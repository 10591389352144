import clsx from "clsx";

interface BannerProps {
    children: React.ReactNode;
    className?: string;
    gradient?: boolean;
}

export const Banner = ({ children, className, gradient }: BannerProps) => {
    return (
        <div
            className={clsx(
                "rounded-lg px-6 py-3 text-center text-white",
                gradient && "bg-gradient-primary",
                className,
            )}
        >
            {children}
        </div>
    );
};

import clsx from "clsx";
import {
    AlignCenter,
    AlignLeft,
    AlignRight,
    Bold,
    Italic,
    List,
    ListOrdered,
    Redo2,
    Underline,
    Undo2,
    AlignJustify,
} from "lucide-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { EditorToolbarButton } from "@/components/Editor/EditorToolbarButton";
import { getButtonCommand } from "@/components/Editor/utils/getButtonCommand";
import { EditorContext } from "@/context/EditorContext";
import { EditorContextType } from "@/types/editor";
import { isMacPlatform } from "@/utils/env";

export const EditorToolbar = () => {
    const { activeEditor: editor } = useContext(
        EditorContext,
    ) as EditorContextType;

    // Button Active States
    const [isBoldActive, setIsBoldActive] = useState(false);
    const [isItalicActive, setIsItalicActive] = useState(false);
    const [isUnderlineActive, setIsUnderlineActive] = useState(false);
    const [isLeftAlignActive, setIsLeftAlignActive] = useState(false);
    const [isCenterAlignActive, setIsCenterAlignActive] = useState(false);
    const [isRightAlignActive, setIsRightAlignActive] = useState(false);
    const [isJustifyAlignActive, setIsJustifyAlignActive] = useState(false);
    const [isBulletListActive, setIsBulletListActive] = useState(false);
    const [isOrderedListActive, setIsOrderedListActive] = useState(false);

    // Button Disabled States
    const [isAlignmentDisabled, setIsAlignmentDisabled] = useState(false);

    const getHasIcon = useCallback(() => {
        if (!editor) return undefined;
        const icon = editor.options.editorProps.attributes?.["data-icon"];
        return !!icon;
    }, [editor]);

    const getIsStaticHeader = useCallback(() => {
        if (!editor) return undefined;
        const isStaticHeader =
            !!editor.options.editorProps.attributes?.["data-static-header"];
        return !!isStaticHeader;
    }, [editor]);

    const updateListButtons = useCallback(() => {
        if (!editor) return;
        const activeBullet = editor.isActive("bulletList");
        const activeOrdered = editor.isActive("orderedList");
        if (activeBullet || activeOrdered) {
            setIsAlignmentDisabled(true);
        } else {
            setIsAlignmentDisabled(false);
        }
        setIsBulletListActive(activeBullet);
        setIsOrderedListActive(activeOrdered);
    }, [editor]);

    const updateAlignmentButtons = useCallback(() => {
        if (!editor) return;
        const hasActiveList =
            editor.isActive("bulletList") || editor.isActive("orderedList");

        if (hasActiveList) {
            // Not allowed to apply alignment to lists
            setIsLeftAlignActive(false);
            setIsCenterAlignActive(false);
            setIsRightAlignActive(false);
            setIsJustifyAlignActive(false);
            setIsAlignmentDisabled(true);
            return;
        }
        setIsLeftAlignActive(editor.isActive({ textAlign: "left" }) || false);
        setIsCenterAlignActive(
            editor.isActive({ textAlign: "center" }) || false,
        );
        setIsRightAlignActive(editor.isActive({ textAlign: "right" }) || false);
        setIsJustifyAlignActive(
            editor.isActive({ textAlign: "justify" }) || false,
        );
    }, [editor]);

    const updateToolBar = useCallback(() => {
        if (!editor) return;
        setIsBoldActive(editor.isActive("bold"));
        setIsItalicActive(editor.isActive("italic"));
        setIsUnderlineActive(editor.isActive("underline"));
        updateListButtons();
        updateAlignmentButtons();
    }, [editor, updateAlignmentButtons, updateListButtons]);

    useEffect(() => {
        if (editor) {
            editor.on("selectionUpdate", updateToolBar);
            //   return () => editor.off('selectionUpdate', updateToolBar);
        }
    }, [editor, updateToolBar]);

    const [hasIcon, setHasIcon] = useState(getHasIcon());
    const [isStaticHeader, setIsStaticHeader] = useState(getIsStaticHeader());

    useEffect(() => {
        if (editor) {
            const hasIcon = getHasIcon();
            const isStaticHeader = getIsStaticHeader();
            setIsStaticHeader(isStaticHeader);
            setHasIcon(hasIcon);
        }
    }, [editor, getHasIcon, getIsStaticHeader]);

    const isHeading = useMemo(() => {
        if (!editor) return true;
        return editor.isActive("heading");
    }, [editor]);

    const isMac = useMemo(() => isMacPlatform(), []);

    return (
        <>
            <div
                id="editor-toolbar"
                className="no-scrollbar flex"
                role="toolbar"
            >
                <div
                    className={clsx(
                        "flex gap-1 border-r-[1px] border-[#CCCCCC] pr-1 xl:pr-2",
                        editor ? "border-[#CCCCCC]" : "border-[#CCCCCC]/25",
                    )}
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().undo().run();
                            }
                        }}
                        disabled={!editor}
                        isActive={false}
                        tooltip={`Undo (${getButtonCommand("undo", isMac)})`}
                        aria-label="Undo"
                    >
                        <Undo2 size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().redo().run();
                            }
                        }}
                        disabled={!editor}
                        isActive={false}
                        tooltip={`Redo (${getButtonCommand("redo", isMac)})`}
                        aria-label="Redo"
                    >
                        <Redo2 size={16} />
                    </EditorToolbarButton>
                </div>
                <div
                    className={clsx(
                        "flex gap-1 border-r-[1px] border-[#CCCCCC] px-1 xl:px-2",
                        editor ? "border-[#CCCCCC]" : "border-[#CCCCCC]/25",
                    )}
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleBold().run();
                                setIsBoldActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isBoldActive}
                        tooltip={`Bold (${getButtonCommand("bold", isMac)})`}
                        aria-label="Bold"
                    >
                        <Bold size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleItalic().run();
                                setIsItalicActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isItalicActive}
                        tooltip={`Italic (${getButtonCommand("italic", isMac)})`}
                        aria-label="Italic"
                    >
                        <Italic size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleUnderline().run();
                                setIsUnderlineActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isUnderlineActive}
                        tooltip={`Underline (${getButtonCommand("underline", isMac)})`}
                        aria-label="Underline"
                    >
                        <Underline size={16} />
                    </EditorToolbarButton>
                </div>
                <div
                    className={clsx(
                        "flex gap-1 border-r-[1px] border-[#CCCCCC] px-1 xl:px-2",
                        editor ? "border-[#CCCCCC]" : "border-[#CCCCCC]/25",
                    )}
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("left")
                                    .run();
                                updateAlignmentButtons();
                            }
                        }}
                        disabled={
                            !editor ||
                            hasIcon ||
                            isAlignmentDisabled ||
                            isStaticHeader
                        }
                        isActive={!hasIcon && isLeftAlignActive}
                        tooltip={`Left align (${getButtonCommand("align-left", isMac)})`}
                        aria-label="Left align"
                    >
                        <AlignLeft size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("center")
                                    .run();
                                updateAlignmentButtons();
                            }
                        }}
                        disabled={
                            !editor ||
                            hasIcon ||
                            isAlignmentDisabled ||
                            isStaticHeader
                        }
                        isActive={!hasIcon && isCenterAlignActive}
                        tooltip={`Center align (${getButtonCommand("align-center", isMac)})`}
                        aria-label="Center align"
                    >
                        <AlignCenter size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("right")
                                    .run();
                                updateAlignmentButtons();
                            }
                        }}
                        disabled={
                            !editor ||
                            hasIcon ||
                            isAlignmentDisabled ||
                            isStaticHeader
                        }
                        isActive={!hasIcon && isRightAlignActive}
                        tooltip={`Right align (${getButtonCommand("align-right", isMac)})`}
                        aria-label="Right align"
                    >
                        <AlignRight size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor
                                    .chain()
                                    .focus()
                                    .setTextAlign("justify")
                                    .run();
                                updateAlignmentButtons();
                            }
                        }}
                        disabled={
                            !editor ||
                            hasIcon ||
                            isAlignmentDisabled ||
                            isStaticHeader
                        }
                        isActive={!hasIcon && isJustifyAlignActive}
                        tooltip={`Justify align (${getButtonCommand("align-justify", isMac)})`}
                        aria-label="Justify align"
                    >
                        <AlignJustify size={16} />
                    </EditorToolbarButton>
                </div>
                <div
                    className="flex gap-1 px-1 xl:px-1"
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleBulletList().run();
                                updateListButtons();
                            }
                        }}
                        disabled={isHeading || hasIcon || isStaticHeader}
                        isActive={isBulletListActive}
                        tooltip={`Bulleted list (${getButtonCommand("bullet-list", isMac)})`}
                        aria-label="Bulleted list"
                    >
                        <List size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor
                                    .chain()
                                    .focus()
                                    .toggleOrderedList()
                                    .run();
                                updateListButtons();
                            }
                        }}
                        disabled={isHeading || hasIcon || isStaticHeader}
                        isActive={isOrderedListActive}
                        tooltip={`Numbered list (${getButtonCommand("ordered-list", isMac)})`}
                        aria-label="Numbered list"
                    >
                        <ListOrdered size={16} />
                    </EditorToolbarButton>
                </div>
            </div>
        </>
    );
};

import { EnhancedStore, Middleware } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import resumeApi from "@/api/resume";
import { RootState, store } from "@/store/store";
import { addNotification } from "@/store/app/slice";
import { NotificationType } from "@/types/app";
import { NotificationMessageType } from "@/types";
import { setHasUnsavedChanges } from "@/store/resume/slice";

enum ActionTypes {
    UPDATE_SECTION_BODY_FIELD = "resume/updateSectionBodyField",
    UPDATE_SECTION_HEADER_FIELD = "resume/updateSectionHeaderField",
    SET_ACTIVE_RESUME = "resume/setActiveResume",
    SET_HAS_UNSAVED_CHANGES = "resume/setHasUnsavedChanges",
}

// We only need these flags since we're not tracking individual updates anymore
let isSaving = false;
let hasUnsavedChanges = false;

const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
    if (hasUnsavedChanges) {
        e.preventDefault();
    }
};

const processQueue = async () => {
    if (isSaving) {
        return;
    }

    isSaving = true;

    try {
        const state: RootState = store.getState();
        const resume = state.resume.activeResume;

        if (!resume) {
            return;
        }

        await resumeApi.saveResume(resume.id, resume);

        // After successful save, remove handler
        hasUnsavedChanges = false;
        window.removeEventListener("beforeunload", beforeUnloadHandler);
        store.dispatch(setHasUnsavedChanges(false));
    } catch (error) {
        if (error?.message) {
            console.error("Failed to save updates:", error?.message);
        } else {
            console.error("Failed to save updates:", JSON.stringify(error));
        }
        store.dispatch(
            addNotification({
                type: NotificationType.ERROR,
                title: "Failed to save changes",
                desc: "Please try again.",
                messageType: NotificationMessageType.RESUME_UPDATE_FIELD,
            })
        );
    } finally {
        isSaving = false;
    }
};

const debouncedProcessQueue = debounce(processQueue, 1000);

export const saveFieldsMiddleware: (store: EnhancedStore<object>) => Middleware = (store) => (next: any) => (action: any) => {
    const result = next(action);

    if (
        action.type === ActionTypes.UPDATE_SECTION_BODY_FIELD ||
        action.type === ActionTypes.UPDATE_SECTION_HEADER_FIELD
    ) {
        const state: RootState = store.getState();
        if (!state.resume.activeResume?.id) return result;

        // Add handler when changes are queued
        if (!hasUnsavedChanges) {
            hasUnsavedChanges = true;
            window.addEventListener("beforeunload", beforeUnloadHandler);
        }

        debouncedProcessQueue();
    } else if (action.type === ActionTypes.SET_ACTIVE_RESUME) {
        // If the resume is being set to null, reset the hasUnsavedChanges and isSaving flags
        if (!action.payload) {
            hasUnsavedChanges = false;
            isSaving = false;
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    } else if (action.type === ActionTypes.SET_HAS_UNSAVED_CHANGES) {
        hasUnsavedChanges = action.payload;
        if (action.payload) {
            window.addEventListener("beforeunload", beforeUnloadHandler);
        } else {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        }
    }

    return result;
};
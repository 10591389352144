import { ICreditAction } from "@/types/creditAction";
import { apiRequest } from "./request";
import { ApiPaginationRequest, ApiPaginationResponse } from "@/types";

interface GetCreditActionsParams {
    limit?: number;
    page?: number;
    sortBy?: string;
    search?: string;
}

const getCreditActionsApi = (params: GetCreditActionsParams) => {
    const requestParams: ApiPaginationRequest = { page: params.page, limit: params.limit, sortBy: params.sortBy };
    if (params.search) {
        requestParams.search = params.search;
    }

    return apiRequest<ApiPaginationResponse<ICreditAction[]>>("/credit-action", "GET", requestParams);
};

const getCreditActionApi = (creditActionId: string) => apiRequest<ICreditAction>(`/credit-action/${creditActionId}`, "GET");

const saveCreditActionApi = (creditAction: Partial<ICreditAction>, creditActionId?: string) => apiRequest<ICreditAction>(`/credit-action/${creditActionId}`, "PATCH", creditAction);

const deleteCreditActionApi = (creditActionId: string) => apiRequest<void>(`/credit-action/${creditActionId}`, "DELETE");

const createCreditActionApi = (creditAction: ICreditAction) => apiRequest<ICreditAction>("/credit-action", "POST", creditAction);

export default {
    getCreditActionsApi,
    getCreditActionApi,
    saveCreditActionApi,
    deleteCreditActionApi,
    createCreditActionApi,
};

import { Section, Text } from "@react-email/components";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { User } from "@/types";
import { DeviceType } from "@/types/device";
interface SurveyEmailProps {
    user?: User;
    url: string;
    satisfaction: number;
    nps: number;
    comments?: string;
    deviceType: DeviceType;
    browserDetails: string;
}

const SurveyEmail = ({
    user,
    url,
    satisfaction,
    nps,
    comments,
    deviceType,
    browserDetails,
}: SurveyEmailProps) => {
    return (
        <EmailWrapper preview="1Template Survey Feedback">
            <EmailHeading>Survey Feedback</EmailHeading>

            <Section>
                <Text style={{ color: "#666666", fontSize: "14px" }}>User</Text>
                <Text style={{ fontWeight: "bold", marginBottom: "16px" }}>
                    {user?.email} ({user?.id})
                </Text>

                <Text style={{ color: "#666666", fontSize: "14px" }}>URL</Text>
                <Text style={{ fontWeight: "bold", marginBottom: "16px" }}>
                    {url}
                </Text>

                <Text style={{ color: "#666666", fontSize: "14px" }}>
                    How satisfied are you with your experience? (1-5)
                </Text>
                <Text style={{ fontWeight: "bold", marginBottom: "16px" }}>
                    {satisfaction}
                </Text>

                <Text style={{ color: "#666666", fontSize: "14px" }}>
                    How likely are you to recommend 1Template to a friend?
                    (1-10)
                </Text>
                <Text style={{ fontWeight: "bold", marginBottom: "16px" }}>
                    {nps}
                </Text>

                <Text style={{ color: "#666666", fontSize: "14px" }}>
                    Please tell us what we can improve and what features
                    you&apos;d like to see next. (10-300 characters)
                </Text>
                <Text style={{ fontWeight: "bold", marginBottom: "16px" }}>
                    {comments || "No comments"}
                </Text>

                <Section
                    style={{
                        backgroundColor: "#f7f7f7",
                        padding: "0 12px",
                        borderRadius: "6px",
                        marginTop: "24px",
                    }}
                >
                    <Text
                        style={{
                            color: "#666666",
                            fontSize: "14px",
                            marginBottom: "12px",
                            fontWeight: "bold",
                        }}
                    >
                        Device Details
                    </Text>
                    <Text style={{ fontWeight: "bold", marginBottom: "4px" }}>
                        {deviceType}
                    </Text>
                    <Text
                        style={{
                            color: "#888888",
                            fontSize: "13px",
                            fontStyle: "italic",
                            marginBottom: "0",
                        }}
                    >
                        {browserDetails}
                    </Text>
                </Section>
            </Section>
        </EmailWrapper>
    );
};

export default SurveyEmail;

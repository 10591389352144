import clsx from "clsx";
import { Download, DownloadIcon } from "lucide-react";
import { useCallback } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { getActiveResume } from "@/store/resume/selectors";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

interface DownloadButtonProps {
    responsive?: boolean;
}

export const DownloadButton = ({ responsive }: DownloadButtonProps) => {
    const resume = useAppSelector(getActiveResume);
    const dispatch = useAppDispatch();

    const onDownload = useCallback(async () => {
        if (!resume) return;
        trackClarityEvent(CLARITY_EVENT_NAMES.DOWNLOAD_RESUME_BUTTON_CLICKED);

        dispatch(openModal({ modalType: ModalTypes.DOWNLOAD_RESUME }));
    }, [resume, dispatch]);

    return (
        resume && (
            <>
                {responsive && (
                    <IconButton
                        variant={"solid"}
                        color={"gradient"}
                        onClick={() => onDownload()}
                        className="flex lg:hidden"
                    >
                        <Download size={20} />
                    </IconButton>
                )}
                <Button
                    color="gradient"
                    className={clsx(
                        "min-w-[132px] xl:min-w-[148px]",
                        responsive && "hidden lg:flex",
                    )}
                    leftIcon={<DownloadIcon size={20} />}
                    onClick={onDownload}
                >
                    Download
                </Button>
            </>
        )
    );
};

import { useCallback } from "react";
import { ActionsToolbar } from "@/components/Section/ActionsToolbar";
import { SectionBody } from "@/components/Section/SectionBody";
import { SectionHeader } from "@/components/Section/SectionHeader";
import {
    SECTION_CONTAINER_CLASS,
    SECTION_ITEM_CLASS,
} from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { SectionContainer } from "@/pages/ResumeBuilder/SectionContainer";
import { resetAiToolbox } from "@/store/app/slice";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResumeGlobalStyles,
    getResumeSectionById,
} from "@/store/resume/selectors";
import { getSectionConfigById } from "@/store/sections/selectors";
import { getActiveItemId, getActiveSection } from "@/store/template/selectors";
import { setActiveEntities, setActiveItemId } from "@/store/template/slice";
import { SectionSpacingSize, PageMarginSize } from "@/types/resume";

interface EditableSectionProps {
    sectionId: string;
    pageNumber: number;
}

export const EditableSection = ({
    sectionId,
    pageNumber,
}: EditableSectionProps) => {
    const pages = useAppSelector(getPages);
    const sectionDetails = useAppSelector(state =>
        getResumeSectionById(state, sectionId),
    );
    const activeSection = useAppSelector(getActiveSection);
    const sectionConfig = useAppSelector(state =>
        getSectionConfigById(state, sectionDetails?.sectionConfigId),
    );
    const activeItemId = useAppSelector(getActiveItemId);
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);
    const dispatch = useAppDispatch();
    const itemsRef = useOutsideClick(() => {
        console.log("itemsRef - useOutsideClick");
        dispatch(setActiveItemId(null));
        dispatch(resetAiToolbox());
    }, [
        "editor-toolbar",
        "actions-toolbar",
        "magic-toolbox",
        "editor-bubble-menu",
    ]);

    const onSectionClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!sectionId || !e.target) return;
            const parent = e.target?.offsetParent ?? {};
            const parentClasslist = Array.from(parent.classList ?? []);
            const isItemClick = parentClasslist.includes(SECTION_ITEM_CLASS);
            const isSectionClick = parentClasslist.includes(
                SECTION_CONTAINER_CLASS,
            );

            if (!isItemClick && isSectionClick) {
                dispatch(
                    setActiveEntities({
                        activeSection: sectionId,
                        activeItemId: undefined,
                    }),
                );
            }
        },
        [dispatch, sectionId],
    );

    if (!sectionDetails || !sectionConfig) return null;
    const sectionIsActive = activeSection === sectionId;
    const pageConfig = pages.find(page => page.pageNumber === pageNumber);

    // Only show the header if this section has a header measurment in the page
    const showSectionHeader = pageConfig?.items.find(
        item => item.section === sectionId && item.type === "header",
    );

    return (
        <SectionContainer
            key={sectionId}
            sectionId={sectionId}
            isStaticHeader={!!sectionConfig.isStaticHeader}
            activeItemId={activeItemId}
            backgroundColour={
                sectionConfig.isStaticHeader
                    ? globalStyles.backgroundColor
                    : undefined
            }
            onClick={onSectionClick}
            sectionSpacingSize={
                globalStyles.sectionSpacingSize ?? SectionSpacingSize.MD
            }
            pageMarginSize={globalStyles.pageMarginSize ?? PageMarginSize.MD}
            pageNumber={pageNumber}
        >
            {sectionIsActive &&
                !activeItemId &&
                !sectionConfig.isStaticHeader && <ActionsToolbar />}
            {showSectionHeader && (
                <SectionHeader
                    sectionId={sectionId}
                    isEditable={true}
                    shouldMeasure={true}
                />
            )}
            <div
                ref={itemsRef}
                id={`${sectionConfig.id}-items`}
                className="break-words"
            >
                <SectionBody
                    sectionId={sectionId}
                    isEditable={true}
                    shouldMeasure={true}
                    pageNumber={pageNumber}
                />
            </div>
        </SectionContainer>
    );
};

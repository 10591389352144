import { useCallback } from 'react';
import billingApi from '@/api/billing';
import { useAppDispatch, useAppSelector } from '@/hooks/types';
import { setUserPlan } from '@/store/user/slice';
import { getStripeCustomerId } from '@/store/user/selectors';
import { UserPlan } from '@/types/billing';
import { setProducts, setProductsError, setProductsLoading } from '@/store/billing/slice';

interface StripeHook {
    getAndUpdateUserPlan: () => Promise<UserPlan | null>;
    createCustomerPortalSession: (returnUrl: string) => Promise<string | null>;
    createStripeCheckoutSession: (priceId: string, redirectUrl: string) => Promise<string>;
    getStripeProducts: () => Promise<void>;
}

export const useStripe = (): StripeHook => {
    const dispatch = useAppDispatch();
    const stripeCustomerId = useAppSelector(getStripeCustomerId) ?? "";

    const getAndUpdateUserPlan = useCallback(async () => {
        return billingApi.getUserPlan(stripeCustomerId)
            .then(response => {
                dispatch(setUserPlan(response));
                return response;
            })
            .catch(error => {
                console.error('Failed to check user plan:', error);
                return null;
            });
    }, [dispatch, stripeCustomerId]);

    const createCustomerPortalSession = useCallback(async (returnUrl: string) => {
        try {
            const session = await billingApi.getPortalSession({
                stripeCustomerId,
                returnUrl,
            });

            return session.url;
        } catch (error) {
            console.error('Failed to create customer portal session:', error);
            return '';
        }
    }, [stripeCustomerId]);

    const createStripeCheckoutSession = useCallback(async (priceId: string, redirectUrl: string) => {
        try {
            const session = await billingApi.initiateStripeCheckout({
                priceId,
                redirectUrl,
                stripeCustomerId,
            });

            return session.id;
        } catch (error) {
            console.error('Failed to create Stripe checkout session:', error);
            return '';
        }
    }, [stripeCustomerId]);

    const getStripeProducts = useCallback(async () => {
        try {
            dispatch(setProductsLoading());
            const products = await billingApi.getStripeProducts();
            dispatch(setProducts(products));
        } catch (error) {
            console.error('Failed to get Stripe products:', error);
            dispatch(setProductsError(error as string));
        }
    }, [dispatch]);

    return { getAndUpdateUserPlan, createCustomerPortalSession, createStripeCheckoutSession, getStripeProducts };
};
import clsx from "clsx";
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getAiFeaturePlaceholder } from "@/components/AiToolbox/helper";
import { AppText } from "@/components/Text/AppText";
import { CreditActionCode } from "@/types/creditAction";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

const textAreaClasses =
    "w-full rounded-lg border-none bg-neutral-100/50 p-3 text-sm leading-tight focus:outline-none focus:ring-1 focus:ring-primary-700";

interface AiInputOutputProps {
    maxLength?: number;
    showCounter?: boolean;
    inputValue: string;
    outputValue: string;
    animateOutput?: boolean;
    loading?: boolean;
    onInputChange: (value: string) => void;
    onOutputChange: (value: string) => void;
    className?: string;
    creditActionCode?: CreditActionCode;
    sectionType?: string;
}

export const AiInputOutput = ({
    maxLength = 500,
    showCounter = true,
    inputValue,
    outputValue,
    animateOutput = false,
    loading = false,
    onInputChange,
    onOutputChange,
    className,
    creditActionCode,
    sectionType,
}: AiInputOutputProps) => {
    const [displayedOutput, setDisplayedOutput] = useState("");
    const [isAnimating, setIsAnimating] = useState(false);
    const [loadingDots, setLoadingDots] = useState("");
    const [disabledThumbs, setDisabledThumbs] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [selectedThumb, setSelectedThumb] = useState<"up" | "down" | null>(
        null,
    );
    const placeholder = useMemo(
        () =>
            getAiFeaturePlaceholder(
                creditActionCode,
                sectionType?.toLowerCase(),
            ),
        [creditActionCode, sectionType],
    );

    const adjustTextAreaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = "0";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (loading) {
            setSelectedThumb(null);
            const interval = setInterval(() => {
                setLoadingDots(prev => {
                    if (prev === "...") return "";
                    return prev + ".";
                });
            }, 200);

            return () => clearInterval(interval);
        } else {
            setLoadingDots("");
        }
    }, [loading]);

    useEffect(() => {
        if (selectedThumb) {
            const timeout = setTimeout(() => {
                setDisabledThumbs(true);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [selectedThumb]);

    useEffect(() => {
        if (outputValue && animateOutput) {
            setIsAnimating(true);
            let index = 0;
            const interval = setInterval(() => {
                if (index <= outputValue.length) {
                    setDisplayedOutput(outputValue.slice(0, index));
                    index++;
                } else {
                    clearInterval(interval);
                    setIsAnimating(false);
                }
            }, 10);

            return () => clearInterval(interval);
        } else {
            setDisplayedOutput(outputValue);
        }
    }, [outputValue, animateOutput]);

    useEffect(() => {
        if (outputValue) {
            adjustTextAreaHeight();
            setSelectedThumb(null);
            setDisabledThumbs(false);
        }
    }, [displayedOutput, outputValue]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= maxLength) {
            onInputChange(value);
        }
    };

    const handleOutputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onOutputChange(e.target.value);
        adjustTextAreaHeight();
    };

    const handleThumb = useCallback((type: "up" | "down") => {
        setSelectedThumb(type);
        trackClarityEvent(
            type === "up"
                ? CLARITY_EVENT_NAMES.AI_TOOLBOX_THUMB
                : CLARITY_EVENT_NAMES.AI_TOOLBOX_THUMB_DOWN,
        );
    }, []);

    return (
        <div className="relative">
            {outputValue || loading ? (
                <textarea
                    ref={textareaRef}
                    value={
                        loading
                            ? loadingDots
                            : isAnimating
                              ? displayedOutput
                              : outputValue
                    }
                    onChange={handleOutputChange}
                    disabled={isAnimating || loading}
                    className={clsx(
                        textAreaClasses,
                        "min-h-[100px] resize-none overflow-hidden",
                        loading &&
                            "cursor-wait bg-neutral-100 text-neutral-600",
                        !loading && "bg-neutral-100 ring-primary-200",
                        (isAnimating || loading) && "cursor-wait",
                        className,
                    )}
                />
            ) : (
                <textarea
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={loading}
                    className={clsx(
                        textAreaClasses,
                        "min-h-[100px]",
                        loading && "cursor-wait",
                        className,
                    )}
                />
            )}
            {showCounter && !outputValue && !loading && (
                <div className="absolute bottom-2 right-2 text-xs text-neutral-700">
                    {inputValue.length}/{maxLength}
                </div>
            )}
            {outputValue && (
                <div className="flex items-center justify-between gap-2 text-xs text-neutral-500">
                    <AppText
                        variant="labelsbuttons"
                        className="text-primary-700"
                    >
                        Generated with AI
                    </AppText>
                    <div className="flex items-center gap-3">
                        <ThumbsUpIcon
                            size={16}
                            className={clsx(
                                "text-neutral-900 transition-all duration-300 hover:rotate-12 hover:scale-110 hover:cursor-pointer active:rotate-[-12deg] active:scale-95",
                                disabledThumbs &&
                                    "pointer-events-none cursor-not-allowed opacity-30",
                                selectedThumb === "down" && "!opacity-10",
                            )}
                            onClick={() => {
                                if (!disabledThumbs) {
                                    handleThumb("up");
                                }
                            }}
                        />
                        <ThumbsDownIcon
                            size={16}
                            className={clsx(
                                "text-neutral-900 transition-all duration-300 hover:rotate-[-12deg] hover:scale-110 hover:cursor-pointer active:rotate-12 active:scale-95",
                                disabledThumbs &&
                                    "pointer-events-none cursor-not-allowed opacity-30",
                                selectedThumb === "up" && "!opacity-10",
                            )}
                            onClick={() => {
                                if (!disabledThumbs) {
                                    handleThumb("down");
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

import { AIModel } from "./AIPreferencesConfig";
import { InfoTooltip } from "@/components/InfoTooltip/InfoTooltip";
import { PromptInputTokenEstimation } from "@/pages/Admin/AI/PromptInputTokenEstimation";
import { TokenEstimationInfo } from "@/pages/Admin/AI/TokenEstimationInfo";

const systemPrompt =
    "You are a professional resume writer. Generate concise, impactful summaries. Your response should be in en-GB.";

interface AiSummaryFeatureProps {
    model: AIModel;
    temperature: number;
    isEditingPrompt: boolean;
    setIsEditingPrompt: (isEditing: boolean) => void;
    customPrompt: string;
    setCustomPrompt: (prompt: string) => void;
    handleSavePrompt: () => void;
    generatePrompt: () => string;
    getTokenCost: (model: AIModel) => { prompt: number; completion: number };
}

export const AiSummaryFeature = ({
    model,
    temperature,
    isEditingPrompt,
    setIsEditingPrompt,
    customPrompt,
    setCustomPrompt,
    handleSavePrompt,
    generatePrompt,
    getTokenCost,
}: AiSummaryFeatureProps) => {
    return (
        <div className="mb-4 rounded-lg border border-gray-200 bg-gray-50 p-4">
            <div className="mb-2 flex flex-col gap-2">
                <h3 className="flex items-center gap-1 text-xs font-medium text-gray-700">
                    System/Function Definition
                    <InfoTooltip
                        content="This is the system/function definition for the AI. It is used to guide the AI to generate a resume summary."
                        position="bottom"
                    />
                </h3>
                <div className="whitespace-pre-wrap rounded-lg bg-white p-3 text-xs text-neutral-600">
                    {systemPrompt}
                </div>
            </div>
            <div className="mb-2 flex items-center justify-between">
                <h3 className="flex items-center gap-1 text-xs font-medium text-gray-700">
                    Generated Prompt
                    <InfoTooltip
                        content="Recommended token budget is 200-300 for the prompt, 150-200 for output. Total is 350-500 tokens"
                        position="bottom"
                    />
                </h3>
                <div className="flex items-center gap-2">
                    <div className="text-xs text-gray-500">
                        Model: {model} | Temp: {temperature}
                    </div>
                    {isEditingPrompt ? (
                        <button
                            onClick={handleSavePrompt}
                            className="text-xs text-primary-600 hover:text-primary-700"
                        >
                            Save
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                setCustomPrompt(generatePrompt());
                                setIsEditingPrompt(true);
                            }}
                            className="text-xs text-primary-600 hover:text-primary-700"
                        >
                            Edit
                        </button>
                    )}
                    {isEditingPrompt && (
                        <button
                            onClick={() => {
                                setCustomPrompt("");
                                setIsEditingPrompt(false);
                                generatePrompt();
                            }}
                            className="ml-2 text-xs text-neutral-700 hover:text-neutral-900"
                        >
                            Reset
                        </button>
                    )}
                </div>
            </div>
            {isEditingPrompt ? (
                <textarea
                    value={customPrompt}
                    onChange={e => setCustomPrompt(e.target.value)}
                    className="w-full whitespace-pre-wrap rounded-lg bg-white p-3 text-xs text-gray-600"
                    rows={10}
                />
            ) : (
                <div className="whitespace-pre-wrap rounded-lg bg-white p-3 text-xs text-neutral-600">
                    {generatePrompt() || "No prompt parameters selected"}
                </div>
            )}
            <div className="mt-2 grid grid-cols-2 gap-4">
                <PromptInputTokenEstimation
                    generatedPrompt={generatePrompt()}
                    systemPrompt={systemPrompt}
                    model={model}
                    getTokenCost={getTokenCost}
                />
                <TokenEstimationInfo />
            </div>
        </div>
    );
};

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Transition,
} from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "lucide-react";
import { Button } from "@/components/Button/Button";

interface AdminFieldContainer {
    children: React.ReactNode;
    title: string;
    onDelete: () => void;
}

export const AdminFieldContainer = ({
    children,
    title,
    onDelete,
}: AdminFieldContainer) => {
    return (
        <Disclosure
            as="div"
            className="w-full"
        >
            <DisclosureButton
                className="group flex w-full cursor-pointer items-center justify-between border-b pb-2 text-left"
                as="div"
            >
                <div className="flex gap-3">
                    <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                    {title}
                </div>
                <Button
                    className="flex h-8 w-12 "
                    color={"red"}
                    onClick={onDelete}
                >
                    <TrashIcon className="h-4 w-4" />
                </Button>
            </DisclosureButton>
            <div className="py-2">
                <Transition
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 -translate-y-6"
                    enterTo="opacity-100 translate-y-0"
                    leave="duration-200 ease-out"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-6"
                >
                    <DisclosurePanel className="origin-top transition">
                        {children}
                    </DisclosurePanel>
                </Transition>
            </div>
        </Disclosure>
    );
};

import { WhiteLogo } from "@/components/Icons/Icons";
import { NavigationLink } from "@/components/Navigation/NavigationLink";
import { AppText } from "@/components/Text/AppText";
import { AppRoutes, SiteRoutes, SocialMediaRoutes } from "@/types/routes";

const socialLinks = [
    {
        href: SocialMediaRoutes.LinkedIn,
        icon: (
            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
        ),
        label: "LinkedIn",
    },
    {
        href: SocialMediaRoutes.Instagram,
        icon: (
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z" />
        ),
        label: "Instagram",
    },
];

const mainLinks = [
    { to: `/${AppRoutes.Examples}`, label: "Resume Examples" },
    { to: `/${AppRoutes.Profile}?tab=credits`, label: "Credits" },
    { to: SiteRoutes.Blog, label: "Blog", external: true },
    { to: SiteRoutes.About, label: "About", external: true },
    { to: SiteRoutes.Terms, label: "Terms", external: true },
    { to: SiteRoutes.PrivacyPolicy, label: "Privacy", external: true },
];

export const PageFooter = () => {
    return (
        <footer className="mt-6 bg-primary-700 text-white lg:mt-12">
            <div className="mx-auto max-w-7xl px-4 py-8 md:px-8">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                    <div className="flex flex-col items-start gap-2">
                        <a
                            href={SiteRoutes.Landing}
                            aria-label="1Template"
                        >
                            <WhiteLogo className="h-6 w-auto" />
                        </a>
                        <AppText
                            variant="footnote"
                            className="mt-2 text-xs text-white/75"
                        >
                            The smarter way to build your resume.
                        </AppText>
                        <NavigationLink
                            to="mailto:hello@1template.io"
                            className="!px-0 !text-xs text-white/90 transition-colors hover:text-white"
                        >
                            hello@1template.io
                        </NavigationLink>
                    </div>

                    <div className="flex flex-col gap-2">
                        <AppText
                            variant="contextheading"
                            className="text-xs text-white"
                        >
                            Quick Links
                        </AppText>
                        <ul className="grid grid-cols-2 gap-1">
                            {mainLinks.map(link => (
                                <li key={link.label}>
                                    <NavigationLink
                                        to={link.to}
                                        target={
                                            link.external ? "_blank" : undefined
                                        }
                                        className="!px-0 text-xs text-white/90 transition-colors hover:text-white"
                                    >
                                        {link.label}
                                    </NavigationLink>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex flex-col gap-2">
                        <AppText
                            variant="contextheading"
                            className="text-xs text-white"
                        >
                            Connect With Us
                        </AppText>
                        <div className="flex gap-3">
                            {socialLinks.map(social => (
                                <a
                                    key={social.label}
                                    href={social.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-white/90 transition-colors hover:text-white"
                                    aria-label={social.label}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        {social.icon}
                                    </svg>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="mx-auto max-w-7xl border-t border-white/20 px-4 py-4 md:px-8">
                    <div className="flex justify-center">
                        <AppText
                            variant="footnote"
                            className="text-xs text-white/75"
                        >
                            © {new Date().getFullYear()} 1Template. All Rights
                            Reserved
                        </AppText>
                    </div>
                </div>
            </div>
        </footer>
    );
};

import { NotificationDetails } from "@/types";
import { SaveTemplateState, SideBarState, TemplateLoadingState } from "@/types/app";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AiToolboxState } from "@/types/aiToolbox";
import { CreditActionCode } from "@/types/creditAction";

interface AppState {
    notifications: NotificationDetails[];
    sideBar: SideBarState;
    showSideBar: boolean;
    saveTemplateState: SaveTemplateState;
    templateLoadingState: TemplateLoadingState;
    showPlansModal: "upgrade" | "download" | "preview" | "";
    showRearrangeModal: boolean;
    datePickerId: string;
    debugBuilder: boolean;
    debugPageMargins: boolean;
    debugSectionSpacing: boolean;
    showFeedbackSurvey: boolean;
    aiToolbox: AiToolboxState;
}

const initialState: AppState = {
    notifications: [],
    sideBar: SideBarState.CLOSED,
    showSideBar: false,
    saveTemplateState: SaveTemplateState.SAVED,
    templateLoadingState: TemplateLoadingState.INITIAL,
    showPlansModal: "", // the value is the source
    showRearrangeModal: false,
    datePickerId: "",
    debugBuilder: false,
    debugPageMargins: false,
    debugSectionSpacing: false,
    showFeedbackSurvey: false,
    aiToolbox: {
        isVisible: false,
        promptValue: "",
        promptActionCode: CreditActionCode.AI_GENERATE_SUMMARY,
    },
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        addNotification: (
            state,
            { payload }: PayloadAction<NotificationDetails>,
        ) => {
            const isAlreadyDisplayingNotification = state.notifications.some(
                n => n.type === payload.type,
            );

            if (!isAlreadyDisplayingNotification) {
                return {
                    ...state,
                    notifications: [...state.notifications, payload],
                };
            }
            return state;
        },
        removeNotification: (
            state,
            { payload }: PayloadAction<NotificationDetails>,
        ) => {
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.type !== payload.type,
                ),
            };
        },
        setSideBarState: (state, { payload }: PayloadAction<SideBarState>) => {
            return {
                ...state,
                sideBar: payload,
            };
        },
        setShowSideBar: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showSideBar: payload,
            };
        },
        setSaveTemplateState: (state, { payload }: PayloadAction<SaveTemplateState>) => {
            return {
                ...state,
                saveTemplateState: payload,
            };
        },
        setTemplateLoadingState: (state, { payload }: PayloadAction<TemplateLoadingState>) => {
            return {
                ...state,
                templateLoadingState: payload,
            };
        },
        setShowPlansModal: (state, { payload }: PayloadAction<"upgrade" | "download" | "preview" | "">) => {
            return {
                ...state,
                showPlansModal: payload,
            };
        },
        setShowRearrangeModal: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showRearrangeModal: payload,
            };
        },
        setDatePickerId: (state, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                datePickerId: payload,
            };
        },
        setDebugBuilder: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugBuilder: payload,
            };
        },
        setDebugPageMargins: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugPageMargins: payload,
            };
        },
        setDebugSectionSpacing: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                debugSectionSpacing: payload,
            };
        },
        setShowFeedbackSurvey: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showFeedbackSurvey: payload,
            };
        },
        showAiToolbox: (state, action: PayloadAction<{
            promptValue?: string;
            promptActionCode: CreditActionCode;
            selection?: {
                from: number;
                to: number;
            };
        }>) => {
            state.aiToolbox = {
                isVisible: true,
                promptValue: action.payload.promptValue || "",
                promptActionCode: action.payload.promptActionCode,
                selection: action.payload.selection,
            };
        },
        toggleAiToolbox: (state, { payload }: PayloadAction<CreditActionCode>) => {
            if (state.aiToolbox.isVisible) {
                state.aiToolbox.isVisible = false;
                state.aiToolbox.promptActionCode = undefined;
            } else {
                state.aiToolbox.isVisible = true;
                state.aiToolbox.promptActionCode = payload;
            }
        },
        resetAiToolbox: (state) => {
            state.aiToolbox = initialState.aiToolbox;
        },
        updateAiToolbox: (state, { payload }: PayloadAction<{
            promptActionCode: CreditActionCode;
            promptValue?: string;
            selection?: {
                from: number;
                to: number;
            };
        }>) => {
            state.aiToolbox = {
                ...state.aiToolbox,
                ...payload,
            };
        },
    },
});

export const { addNotification, removeNotification, setSideBarState, setShowSideBar, setSaveTemplateState, setTemplateLoadingState, setShowPlansModal, setShowRearrangeModal, setDatePickerId, setDebugBuilder, setDebugPageMargins, setDebugSectionSpacing, setShowFeedbackSurvey, showAiToolbox, toggleAiToolbox, resetAiToolbox, updateAiToolbox } = appSlice.actions;
export const { reducer: appReducer } = appSlice;

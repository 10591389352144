import { bool, date, object, string, number, array, mixed } from "yup";

export const userSchema = object({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().required("Email is required"),
    role: string().required().oneOf(["admin", "user"]),
    isEmailVerified: bool().required(),
    loginMethod: string().oneOf(["email", "google", undefined]),
    stripeCustomerId: string(),
    credits: object({
        balance: number().required(),
        lastUpdated: date().required(),
    }),
    accountDeletionRequestedAt: date(),
    scheduledDeletionDate: date(),
    createdAt: date().required(),
    flags: mixed(),
    marketingOptIns: array(
        object({
            type: string().required(),
            optIn: bool().required(),
        })
    ),
    preferences: object({
        industries: array(string()).required(),
        experienceLevel: string(),
        jobTypes: array(string()).required(),
        jobRoles: array(string()).required(),
    }),
    acceptedTerms: bool().required(),
    lastLoginAt: date(),
});

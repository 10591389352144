import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "@/store/store";
import resumeApi from "@/api/resume";
import userApi from "@/api/user";
import billingApi from "@/api/billing";
import assistantApi from "@/api/assistant";
import sectionApi from "@/api/section";
import templateApi from "@/api/template";
import documentTypeApi from "@/api/documentType";
import { setResumes, setExamples, setActiveResume } from "@/store/resume/slice";
import { setSections } from "@/store/sections/slice";
import { setTemplates } from "@/store/template/slice";
import { setDocumentTypes } from "@/store/documentType/slice";
import { setAssistantItems } from "@/store/assistant/slice";
import { getIsResumePage } from "@/utils/routes";
import { setUserPlan, setUserDetails, setUserFeatures } from "@/store/user/slice";
import { setWizardUserPreferences } from "@/store/wizard/slice";
import { trackClarityIdentify } from "@/utils/tracking";
import { useAppSelector } from "@/hooks/types";
import { getIsDevelopmentEnv } from "@/utils/env";
import { getIsAdminUser, getUserDetails } from "@/store/user/selectors";

// Define query keys
export const queryKeys = {
    activeResume: (id: string) => ["activeResume", id],
    resumes: ["resumes"],
    sections: ["sections"],
    templates: ["templates"],
    documentTypes: ["documentTypes"],
    examples: ["examples"],
    assistantItems: ["assistantItems"],
} as const;

const ONE_HOUR = 30 * 60 * 1000;
const FIFTEEN_MINUTES = 15 * 60 * 1000;
const THIRTY_MINUTES = 30 * 60 * 1000;

export const useAppData = (resumeId?: string, includeAssistantItems = false) => {
    const dispatch = useDispatch();
    const isAdmin = useAppSelector(getIsAdminUser);
    const isDevelopment = getIsDevelopmentEnv();
    const userDetails = useAppSelector(getUserDetails);

    const userDataQuery = useQuery({
        queryKey: ["userData"],
        queryFn: async () => {
            const accessToken = window.localStorage.getItem("accessToken");
            const parsedAccessToken = accessToken ? JSON.parse(accessToken) : {};

            if (parsedAccessToken?.token && parsedAccessToken?.token !== "null") {
                // If we already have admin status, don't refetch on hot reload
                if (isAdmin && isDevelopment) {
                    return userDetails;
                }

                const response = await userApi.getMeApi();
                store.dispatch(setUserDetails(response));
                store.dispatch(setWizardUserPreferences(response.preferences || null));
                trackClarityIdentify(response.email);

                // Fetch user plan
                const userPlan = await billingApi.getUserPlan(response.stripeCustomerId);
                store.dispatch(setUserPlan(userPlan));

                return response;
            }
            return null;
        },
        retry: false,
        staleTime: FIFTEEN_MINUTES,
        gcTime: THIRTY_MINUTES,
        refetchOnMount: isAdmin ? false : "always", // Don't refetch for admins on mount
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });

    const userFeaturesQuery = useQuery({
        queryKey: ["userFeatures"],
        queryFn: () => userApi.getFeaturesApi(),
        staleTime: FIFTEEN_MINUTES,
        gcTime: THIRTY_MINUTES,
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });

    const activeResumeQuery = useQuery({
        queryKey: queryKeys.activeResume(resumeId!),
        queryFn: () => resumeApi.getResume(resumeId!),
        enabled: !!resumeId,
        staleTime: 0,
        gcTime: 0,
        refetchOnMount: "always",
    });

    const resumesQuery = useQuery({
        queryKey: queryKeys.resumes,
        queryFn: () => resumeApi.getAllResume(false),
        staleTime: 0,
        gcTime: 0,
        refetchOnMount: "always",
        // refetchOnWindowFocus: "always",
        refetchOnReconnect: "always",
        // refetchInterval: 0,
    });

    const assistantItemsQuery = useQuery({
        queryKey: queryKeys.assistantItems,
        queryFn: () => assistantApi.getUserAssistantItems(),
        enabled: includeAssistantItems,
        staleTime: ONE_HOUR,
    });

    const sectionsQuery = useQuery({
        queryKey: queryKeys.sections,
        queryFn: () => sectionApi.getAllSections(),
        staleTime: ONE_HOUR,
        gcTime: ONE_HOUR,
    });

    const templatesQuery = useQuery({
        queryKey: queryKeys.templates,
        queryFn: () => templateApi.getAllTemplates(),
        staleTime: ONE_HOUR,
        gcTime: ONE_HOUR,
    });

    const documentTypesQuery = useQuery({
        queryKey: queryKeys.documentTypes,
        queryFn: () => documentTypeApi.getAllDocumentTypes(),
        staleTime: ONE_HOUR,
        gcTime: ONE_HOUR,
    });

    const examplesQuery = useQuery({
        queryKey: queryKeys.examples,
        queryFn: () => resumeApi.getExamples(),
        staleTime: ONE_HOUR,
        gcTime: ONE_HOUR,
    });

    useEffect(() => {
        if (userFeaturesQuery.data) {
            dispatch(setUserFeatures(userFeaturesQuery.data));
        }
    }, [userFeaturesQuery.data, dispatch]);

    useEffect(() => {
        if (activeResumeQuery.data) {
            dispatch(setActiveResume(activeResumeQuery.data));
        }
    }, [activeResumeQuery.data, dispatch]);

    useEffect(() => {
        if (assistantItemsQuery.data) {
            dispatch(setAssistantItems(assistantItemsQuery.data.results));
        }
    }, [assistantItemsQuery.data, dispatch]);

    // Handle Redux state updates in an effect
    useEffect(() => {
        if (resumesQuery.data) {
            dispatch(setResumes(resumesQuery.data.results));
        }
    }, [resumesQuery.data, dispatch]);

    useEffect(() => {
        if (templatesQuery.data) {
            dispatch(setTemplates(templatesQuery.data.results));
        }
    }, [templatesQuery.data, dispatch]);

    useEffect(() => {
        if (documentTypesQuery.data) {
            dispatch(setDocumentTypes(documentTypesQuery.data.results));
        }
    }, [documentTypesQuery.data, dispatch]);

    useEffect(() => {
        if (examplesQuery.data) {
            dispatch(setExamples(examplesQuery.data.results));
        }
    }, [examplesQuery.data, dispatch]);

    useEffect(() => {
        if (sectionsQuery.data) {
            dispatch(setSections(sectionsQuery.data.results));
        }
    }, [sectionsQuery.data, dispatch]);

    return {
        isLoading:
            resumesQuery.isLoading ||
            sectionsQuery.isLoading ||
            templatesQuery.isLoading ||
            documentTypesQuery.isLoading ||
            examplesQuery.isLoading ||
            (includeAssistantItems && assistantItemsQuery.isLoading) ||
            (resumeId && activeResumeQuery.isLoading && !getIsResumePage()) ||
            userDataQuery.isLoading ||
            userFeaturesQuery.isLoading,
        isError:
            resumesQuery.isError ||
            sectionsQuery.isError ||
            templatesQuery.isError ||
            documentTypesQuery.isError ||
            examplesQuery.isError ||
            (includeAssistantItems && assistantItemsQuery.isError) ||
            (resumeId && activeResumeQuery.isError) ||
            userDataQuery.isError ||
            userFeaturesQuery.isError,
        error:
            resumesQuery.error ||
            sectionsQuery.error ||
            templatesQuery.error ||
            documentTypesQuery.error ||
            examplesQuery.error ||
            assistantItemsQuery.error ||
            activeResumeQuery.error ||
            userDataQuery.error ||
            userFeaturesQuery.error,
    };
};

export const useInvalidateResumesCache = () => {
    const queryClient = useQueryClient();
    return () => queryClient.invalidateQueries({ queryKey: queryKeys.resumes });
};

export const useInvalidateActiveResumeCache = () => {
    const queryClient = useQueryClient();
    return (resumeId: string) => queryClient.invalidateQueries({ queryKey: queryKeys.activeResume(resumeId) });
};

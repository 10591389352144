import {
    ALargeSmall,
    Minimize2,
    Repeat,
    SpellCheck,
    ListChecks,
    PenLine,
} from "lucide-react";
import { CreditActionCode } from "@/types/creditAction";

export const smartScribeToolsOptions = [
    {
        id: CreditActionCode.AI_GENERATE_SUMMARY,
        title: "Paragraph Pro",
        icon: <PenLine className="h-4 w-4" />,
    },
    {
        id: CreditActionCode.AI_GENERATE_BULLET_POINTS,
        title: "Bullet Builder",
        icon: <ListChecks className="h-4 w-4" />,
    },
];

export const smartScribeQuickPromptOptions = [
    {
        id: CreditActionCode.AI_PROMPT_REWRITE,
        title: "Rewrite",
        icon: <Repeat className="h-4 w-4" />,
    },
    {
        id: CreditActionCode.AI_PROMPT_SHORTEN,
        title: "Shorten",
        icon: <Minimize2 className="h-4 w-4" />,
    },
    {
        id: CreditActionCode.AI_PROMPT_FORMAL,
        title: "More formal",
        icon: <ALargeSmall className="h-4 w-4" />,
    },
    {
        id: CreditActionCode.AI_PROMPT_GRAMMAR,
        title: "Fix grammar",
        icon: <SpellCheck className="h-4 w-4" />,
    },
];

// Mapping of placeholders for each section type for the bullet builder tool
export const bulletBuilderSectionTypeToPlaceholder: Record<string, string> = {
    accomplishments:
        "List your achievements with key metrics or outcomes (e.g., increased sales by 20%)",
    certification:
        "Provide details of your certifications, including names, dates, and issuer.",
    custom: "Describe the unique information you’d like to highlight with bullet points.",
    education:
        "Share your degrees, institutions, and any notable academic achievements",
    experience:
        "Provide a summary of your roles, responsibilities, and key achievements for this position",
    interests:
        "List hobbies or passions that reflect your personality or skills.",
    languages: "Specify the languages you know and your proficiency levels.",
    licenses:
        "Provide the details of any relevant licences, including issuing authorities",
    profile:
        "“Share the key highlights you’d like to feature in your professional profile",
    projects:
        "Describe your projects, focusing on objectives, results, and technologies used",
    references: "Provide details of your referees, including names and roles.",
    skills: "List your technical and soft skills relevant to the role",
    strengths: "Highlight your core strengths and areas of expertise",
    summary:
        "Provide a brief overview of your career goals and key highlights.",
    volunteering:
        "Describe your volunteer experiences, including roles and impact.",
    default: "Provide some context to help generate bullet points.",
};

// Mapping of placeholders for each section type for the paragraph pro tool
export const paragraphProSectionTypeToPlaceholder: Record<string, string> = {
    accomplishments:
        "Tell us about your top achievements and their significance.",
    certification:
        "Explain how your certifications enhance your qualifications.",
    custom: "Provide any additional context for the section you’d like written.",
    education:
        "Share details about your education journey and academic highlights.",
    experience:
        "Describe your job responsibilities and notable accomplishments.",
    interests:
        "Explain how your interests align with your personal or professional growth.",
    languages: "Highlight your language skills and how they’ve been useful.",
    licenses: "Provide context on the licences you hold and their relevance.",
    profile:
        "Give an overview of your professional background and career goals.",
    projects:
        "Describe your project in detail, including challenges and outcomes.",
    references: "Share details about your referee and their connection to you.",
    skills: "Explain your key skills and how they’ve been applied in your career.",
    strengths: "Provide examples of how your strengths have led to success.",
    summary:
        "Share your career aspirations and the key highlights of your journey.",
    volunteering: "Describe your volunteer work and the impact you’ve made.",
    default: "Provide some context to help generate professional paragraphs.",
};

import clsx from "clsx";
import { AppText } from "@/components/Text/AppText";

interface PresetsMenuProps {
    title: string | React.ReactNode;
    children: React.ReactNode;
    type?: "alert" | "info";
}

export const PresetsMenu = ({
    title,
    children,
    type = "info",
}: PresetsMenuProps) => (
    <div className="flex flex-col overflow-hidden rounded-lg bg-white text-neutral-900">
        <div
            className={clsx(
                "flex items-center justify-start overflow-hidden rounded-t-lg border-2 border-neutral-300 px-3 py-2",
                type === "alert" && "bg-alert-100",
                type === "info" && "bg-neutral-100",
            )}
        >
            {typeof title === "string" ? (
                <AppText variant="labelsbuttons">{title}</AppText>
            ) : (
                title
            )}
        </div>
        <div className="rounded-b-lg border-2 border-t-0 border-neutral-300 p-3">
            {children}
        </div>
    </div>
);

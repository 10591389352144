import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight, Edit3Icon, Lightbulb } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./assistantStyles.css";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { AssistantItemBlock } from "@/components/Sidebar/Assistant/AssistantItemBlock";
import { AssistantItemsContainer } from "@/components/Sidebar/Assistant/AssistantItemsContainer";
import { JobRoleSelector } from "@/components/Sidebar/Assistant/JobRoleSelector";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import {
    getActiveJobRole,
    getAssistantItemsByQuery,
} from "@/store/assistant/selectors";
import { getActiveResumeSections } from "@/store/resume/selectors";
import { getSectionConfigById } from "@/store/sections/selectors";
import { getActiveSection } from "@/store/template/selectors";
import {
    AssistantItemSectionContentType,
    AssistantItemTypes,
    AssistantSectionItem,
} from "@/types/assistant";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

export const SectionAssistant = () => {
    const [activeTab, setActiveTab] = useState<"advice" | "examples">("advice");
    const [numVisibleExamples, setNumVisibleExamples] = useState(2);
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const activeSection = useAppSelector(getActiveSection);
    const activeJobRole = useAppSelector(getActiveJobRole);

    const activeSectionDetails = useMemo(
        () =>
            activeResumeSections && activeSection
                ? activeResumeSections?.[activeSection]
                : null,
        [activeSection, activeResumeSections],
    );
    const activeSectionConfig = useAppSelector(state =>
        getSectionConfigById(
            state,
            activeSectionDetails?.sectionConfigId ?? activeSection,
        ),
    );
    const assistantItemsExamples = useAppSelector(state =>
        getAssistantItemsByQuery(state, {
            type: AssistantItemTypes.SECTION,
            id: activeSectionConfig?.id,
            contentType: AssistantItemSectionContentType.EXAMPLE,
        }),
    ) as AssistantSectionItem[];
    const assistantItemsInstructions = useAppSelector(state =>
        getAssistantItemsByQuery(state, {
            type: AssistantItemTypes.SECTION,
            id: activeSectionConfig?.id,
            contentType: AssistantItemSectionContentType.DEFAULT,
        }),
    ) as AssistantSectionItem[];

    const handleTabChange = useCallback((tab: "advice" | "examples") => {
        setActiveTab(tab);
        if (tab === "examples") {
            trackClarityEvent(
                CLARITY_EVENT_NAMES.ASSISTANT_SECTION_EXAMPLE_CLICKED,
            );
        }
    }, []);

    const filteredExamples = useMemo(() => {
        const role = activeJobRole || "All";
        return assistantItemsExamples.filter(item => item.jobRoleType === role);
    }, [assistantItemsExamples, activeJobRole]);

    useEffect(() => {
        setNumVisibleExamples(2);
    }, [activeJobRole]);

    useEffect(() => {
        if (activeTab === "examples" && filteredExamples.length === 0) {
            handleTabChange("advice");
        }
    }, [
        activeSectionConfig,
        activeTab,
        filteredExamples.length,
        handleTabChange,
    ]);

    return activeSectionConfig ? (
        <div className="assistant-sections flex flex-col gap-4">
            <AppText
                variant="subheadings"
                className="mx-4"
            >
                {activeSectionConfig.name}
            </AppText>
            <JobRoleSelector />
            <div className="relative overflow-hidden">
                <div className="flex">
                    <motion.div
                        className="w-full flex-shrink-0 px-4"
                        animate={{ x: activeTab === "advice" ? "0%" : "-100%" }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 30,
                        }}
                    >
                        <div className="flex flex-col gap-4 pb-10">
                            {filteredExamples.length > 0 && (
                                <Button
                                    rightIcon={<ChevronRight size={16} />}
                                    onClick={() => handleTabChange("examples")}
                                    className="w-full px-20"
                                >
                                    See Examples
                                </Button>
                            )}
                            <AssistantItemsContainer
                                icon={<Edit3Icon size={16} />}
                                title="Writing Advice"
                            >
                                {assistantItemsInstructions?.map(
                                    (item, index) => (
                                        <AssistantItemBlock
                                            content={item.properties.content}
                                            key={`section-instruction-${index}`}
                                            isLastItem={
                                                index ===
                                                assistantItemsInstructions.length -
                                                    1
                                            }
                                            isExpandable={false}
                                            canCopy={false}
                                        />
                                    ),
                                )}
                            </AssistantItemsContainer>
                        </div>
                    </motion.div>
                    <motion.div
                        className="w-full flex-shrink-0 px-4"
                        animate={{
                            x: activeTab === "examples" ? "-100%" : "0%",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 30,
                        }}
                    >
                        <div className="mb-10 flex flex-col gap-4">
                            <Button
                                color={"softIndigo"}
                                onClick={() => handleTabChange("advice")}
                                className="w-full px-20"
                                leftIcon={<ChevronLeft size={16} />}
                            >
                                Back to Advice
                            </Button>
                            <AssistantItemsContainer
                                icon={<Lightbulb size={16} />}
                                title="Examples"
                            >
                                <AnimatePresence initial={false}>
                                    {filteredExamples?.length &&
                                        filteredExamples
                                            .slice(0, numVisibleExamples)
                                            .map((item, index) => (
                                                <motion.div
                                                    key={`section-example-${index}`}
                                                    initial={{
                                                        opacity: 0,
                                                        height: 0,
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        height: "auto",
                                                    }}
                                                    exit={{
                                                        opacity: 0,
                                                        height: 0,
                                                    }}
                                                    transition={{
                                                        duration: 0.2,
                                                    }}
                                                >
                                                    <AssistantItemBlock
                                                        content={
                                                            item.properties
                                                                .content
                                                        }
                                                        isLastItem={
                                                            index ===
                                                            filteredExamples.length -
                                                                1
                                                        }
                                                        isExpandable={true}
                                                        canCopy={true}
                                                        title={
                                                            item.properties
                                                                .title
                                                        }
                                                    />
                                                </motion.div>
                                            ))}
                                </AnimatePresence>
                                {numVisibleExamples <
                                    filteredExamples.length && (
                                    <div className="mt-4 flex justify-center">
                                        <Button
                                            color={"primary"}
                                            variant={"text"}
                                            onClick={() =>
                                                setNumVisibleExamples(
                                                    numVisibleExamples + 2,
                                                )
                                            }
                                        >
                                            See More
                                        </Button>
                                    </div>
                                )}
                            </AssistantItemsContainer>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    ) : (
        <Alert
            className="mx-4 mt-1"
            type="info"
            message="Select a section to view help from the assistant."
        />
    );
};

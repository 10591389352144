import { useCallback, useState } from "react";
import { Modal } from "../../Modal/Modal";
import resumeApi from "@/api/resume";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { removeResume } from "@/store/resume/slice";
import { ModalTypes } from "@/types/modal";

const DeleteResumeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.DELETE_RESUME));
    const modalProps = useAppSelector(getModalProps(ModalTypes.DELETE_RESUME));
    const { resumeId } = modalProps ?? {};
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useAppDispatch();

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.DELETE_RESUME));
    }, [dispatch]);

    const onDeleteConfirm = useCallback(() => {
        setIsDeleting(true);
        resumeApi
            .deleteResume(resumeId)
            .then(() => {
                dispatch(removeResume(resumeId));
            })
            .finally(() => {
                setIsDeleting(false);
                onClose();
            });
    }, [resumeId, dispatch, onClose]);

    return (
        <Modal
            open={isOpen}
            title="Delete Resume"
            width="md"
            closeOnOverlayClick
            modalType={ModalTypes.DELETE_RESUME}
            footer={
                <ModalFooter
                    primaryActionOnClick={onDeleteConfirm}
                    primaryActionText="Delete Resume"
                    secondaryActionOnClick={onClose}
                    secondaryActionText="Cancel"
                    primaryActionColor={"red"}
                    secondaryActionColor={"grey"}
                    primaryActionLoading={isDeleting}
                />
            }
        >
            <div className="flex flex-col justify-center">
                <AppText
                    variant="regular"
                    className="text-neutral-900"
                >
                    Are you sure you want to delete this resume?
                </AppText>
            </div>
        </Modal>
    );
};

export default DeleteResumeModal;

import { Eye } from "lucide-react";
import { useCallback } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { openModal } from "@/store/modal/slice";
import { TemplateLoadingState } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

export const PreviewResumeButton = () => {
    const dispatch = useAppDispatch();
    const templateLoadingState = useAppSelector(getTemplateLoadingState);

    const onOpenPreviewResumeModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.RESUME_PREVIEW }));
        trackClarityEvent(CLARITY_EVENT_NAMES.PREVIEW_RESUME_BUTTON_CLICKED);
    }, [dispatch]);

    return (
        <div className="group">
            <IconButton
                variant={"solid"}
                color={"primary"}
                onClick={onOpenPreviewResumeModal}
                disabled={templateLoadingState !== TemplateLoadingState.READY}
                className={BuilderTourStep.PREVIEW_RESUME}
            >
                <Eye size={20} />
            </IconButton>
            <Tooltip
                text="Preview Resume"
                positionClassName="-translate-x-[90%] translate-y-[80%]"
            />
        </div>
    );
};

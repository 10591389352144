import { Section } from "@react-email/components";
import { EmailButton } from "@/email/components/EmailButton";
import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { User } from "@/types/user";

interface DocumentAttachmentEmailProps {
    user: User;
}

const DocumentAttachmentEmail = ({ user }: DocumentAttachmentEmailProps) => {
    const intro = user?.firstName ? `Hey ${user?.firstName},` : "Hey,";
    return (
        <EmailWrapper preview="Your 1Template Resume is attached!">
            <EmailHeading>Your Resume is Ready!</EmailHeading>
            <EmailText>{intro}</EmailText>
            <Section>
                <EmailText>
                    Your professionally crafted 1Template resume is attached and
                    ready to make an impact!
                </EmailText>
            </Section>
            <Section
                style={{
                    marginTop: "16px",
                    padding: "16px",
                    backgroundColor: "#f8f9fa",
                    borderRadius: "8px",
                    border: "1px solid #e9ecef",
                }}
            >
                <EmailText
                    additionalStyle={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#303330",
                    }}
                >
                    Find the perfect plan – credits or unlimited access!
                </EmailText>
                <EmailText
                    additionalStyle={{
                        color: "#303330",
                        marginBottom: "16px",
                        fontSize: "16px",
                    }}
                >
                    Get additional credits or subscribe for unlimited access to
                    keep your job search on track.
                </EmailText>
                <EmailButton
                    noMargin
                    href="https://www.1template.io/#pricing?utm_source=email&utm_medium=email&utm_campaign=email_pdf_download"
                >
                    See Plans
                </EmailButton>
            </Section>
            <Section style={{ marginTop: "32px" }}>
                <EmailText noMargin>Best regards,</EmailText>
                <EmailText>The 1Template Team</EmailText>
            </Section>
        </EmailWrapper>
    );
};

export default DocumentAttachmentEmail;

import { useContext, useEffect } from "react";
import { PageGrid } from "@/components/Grid/PageGrid";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { Page } from "@/components/Page/Page";
import { PageContent } from "@/components/Page/PageContent";
import { TemplateSection } from "@/components/Section/TemplateSection";
import { DOCUMENT_CONTAINER_CLASS } from "@/constants/resume";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { resetAiToolbox } from "@/store/app/slice";
import { getPageHeaderSection, getPages } from "@/store/pages/selectors";
import { setActiveItemId, setActiveSection } from "@/store/template/slice";
import { EditorContextType } from "@/types/editor";

export const ResumePage = () => {
    const pages = useAppSelector(getPages);
    const pageHeaderSection = useAppSelector(state =>
        getPageHeaderSection(state, 0),
    );
    const dispatch = useAppDispatch();
    const { setActiveEditor } = useContext(EditorContext) as EditorContextType;

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const isPageClick = (e.target as HTMLElement)?.id.includes(
                "document-page-",
            );
            const isDocumentContainerClick = (
                e.target as HTMLElement
            )?.classList?.contains(DOCUMENT_CONTAINER_CLASS);
            const isPageContentContainer = (
                e.target as HTMLElement
            )?.id?.includes("page-");

            if (
                isDocumentContainerClick ||
                isPageClick ||
                isPageContentContainer
            ) {
                dispatch(setActiveItemId(null));
                dispatch(resetAiToolbox());
                dispatch(setActiveSection());
                setActiveEditor();
            }
        };
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [dispatch, setActiveEditor]);

    return (
        <>
            {pages.map(page => (
                <Page
                    key={page.pageNumber}
                    pageNumber={page.pageNumber}
                >
                    <PageContent
                        pageNumber={page.pageNumber}
                        id={`page-${page.pageNumber}`}
                        className="grid"
                        style={{ gridTemplateRows: "auto 1fr" }}
                    >
                        {page.pageNumber === 0 && pageHeaderSection && (
                            <div className={BuilderTourStep.HEADER_SECTION}>
                                <TemplateSection
                                    sectionId={pageHeaderSection?.sectionId}
                                    shouldMeasure={true}
                                    pageNumber={page.pageNumber}
                                />
                            </div>
                        )}
                        <PageGrid page={page} />
                    </PageContent>
                </Page>
            ))}
        </>
    );
};

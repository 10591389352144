import clsx from "clsx";
import { ArrowRight, ChevronLeft } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useWizard } from "react-use-wizard";
import { Button } from "@/components/Button/Button";
import { useAppSelector } from "@/hooks/types";
import { getUserPreferenceWizard } from "@/store/wizard/selectors";

interface PreferencesFooterProps {
    onComplete: () => void;
    sourcePath: string | null;
    skipButtonRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const PreferencesFooter = ({
    onComplete,
    sourcePath,
    skipButtonRef,
}: PreferencesFooterProps) => {
    const { nextStep, previousStep, isLastStep, activeStep } = useWizard();
    const userPreferences = useAppSelector(getUserPreferenceWizard);
    const isTrueFirstStep = sourcePath ? activeStep === 1 : activeStep === 0;

    const handleNext = useCallback(() => {
        if (isLastStep) {
            onComplete();
        } else {
            nextStep();
        }
    }, [isLastStep, nextStep, onComplete]);

    const canGoNext = useMemo(() => {
        if (activeStep === 1) {
            return userPreferences.industries?.length > 0;
        } else if (activeStep === 2) {
            return userPreferences.jobRoles?.length > 0;
        } else if (activeStep === 3) {
            return !!userPreferences.experienceLevel;
        } else if (activeStep === 4) {
            return userPreferences.jobTypes?.length > 0;
        } else {
            return true;
        }
    }, [
        activeStep,
        userPreferences.experienceLevel,
        userPreferences.industries?.length,
        userPreferences.jobTypes?.length,
        userPreferences.jobRoles?.length,
    ]);

    const onBack = useCallback(() => {
        previousStep();
        if (!sourcePath && activeStep === 1)
            skipButtonRef.current?.classList.add("opacity-0");
    }, [activeStep, previousStep, skipButtonRef, sourcePath]);

    return (
        <div
            className={clsx(
                "flex h-[84px] items-end justify-between bg-white",
                activeStep === 0 && !sourcePath ? "opacity-0" : "opacity-100",
            )}
        >
            <Button
                leftIcon={<ChevronLeft size={16} />}
                onClick={onBack}
                color={"grey"}
                disabled={isTrueFirstStep}
            >
                Back
            </Button>
            <div className="flex items-center gap-4">
                {sourcePath && (
                    <Button
                        onClick={() => {
                            onComplete();
                        }}
                        color={"softIndigo"}
                        variant={"solid"}
                    >
                        Save
                    </Button>
                )}
                <Button
                    rightIcon={<ArrowRight size={16} />}
                    onClick={handleNext}
                    disabled={!canGoNext}
                >
                    {isLastStep ? "Finish" : "Next"}
                </Button>
            </div>
        </div>
    );
};

import { CircleHelpIcon } from "lucide-react";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../../Modal/Modal";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import { CreditPacks } from "@/components/Credits/CreditPacks";
import { CreditsIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import {
    CLARITY_EVENT_NAMES,
    GA_EVENT_ACTIONS,
    GA_EVENT_CATEGORIES,
    META_CUSTOM_EVENT_ACTIONS,
} from "@/types/tracking";
import {
    trackClarityEvent,
    trackGAEvent,
    trackMetaCustomEvent,
} from "@/utils/tracking";

const BuyCreditsModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.BUY_CREDITS));
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const userBalance = useAppSelector(getUserCreditBalance);
    const openCreditsModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_INFO }));
    }, [dispatch]);

    const openCreditsSuccessModal = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.CREDITS_SUCCESS,
                props: { mode: "buy" },
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        const res = searchParams.get("res"); // either success or cancel
        if (res !== "success") {
            trackGAEvent(
                GA_EVENT_CATEGORIES.STRIPE,
                GA_EVENT_ACTIONS.SUBMIT,
                res === "success" ? "subscribe-success" : "subscribe-canceled",
            );
        }

        if (res === "success") {
            openCreditsSuccessModal();
            trackClarityEvent(CLARITY_EVENT_NAMES.SUBSCRIPTION_SUCCESS);
        }

        if (res === "cancel") {
            trackMetaCustomEvent(META_CUSTOM_EVENT_ACTIONS.CANCEL_PURCHASE);
            trackClarityEvent(CLARITY_EVENT_NAMES.SUBSCRIPTION_CANCELED);
        }
        setSearchParams({}, { replace: true });
    }, [searchParams, dispatch, setSearchParams, openCreditsSuccessModal]);

    return (
        <Modal
            modalType={ModalTypes.BUY_CREDITS}
            open={isOpen}
            title="Purchase Credits"
            width="5xl"
            gradientBackground
        >
            <AppText variant="regular">
                Credits let you access and utilise more features and download
                your resume as you need them.
            </AppText>
            <div className="mt-2 flex flex-row items-center justify-between lg:mt-4">
                <div className="flex items-center gap-2">
                    <AppText
                        variant="labelsbuttons"
                        className="my-2 text-neutral-900 lg:my-4"
                    >
                        You have:
                    </AppText>
                    <div>
                        <CreditsChip
                            credits={userBalance}
                            type="gradient"
                            borderless
                            leftIcon={<CreditsIcon className="h-4 w-4" />}
                        />
                    </div>
                </div>
                <Button
                    color={"primary"}
                    variant={"text"}
                    rightIcon={<CircleHelpIcon size={16} />}
                    onClick={openCreditsModal}
                >
                    What are Credits?
                </Button>
            </div>
            <CreditPacks />
        </Modal>
    );
};

export default BuyCreditsModal;

import { ButtonColour, ButtonSize, ButtonVariant } from "@/components/Button/Button";

type Styles = { [key: string]: string; };

type ButtonSizes = Record<ButtonSize, string>;

export const buttonSizes: ButtonSizes = {
    small: "h-7 min-w-10 group inline-flex items-center justify-center rounded-lg py-2 px-3 xl:px-3",
    default: "h-9 min-w-24 group inline-flex items-center justify-center rounded-lg py-2 px-4 xl:px-6",
};

export const buttonSharedStyles = "font-semibold focus:outline-none disabled:opacity-25 disabled:pointer-events-none";

export const buttonVariantStyles: Styles = {
    solid: "focus-visible:outline-2 focus-visible:outline-offset-2 ",
    outline: "ring-2 ring-inset",
    text: "",
};

export const buttonColours: Record<ButtonVariant, Record<ButtonColour, string>> = {
    solid: {
        primary: "bg-primary-700 text-neutral-50 hover:bg-primary-800 focus-visible:outline-primary-900 disabled:hover:bg-primary-700 active:bg-primary-800",
        grey: "bg-neutral-100 text-neutral-900 hover:bg-neutral-200 active:bg-neutral-200 focus-visible:outline-primary-900 disabled:hover:bg-neutral-100",
        white: "bg-white text-neutral-900 hover:bg-neutral-100 active:bg-neutral-100 focus-visible:outline-primary-900 disabled:hover:bg-white",
        red: "bg-error-500 text-neutral-50 hover:bg-error-600 active:bg-error-400 focus-visible:outline-primary-900 disabled:hover:bg-error-500",
        softIndigo: "bg-primary-100 text-primary-700 hover:bg-primary-200 hover:text-primary-800 active:bg-primary-50 focus-visible:outline-primary-900 disabled:hover:bg-primary-100",
        alert: "text-alert-800 bg-alert-100 hover:bg-alert-200 hover:text-alert-800 active:bg-alert-50 focus-visible:outline-alert-900 disabled:hover:bg-alert-100",
        gradient: "gradient-button",
    },
    outline: {
        primary: "ring-primary-700 text-primary-700 hover:text-primary-600 hover:ring-primary-600 active:ring-primary-400 active:text-primary-400 focus-visible:outline-primary-900",
        white: "ring-neutral-700 text-neutral-50 hover:ring-slate-500 active:ring-slate-700 active:text-neutral-400 focus-visible:outline-white focus-visible:outline-primary-900",
        grey: "ring-neutral-300 text-neutral-900 hover:bg-neutral-100 active:ring-neutral-400 active:text-neutral-400 focus-visible:outline-primary-900",
        softIndigo: "ring-neutral-300 text-primary-900 hover:bg-primary-50 active:ring-primary-400 active:text-primary-900 focus-visible:outline-primary-900",
        alert: "ring-alert-700 text-alert-800 hover:ring-alert-600 active:ring-alert-400 active:text-alert-800 focus-visible:outline-alert-900",
        red: "ring-error-700 text-error-500 hover:ring-error-600 active:ring-error-400 active:text-error-500 focus-visible:outline-error-900",
        gradient: "gradient-border-button",
    },
    text: {
        primary: "text-primary-700 hover:text-primary-600 active:text-primary-400 focus-visible:outline-primary-900",
        white: "text-neutral-50 hover:text-neutral-400 active:text-neutral-400 focus-visible:outline-white focus-visible:outline-primary-900",
        grey: "text-neutral-900 hover:text-neutral-400 active:text-neutral-400 focus-visible:outline-primary-900",
        softIndigo: "text-primary-700 hover:text-primary-600 active:text-primary-400 focus-visible:outline-primary-900",
        alert: "text-alert-800 hover:text-alert-600 active:text-alert-400 focus-visible:outline-alert-900",
        red: "text-error-500 hover:text-error-600 active:text-error-400 focus-visible:outline-error-900",
        gradient: "",
    }
};

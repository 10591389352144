import { CircleHelpIcon } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import { CreditPackButtons } from "@/components/Credits/CreditPackButtons";
import { CreditsIcon } from "@/components/Icons/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { ProfileTransactions } from "@/pages/Profile/ProfileTransactions";
import { setShowPlansModal } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import {
    getStripeCustomerId,
    getUserCreditBalance,
} from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";

export const ProfilePlan = () => {
    const stripeCustomerId = useAppSelector(getStripeCustomerId);
    const [portalUrl, setPortalUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(!portalUrl);
    const [searchParams, setSearchParams] = useSearchParams();
    const [refresh, setRefresh] = useState(
        searchParams.get("refresh") === "true",
    );
    const { getAndUpdateUserPlan, createCustomerPortalSession } = useStripe();
    const userBalance = useAppSelector(getUserCreditBalance);
    const [showSuccess, setShowSuccess] = useState(
        searchParams.get("res") === "success",
    );
    const dispatch = useAppDispatch();

    const openCreditsModal = () => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_INFO }));
    };

    const getUserPlan = useCallback(async () => {
        await getAndUpdateUserPlan();
        setLoading(false);
    }, [getAndUpdateUserPlan]);

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            getUserPlan();
            setRefresh(false);
            setSearchParams({}, { replace: true });
        }
    }, [refresh, getUserPlan, setRefresh, setSearchParams]);

    useEffect(() => {
        if (showSuccess) {
            dispatch(setShowPlansModal("upgrade"));
            setShowSuccess(false);
        }
    }, [showSuccess, setSearchParams, dispatch]);

    useEffect(() => {
        const getPortalSession = async () => {
            const url = await createCustomerPortalSession(
                `${window.location.origin}/${AppRoutes.Profile}?tab=credits&refresh=true`,
            );
            setPortalUrl(url);
            setLoading(false);
        };

        getPortalSession();
    }, [createCustomerPortalSession, stripeCustomerId]);

    return (
        <div className="flex flex-col">
            <AppText
                variant="headings"
                className="mb-4 hidden text-neutral-900 md:block"
            >
                Credits
            </AppText>
            <AppText
                variant="regular"
                className="mb-8 text-neutral-900"
            >
                Credits let you access more features and download your resume as
                you need them.
            </AppText>
            {loading ? (
                <div className="m-auto my-40">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="flex flex-col space-y-6">
                    <div className="flex flex-col">
                        <AppText
                            variant="subheadings"
                            className="mb-4 text-neutral-900"
                        >
                            Balance
                        </AppText>
                        <div className="flex flex-col rounded-2xl bg-neutral-100 p-4">
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex items-center">
                                    <AppText
                                        variant="labelsbuttons"
                                        className="text-neutral-900"
                                    >
                                        Current Balance:
                                    </AppText>
                                    <div>
                                        <CreditsChip
                                            credits={userBalance}
                                            type="gradient"
                                            borderless
                                            leftIcon={
                                                <CreditsIcon className="h-4 w-4" />
                                            }
                                        />
                                    </div>
                                </div>
                                <Button
                                    color={"softIndigo"}
                                    rightIcon={<CircleHelpIcon size={16} />}
                                    onClick={openCreditsModal}
                                >
                                    What are Credits?
                                </Button>
                            </div>
                            <div className="mx-auto mt-4">
                                <CreditPackButtons buttonPrefix="Buy" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <AppText
                            variant="subheadings"
                            className="text-neutral-900"
                        >
                            Billing & Purchase
                        </AppText>
                        <AppText
                            variant="regular"
                            className="my-4 text-neutral-900"
                        >
                            You can view and manage your billing information and
                            receipts{" "}
                            <NavLink
                                to={portalUrl || "#"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="font-medium text-primary-700 underline"
                            >
                                here
                            </NavLink>
                            .
                        </AppText>
                    </div>
                    <ProfileTransactions />
                </div>
            )}
        </div>
    );
};

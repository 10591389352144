import { FieldArray, Formik } from "formik";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { InlineCodeEditor } from "../InlineCodeEditor/InlineCodeEditor";
import { PreviewComponent } from "./PreviewComponent";
import { sectionSchema } from "./schema/section";
import documentTypeApi from "@/api/documentType";
import sectionApi from "@/api/section";
import { Button } from "@/components/Button/Button";
import { Loader } from "@/components/Icons";
import { MultiSelect } from "@/components/MultiSelect/MultiSelect";
import { AppText } from "@/components/Text/AppText";
import { Checkbox } from "@/components/form/Checkbox";
import { Input } from "@/components/form/Input";
import { InputError } from "@/components/form/InputError";
import { InputLabel } from "@/components/form/InputLabel";
import { Select } from "@/components/form/Select";
import { useAppDispatch } from "@/hooks/types";
import { AdminFieldContainer } from "@/pages/Admin/AdminFieldContainer";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { LayoutFieldOptionsModal } from "@/pages/Admin/Sections/LayoutFieldOptionsModal";
import {
    supportedWidthOptions,
    fieldToggleStateOptions,
    layoutFieldToggleStateOptions,
    sectionFormNavItems,
    layoutFieldTypes,
    sectionTagOptions,
} from "@/pages/Admin/Sections/constants";
import { addNotification } from "@/store/app/slice";
import {
    DocumentType,
    NotificationMessageType,
    NotificationType,
    Section,
} from "@/types";
import {
    LayoutField,
    LayoutFieldOption,
    SectionField,
    SectionWidth,
    ToggleState,
} from "@/types/section";
import { sortFieldsByOrder, transformFieldsToProps } from "@/utils/section";

export const SectionForm = () => {
    const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
    const { getAdminSection } = useLoaderData() as {
        getAdminSection: Promise<Section>;
    };
    useEffect(() => {
        const getDocTypes = async () => {
            const response = await documentTypeApi.getAllDocumentTypes();
            setDocTypes(response.results);
        };
        getDocTypes();
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminSection}
                errorElement={<div>Failed to load section</div>}
            >
                {resolvedValues => (
                    <SectionFormFields
                        section={resolvedValues}
                        documentTypes={docTypes}
                    />
                )}
            </Await>
        </Suspense>
    );
};

const SectionFormFields = ({
    section,
    documentTypes,
}: {
    section?: Section;
    documentTypes: DocumentType[];
}) => {
    const [activeTab, setActiveTab] = useState<string>(
        sectionFormNavItems[0].id,
    );
    const [editingLayoutFieldIndex, setEditingLayoutFieldIndex] = useState<
        number | null
    >(null);
    const initialValues: Partial<Section> = useMemo(
        () => ({
            name: section?.name || "",
            documentType: section?.documentType || "",
            sectionType: section?.sectionType || "",
            titleComp: section?.titleComp || "",
            component: section?.component || "",
            bodyFields: sortFieldsByOrder(
                section?.bodyFields || [],
            ) as SectionField[],
            headerFields: sortFieldsByOrder(
                section?.headerFields || [],
            ) as SectionField[],
            layoutFields: sortFieldsByOrder(
                section?.layoutFields || [],
            ) as LayoutField[],
            supportedWidths: section?.supportedWidths || [
                supportedWidthOptions[0].id as SectionWidth,
            ],
            isStaticHeader: section?.isStaticHeader || false,
            isCustomSection: section?.isCustomSection || false,
            tags: section?.tags || [],
        }),
        [section],
    );

    const dispatch = useAppDispatch();
    const { sectionId: id } = useParams();
    const sectionId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (section: Partial<Section>) => {
        try {
            await sectionApi
                .saveSection(section as Section, sectionId)
                .then(() => {
                    if (!sectionId) {
                        navigate("/admin/sections", { replace: true }); // push user back to sections list
                        return;
                    }
                    dispatch(
                        addNotification({
                            title: "Successfully saved!",
                            desc: "Your details is successfully saved!!",
                            messageType:
                                NotificationMessageType.ADMIN_SECTION_SAVE,
                            type: NotificationType.SUCCESS,
                        }),
                    );
                });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_SECTION_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        // Todo, delete confirmation modal?
        try {
            if (!sectionId) {
                navigate("/admin/sections", { replace: true }); // push user back to sections list
                return;
            }
            await sectionApi.deleteSection(sectionId).then(() => {
                navigate("/admin/sections", { replace: true });
                dispatch(
                    addNotification({
                        title: "Successfully deleted",
                        desc: "Section successfully deleted",
                        messageType:
                            NotificationMessageType.ADMIN_SECTION_DELETE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_SECTION_SAVE,
            );
        }
    }, [handleError, navigate, sectionId, dispatch]);

    return (
        <AdminFormContainer
            navigationItems={sectionFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle={initialValues?.name || "Section"}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={sectionSchema}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    submitForm,
                }) => (
                    <div>
                        <div className="px-8">
                            {activeTab === "details" && (
                                <div className="flex flex-col gap-4 bg-white">
                                    <div>
                                        <InputLabel
                                            label="Section Display Name"
                                            htmlFor="name"
                                            required
                                            description="The name of the section as it will be displayed in the app"
                                        />
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={values.name}
                                            formikChange={handleChange}
                                            error={errors.name}
                                        />
                                    </div>
                                    <div className="">
                                        <InputLabel
                                            label="Document Type"
                                            htmlFor="documentType"
                                            required
                                            description="Type of document this section is for. E.g. resume"
                                        />
                                        <div className="w-52">
                                            <Select
                                                value={
                                                    documentTypes.find(
                                                        dt =>
                                                            dt.id ===
                                                            values.documentType,
                                                    ) ?? {
                                                        id: "",
                                                        name: "",
                                                    }
                                                }
                                                onChange={value =>
                                                    setFieldValue(
                                                        "documentType",
                                                        value.id,
                                                    )
                                                }
                                                items={documentTypes.map(
                                                    ({ id, name }) => ({
                                                        id,
                                                        name,
                                                    }),
                                                )}
                                            />
                                        </div>
                                        <InputError
                                            error={errors.documentType}
                                        />
                                    </div>
                                    <div className="">
                                        <InputLabel
                                            label="Section Type"
                                            htmlFor="sectionType"
                                            required
                                            description="The ID for the section. This is used to identify the section in the app."
                                        />
                                        <Input
                                            type="text"
                                            name="sectionType"
                                            id="sectionType"
                                            value={values.sectionType}
                                            formikChange={handleChange}
                                            error={errors.sectionType}
                                        />
                                    </div>
                                    <div className="">
                                        <InputLabel
                                            label="Supported widths"
                                            htmlFor="supportedWidths"
                                            required
                                            description="The widths this section can be displayed in. E.g. full, half, one-third, two-thirds"
                                        />
                                        <div className="w-64">
                                            <MultiSelect
                                                items={supportedWidthOptions}
                                                onChange={items => {
                                                    setFieldValue(
                                                        "supportedWidths",
                                                        items.map(i => i.id),
                                                    );
                                                }}
                                                selectedValues={
                                                    values.supportedWidths
                                                        ?.map(w => {
                                                            const item =
                                                                supportedWidthOptions.find(
                                                                    i =>
                                                                        i.id ===
                                                                        w,
                                                                );
                                                            return item;
                                                        })
                                                        .filter(Boolean) ?? []
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <InputLabel
                                            label="Tags"
                                            htmlFor="tags"
                                            description="Optional tags"
                                        />
                                        <div className="w-64">
                                            <MultiSelect
                                                items={sectionTagOptions}
                                                onChange={items => {
                                                    setFieldValue(
                                                        "tags",
                                                        items.map(i => i.id),
                                                    );
                                                }}
                                                selectedValues={
                                                    values.tags
                                                        ?.map(w => {
                                                            const item =
                                                                sectionTagOptions.find(
                                                                    i =>
                                                                        i.id ===
                                                                        w,
                                                                );
                                                            return item;
                                                        })
                                                        .filter(Boolean) ?? []
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-[400px] gap-4">
                                        <div>
                                            <InputLabel label="Static Header" />
                                            <Checkbox
                                                id="isStaticHeader"
                                                label="Is Static Header"
                                                description="This section cannot be removed, or moved. It will always be at the top of the document."
                                                checked={
                                                    !!values.isStaticHeader
                                                }
                                                onChange={(checked: boolean) =>
                                                    setFieldValue(
                                                        "isStaticHeader",
                                                        checked,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <InputLabel label="Custom Section" />
                                            <Checkbox
                                                id="isCustomSection"
                                                label="Is Custom Section"
                                                description="This is the source custom section that can be configured by the user"
                                                checked={
                                                    !!values.isCustomSection
                                                }
                                                onChange={(checked: boolean) =>
                                                    setFieldValue(
                                                        "isCustomSection",
                                                        checked,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="w-96"></div>
                                </div>
                            )}
                            {activeTab === "layout" && (
                                <div className="flex flex-col bg-white">
                                    <div className="col-span-1 mt-2 px-8 py-2">
                                        <InputLabel
                                            label="Title Component"
                                            htmlFor="titleComp"
                                            required
                                            description="The HTML layout for the user entered title. Add an ID to inject values."
                                        />
                                        <InlineCodeEditor
                                            value={values.titleComp ?? ""}
                                            onChange={(html: string) =>
                                                setFieldValue("titleComp", html)
                                            }
                                            language="html"
                                        />
                                        <InputError error={errors.titleComp} />
                                    </div>
                                    <div className="col-span-1 mt-2 px-8 py-2">
                                        <InputLabel
                                            label="Component"
                                            htmlFor="component"
                                            required
                                            description="The HTML layout of the content component. Add an ID to inject values."
                                        />
                                        <InlineCodeEditor
                                            value={values.component ?? ""}
                                            onChange={(html: string) =>
                                                setFieldValue("component", html)
                                            }
                                            language="html"
                                        />
                                        <InputError error={errors.component} />
                                    </div>
                                </div>
                            )}
                            {activeTab === "header" && (
                                <FieldArray
                                    name="headerFields"
                                    render={({ push, remove }) => (
                                        <>
                                            <InputError
                                                error={errors.headerFields}
                                            />
                                            {(values.headerFields ?? []).map(
                                                (headerField, index) => (
                                                    <AdminFieldContainer
                                                        key={`header-field-${index}`}
                                                        title={
                                                            `${headerField.order ?? 0} - ` +
                                                                headerField.name ||
                                                            `Header Field ${index + 1}`
                                                        }
                                                        onDelete={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <div className="relative col-span-2 mb-3 grid grid-cols-6 items-baseline gap-x-4 rounded-lg bg-white px-4">
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Name"
                                                                    htmlFor={`headerFields.${index}.name`}
                                                                    required
                                                                    description="Prop key"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.name`}
                                                                    id={`headerFields.${index}.name`}
                                                                    value={
                                                                        headerField.name
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Display Name"
                                                                    htmlFor={`headerFields.${index}.displayName`}
                                                                    required
                                                                    description="Visible name on UI"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.displayName`}
                                                                    id={`headerFields.${index}.displayName`}
                                                                    value={
                                                                        headerField.displayName
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Type"
                                                                    htmlFor={`headerFields.${index}.type`}
                                                                    required
                                                                    description="text, date"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.type`}
                                                                    id={`headerFields.${index}.type`}
                                                                    value={
                                                                        headerField.type
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Format"
                                                                    htmlFor={`headerFields.${index}.format`}
                                                                    description="Date or field format"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.format`}
                                                                    id={`headerFields.${index}.format`}
                                                                    value={
                                                                        headerField.format
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Icon"
                                                                    htmlFor={`headerFields.${index}.icon`}
                                                                    description="ID of icon to be displayed. Ensure the icons equivalent PDF Icon has been added"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.icon`}
                                                                    id={`headerFields.${index}.icon`}
                                                                    value={
                                                                        headerField.icon
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Order"
                                                                    htmlFor={`headerFields.${index}.order`}
                                                                    description="Display order of the field in sidebar"
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name={`headerFields.${index}.order`}
                                                                    id={`headerFields.${index}.order`}
                                                                    value={
                                                                        headerField.order
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Default Value"
                                                                    htmlFor={`headerFields.${index}.defaultValue`}
                                                                    required
                                                                    description="Value to be used before user data entry. Supports HTML"
                                                                />
                                                                <InlineCodeEditor
                                                                    value={
                                                                        headerField.defaultValue ??
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        html: string,
                                                                    ) =>
                                                                        setFieldValue(
                                                                            `headerFields.${index}.defaultValue`,
                                                                            html,
                                                                        )
                                                                    }
                                                                    allowTextOnly
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Placeholder"
                                                                    htmlFor={`headerFields.${index}.placeholder`}
                                                                    required
                                                                    description="Value to be used after user clears the field"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`headerFields.${index}.placeholder`}
                                                                    id={`headerFields.${index}.placeholder`}
                                                                    value={
                                                                        headerField.placeholder
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Toggle State"
                                                                    htmlFor={`headerFields.${index}.toggleState`}
                                                                    required
                                                                    description="Default toggle state of the field. Disabled means toggle will not be shown for field"
                                                                />
                                                                <Select
                                                                    value={
                                                                        fieldToggleStateOptions.find(
                                                                            o =>
                                                                                o.id ===
                                                                                headerField.toggleState?.toString(),
                                                                        ) ??
                                                                        fieldToggleStateOptions[0]
                                                                    }
                                                                    onChange={value =>
                                                                        setFieldValue(
                                                                            `headerFields.${index}.toggleState`,
                                                                            value.id,
                                                                        )
                                                                    }
                                                                    items={
                                                                        fieldToggleStateOptions
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </AdminFieldContainer>
                                                ),
                                            )}
                                            <Button
                                                onClick={() =>
                                                    push({
                                                        type: "text",
                                                        toggleState:
                                                            ToggleState.ON,
                                                        order:
                                                            (values.headerFields
                                                                ?.length ?? 0) +
                                                            1,
                                                    })
                                                }
                                            >
                                                Add Field
                                            </Button>
                                        </>
                                    )}
                                />
                            )}
                            {activeTab === "body" && (
                                <FieldArray
                                    name="bodyFields"
                                    render={({ push, remove }) => (
                                        <>
                                            <InputError
                                                error={errors.bodyFields}
                                            />
                                            {(values.bodyFields ?? []).map(
                                                (bodyField, index) => (
                                                    <AdminFieldContainer
                                                        key={`body-field-${index}`}
                                                        title={
                                                            `${bodyField.order ?? 0} -  ` +
                                                                bodyField.name ||
                                                            `Body Field ${index + 1}`
                                                        }
                                                        onDelete={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <div
                                                            key={`body-field-${index}`}
                                                            className="relative col-span-2 mb-3 grid grid-cols-6 items-baseline gap-x-4 bg-white px-4 sm:rounded-xl"
                                                        >
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Name"
                                                                    htmlFor={`bodyFields.${index}.name`}
                                                                    required
                                                                    description="Prop name"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.name`}
                                                                    id={`bodyFields.${index}.name`}
                                                                    value={
                                                                        bodyField.name
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Display Name"
                                                                    htmlFor={`bodyFields.${index}.displayName`}
                                                                    required
                                                                    description="Visible name on UI"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.displayName`}
                                                                    id={`bodyFields.${index}.displayName`}
                                                                    value={
                                                                        bodyField.displayName
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Type"
                                                                    htmlFor={`bodyFields.${index}.type`}
                                                                    required
                                                                    description="text, date"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.type`}
                                                                    id={`bodyFields.${index}.type`}
                                                                    value={
                                                                        bodyField.type
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Format"
                                                                    htmlFor={`bodyFields.${index}.format`}
                                                                    description="Date or field format"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.format`}
                                                                    id={`bodyFields.${index}.format`}
                                                                    value={
                                                                        bodyField.format
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Icon"
                                                                    htmlFor={`bodyFields.${index}.icon`}
                                                                    description="ID of icon to be displayed. Ensure the icons equivalent PDF Icon has been added"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.icon`}
                                                                    id={`bodyFields.${index}.icon`}
                                                                    value={
                                                                        bodyField.icon
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Order"
                                                                    htmlFor={`bodyFields.${index}.order`}
                                                                    description="Display order of the field in sidebar"
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name={`bodyFields.${index}.order`}
                                                                    id={`bodyFields.${index}.order`}
                                                                    value={
                                                                        bodyField.order
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Default Value"
                                                                    htmlFor={`bodyFields.${index}.defaultValue`}
                                                                    required
                                                                    description="Value to be used before user data entry. Supports HTML"
                                                                />
                                                                <InlineCodeEditor
                                                                    value={
                                                                        bodyField.defaultValue ??
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        html: string,
                                                                    ) =>
                                                                        setFieldValue(
                                                                            `bodyFields.${index}.defaultValue`,
                                                                            html,
                                                                        )
                                                                    }
                                                                    allowTextOnly
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Placeholder"
                                                                    htmlFor={`bodyFields.${index}.placeholder`}
                                                                    required
                                                                    description="Value to be used after user clears the field"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`bodyFields.${index}.placeholder`}
                                                                    id={`bodyFields.${index}.placeholder`}
                                                                    value={
                                                                        bodyField.placeholder
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-2 my-2 py-2">
                                                                <InputLabel
                                                                    label="Toggle State"
                                                                    htmlFor={`bodyFields.${index}.toggleState`}
                                                                    required
                                                                    description="Default toggle state of the field. Disabled means toggle will not be shown for field"
                                                                />
                                                                <Select
                                                                    value={
                                                                        fieldToggleStateOptions.find(
                                                                            o =>
                                                                                o.id ===
                                                                                bodyField.toggleState?.toString(),
                                                                        ) ??
                                                                        fieldToggleStateOptions[0]
                                                                    }
                                                                    onChange={value =>
                                                                        setFieldValue(
                                                                            `bodyFields.${index}.toggleState`,
                                                                            value.id,
                                                                        )
                                                                    }
                                                                    items={
                                                                        fieldToggleStateOptions
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </AdminFieldContainer>
                                                ),
                                            )}
                                            <Button
                                                onClick={() =>
                                                    push({
                                                        type: "text",
                                                        toggleState:
                                                            ToggleState.ON,
                                                        order:
                                                            (values.bodyFields
                                                                ?.length ?? 0) +
                                                            1,
                                                    })
                                                }
                                            >
                                                Add Field
                                            </Button>
                                        </>
                                    )}
                                />
                            )}
                            {activeTab === "layout-fields" && (
                                <FieldArray
                                    name="layoutFields"
                                    render={({ push, remove }) => (
                                        <>
                                            <InputError
                                                error={errors.layoutFields}
                                            />
                                            {values.layoutFields?.map(
                                                (layoutField, index) => (
                                                    <AdminFieldContainer
                                                        key={`layout-field-${index}`}
                                                        title={
                                                            `${layoutField.order ?? 0} - ` +
                                                                layoutField.name ||
                                                            `Layout Field ${index + 1}`
                                                        }
                                                        onDelete={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <div
                                                            key={`layout-field-${index}`}
                                                            className="relative col-span-2 mb-3 grid grid-cols-4 items-baseline gap-x-4 bg-white px-4 sm:rounded-xl"
                                                        >
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Name (key)"
                                                                    htmlFor={`layoutFields.${index}.name`}
                                                                    required
                                                                    description="Prop key"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`layoutFields.${index}.name`}
                                                                    id={`layoutFields.${index}.name`}
                                                                    value={
                                                                        layoutField.name
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Display name"
                                                                    htmlFor={`layoutFields.${index}.displayName`}
                                                                    required
                                                                    description="How prop will display in sidebar"
                                                                />
                                                                <Input
                                                                    type="text"
                                                                    name={`layoutFields.${index}.displayName`}
                                                                    id={`layoutFields.${index}.displayName`}
                                                                    value={
                                                                        layoutField.displayName
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Order"
                                                                    htmlFor={`layoutFields.${index}.order`}
                                                                    required
                                                                    description="Display order of field in sidebar"
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    name={`layoutFields.${index}.order`}
                                                                    id={`layoutFields.${index}.order`}
                                                                    value={
                                                                        layoutField.order
                                                                    }
                                                                    formikChange={
                                                                        handleChange
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Toggle State"
                                                                    htmlFor={`layoutFields.${index}.toggleState`}
                                                                    required
                                                                    description="Hide or show toggle for field in sidebar"
                                                                />
                                                                <Select
                                                                    value={
                                                                        layoutFieldToggleStateOptions.find(
                                                                            o =>
                                                                                o.id ===
                                                                                layoutField.toggleState?.toString(),
                                                                        ) ??
                                                                        layoutFieldToggleStateOptions[0]
                                                                    }
                                                                    onChange={value =>
                                                                        setFieldValue(
                                                                            `layoutFields.${index}.toggleState`,
                                                                            value.id,
                                                                        )
                                                                    }
                                                                    items={
                                                                        layoutFieldToggleStateOptions
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Type"
                                                                    htmlFor={`layoutFields.${index}.type`}
                                                                    required
                                                                    description="dropdown only for now"
                                                                />
                                                                <Select
                                                                    value={
                                                                        layoutFieldTypes.find(
                                                                            o =>
                                                                                o.id ===
                                                                                layoutField.type,
                                                                        ) ??
                                                                        layoutFieldTypes[0]
                                                                    }
                                                                    onChange={value =>
                                                                        setFieldValue(
                                                                            `layoutFields.${index}.type`,
                                                                            value.id,
                                                                        )
                                                                    }
                                                                    items={
                                                                        layoutFieldTypes
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-span-1 my-2 py-2">
                                                                <InputLabel
                                                                    label="Default Value"
                                                                    htmlFor={`layoutFields.${index}.defaultValue`}
                                                                    required
                                                                    description="Value from options to be selected by default"
                                                                />
                                                                <Select
                                                                    value={
                                                                        layoutField.options?.find(
                                                                            o =>
                                                                                o.value ===
                                                                                layoutField.defaultValue,
                                                                        ) ??
                                                                        "-- Select an option --"
                                                                    }
                                                                    onChange={value =>
                                                                        setFieldValue(
                                                                            `layoutFields.${index}.defaultValue`,
                                                                            value.id,
                                                                        )
                                                                    }
                                                                    items={
                                                                        layoutField.options?.map(
                                                                            o => ({
                                                                                id: o.value,
                                                                                name: o.name,
                                                                            }),
                                                                        ) || []
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="my-2 py-2">
                                                                <InputLabel
                                                                    label="Options"
                                                                    htmlFor={`layoutFields.${index}.options`}
                                                                    description="Options for dropdown or toggle"
                                                                />
                                                                <AppText variant="labelsbuttons">
                                                                    Number of
                                                                    options:{" "}
                                                                    {
                                                                        layoutField
                                                                            .options
                                                                            ?.length
                                                                    }
                                                                </AppText>
                                                                <Button
                                                                    className="mt-2"
                                                                    onClick={() =>
                                                                        setEditingLayoutFieldIndex(
                                                                            index,
                                                                        )
                                                                    }
                                                                >
                                                                    Add/Edit
                                                                    Options
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </AdminFieldContainer>
                                                ),
                                            )}
                                            <Button
                                                onClick={() =>
                                                    push({
                                                        type: "dropdown",
                                                        toggleState:
                                                            ToggleState.ON,
                                                        order:
                                                            (values.layoutFields
                                                                ?.length ?? 0) +
                                                            1,
                                                    })
                                                }
                                            >
                                                Add Field
                                            </Button>
                                        </>
                                    )}
                                />
                            )}
                        </div>
                        <div className="mt-12 flex justify-between border-t px-8 pt-4">
                            <PreviewComponent
                                titleComponent={values.titleComp}
                                component={values.component}
                                headerFields={transformFieldsToProps(
                                    false,
                                    values.headerFields ?? [],
                                )}
                                bodyFields={transformFieldsToProps(
                                    true,
                                    values.bodyFields ?? [],
                                )}
                                layoutFields={values.layoutFields ?? []}
                            />
                        </div>
                        <AdminFormFooter
                            onSave={submitForm}
                            listHref="/admin/sections"
                            onDelete={handleDelete}
                        />
                        <LayoutFieldOptionsModal
                            isOpen={typeof editingLayoutFieldIndex === "number"}
                            onClose={() => setEditingLayoutFieldIndex(null)}
                            onSave={(newOptions: LayoutFieldOption[]) => {
                                setEditingLayoutFieldIndex(null);
                                setFieldValue(
                                    `layoutFields[${editingLayoutFieldIndex}].options`,
                                    newOptions,
                                );
                            }}
                            activeFieldIndex={editingLayoutFieldIndex}
                            currentOptions={
                                editingLayoutFieldIndex !== null
                                    ? (values.layoutFields?.[
                                          editingLayoutFieldIndex
                                      ]?.options ?? [])
                                    : []
                            }
                        />
                    </div>
                )}
            </Formik>
        </AdminFormContainer>
    );
};

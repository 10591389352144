import { PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { CircleCheckIcon, Key, Mail } from "lucide-react";
import { ReactNode, useState } from "react";
import Accordion from "@/components/Accordion/Accordion";
import { Button } from "@/components/Button/Button";
import { DatePeriodPicker } from "@/components/DatePeriodPicker/DatePeriodPicker";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { AppText } from "@/components/Text/AppText";
import { HeadingText } from "@/components/Text/HeadingText";
import { Input } from "@/components/form/Input";

const Container = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => <div className={clsx(className, "mr-16 flex flex-col")}>{children}</div>;

const Heading = ({
    title,
    className,
}: {
    title: string;
    className?: string;
}) => (
    <AppText
        className={clsx(className)}
        variant="subheadings"
    >
        {title}
    </AppText>
);

const SubHeading = ({
    subheading,
    className,
}: {
    subheading: string;
    className?: string;
}) => (
    <AppText
        className={clsx(className)}
        variant="contextheading"
    >
        {subheading}
    </AppText>
);

const Spacing = ({ id, value }: { id: string; value: string }) => (
    <div className="my-2 rounded border border-solid bg-slate-600">
        <div className={clsx(`pl-${id}`, "bg-slate-200")}>
            {id} {value}
        </div>
    </div>
);

export const StylePage = () => {
    const [switchEnabled, setSwitchEnabled] = useState(false);
    const [dropDownId, setDropdownId] = useState("0");
    const [dateValue, setDateValue] = useState("06/1991");
    return (
        <div className="px-16">
            <div className="mb-3 flex border-b-2">
                <Container>
                    <Heading title="Typography" />
                    <HeadingText variant="heading-1">
                        Heading: Heading-1
                    </HeadingText>
                </Container>
                <Container>
                    <Heading title="Fonts" />
                    <h1 className="text-xl">Poppins</h1>
                    <p>Space mono</p>
                </Container>
                <Container>
                    <Heading title="Colour Pallette" />
                    <div className="bg-primary-off-white mb-2 h-12 w-32 rounded p-1 text-xs">
                        Primary Off white
                    </div>
                    <div className="bg-primary-logo-blue mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        Primary Logo blue
                    </div>
                    <div className="bg-primary-purple mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        Primary Purple
                    </div>
                    <div className="bg-primary-dark-purple mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        Primary dark purple
                    </div>
                    <div className="bg-primary-light-purple mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        primary light purple
                    </div>
                    <div className="bg-primary-light-pink mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        primary light pink
                    </div>
                    <div className="bg-secondary-aqua-blue mb-2 h-12 w-32 rounded p-1 text-xs text-black">
                        secondary aqua blue
                    </div>
                    <div className="bg-secondary-dark-orange mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        secondary dark orange
                    </div>
                    <div className="bg-secondary-light-orange mb-2 h-12 w-32 rounded p-1 text-xs text-white">
                        secondary light orange
                    </div>
                    <div className="bg-secondary-yellow mb-2 h-12 w-32 rounded p-1 text-xs text-black">
                        secondary yellow
                    </div>
                </Container>
                <Container>
                    <Heading title="Spacing - Padding" />
                    <Spacing
                        id={"xxs"}
                        value="4px"
                    />
                    <Spacing
                        id={"xs"}
                        value="8px"
                    />
                    <Spacing
                        id={"sm"}
                        value="16px"
                    />
                    <Spacing
                        id={"med"}
                        value="24px"
                    />
                    <Spacing
                        id={"lg"}
                        value="48px"
                    />
                    <Spacing
                        id={"xlg"}
                        value="96px"
                    />
                    <Spacing
                        id={"icon"}
                        value="96px"
                    />
                    <Spacing
                        id={"section"}
                        value="96px"
                    />
                </Container>
            </div>
            <div className="mb-3 flex border-b-2">
                <Container>
                    <Heading title="Buttons" />
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"primary"}
                        leftIcon={<PlusIcon className="mr-1 w-4" />}
                    >
                        Solid Primary
                    </Button>
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"primary"}
                        leftIcon={<PlusIcon className="mr-1 w-4" />}
                        disabled
                    >
                        Solid Primary Disabled
                    </Button>
                    <Button
                        className="my-1"
                        variant={"outline"}
                        color={"primary"}
                    >
                        Outline Primary
                    </Button>
                    <Button
                        className="my-1"
                        variant={"outline"}
                        color={"primary"}
                        disabled
                    >
                        Outline Primary Disabled
                    </Button>
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"red"}
                    >
                        Solid Red
                    </Button>
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"red"}
                        disabled
                    >
                        Solid Red Disabled
                    </Button>
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"grey"}
                    >
                        Solid Grey
                    </Button>
                    <Button
                        className="my-1"
                        variant={"solid"}
                        color={"softIndigo"}
                    >
                        Solid Indigo
                    </Button>
                    <Button
                        className="my-3"
                        variant={"outline"}
                        color={"primary"}
                        leftIcon={<PlusIcon className="mr-2 w-4" />}
                    >
                        Left Icon
                    </Button>
                    <Button
                        className="my-3"
                        variant={"text"}
                        color={"primary"}
                    >
                        Text
                    </Button>
                    <Button
                        className="my-3"
                        variant={"text"}
                        color={"primary"}
                        leftIcon={<PlusIcon className="mr-2 w-4" />}
                    >
                        Text
                    </Button>
                </Container>

                <Container className="mb-2 w-64">
                    <Heading title="Text Inputs" />
                    <SubHeading
                        className="my-3"
                        subheading="With Label"
                    />
                    <Input
                        label="Input label"
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="No Label"
                    />
                    <Input onChange={(_s, _v) => false} />
                    <SubHeading
                        className="my-3"
                        subheading="With error"
                    />
                    <Input
                        error="Error message"
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="With Value"
                    />
                    <Input
                        defaultValue="Value"
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="With Placeholder"
                    />
                    <Input
                        placeholder="Placeholder"
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Disabled no value"
                    />
                    <Input
                        disabled
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Disabled value"
                    />
                    <Input
                        disabled
                        defaultValue="value"
                        onChange={(_s, _v) => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Leading icon"
                    />
                    <Input
                        onChange={(_s, _v) => false}
                        leadingIcon={<Mail />}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Trailing Icon"
                    />
                    <Input
                        onChange={(_s, _v) => false}
                        trailingIcon={<Key />}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Password"
                    />
                    <Input
                        type="password"
                        onChange={(_s, _v) => false}
                        defaultValue="password"
                    />
                </Container>
                <Container>
                    <Heading title="Accordion" />
                    <SubHeading
                        className="my-3"
                        subheading="Working example"
                    />
                    <Accordion title="Accordion">
                        <div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nulla vitae elit libero, a
                                pharetra augue. Sed non mauris vitae erat
                                consequat auctor eu in elit. Fusce dapibus,
                                tellus ac cursus commodo, tortor mauris
                                condimentum nibh, ut fermentum massa justo sit
                                amet risus.
                            </p>
                        </div>
                    </Accordion>
                </Container>
                <Container>
                    <Heading title="Calendar" />
                    <SubHeading
                        className="my-3"
                        subheading="Working example"
                    />
                    <DatePeriodPicker
                        value={dateValue}
                        onChange={setDateValue}
                    />
                </Container>
            </div>
            <div className="mb-3 flex border-b-2">
                <Container>
                    <Heading title="Dropdown" />
                    <SubHeading
                        className="my-3"
                        subheading="Working example"
                    />
                    <Dropdown
                        items={[
                            {
                                title: "Item 1",
                                id: "1",
                                icon: <CircleCheckIcon size={16} />,
                            },
                            { title: "Item 2", id: "2" },
                            { title: "Item 3", id: "3" },
                        ]}
                        activeId={dropDownId}
                        handleChange={id => {
                            setDropdownId(id);
                        }}
                        title="Dropdown example"
                    />
                </Container>
                <Container>
                    <Heading title="Switch Toggle" />
                    <SubHeading
                        className="my-3"
                        subheading="Working example"
                    />
                    <SwitchToggle
                        enabled={switchEnabled}
                        onToggle={e => setSwitchEnabled(e)}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Enabled"
                    />
                    <SwitchToggle
                        enabled={true}
                        onToggle={_e => false}
                    />
                    <SubHeading
                        className="my-3"
                        subheading="Not enabled"
                    />
                    <SwitchToggle
                        enabled={false}
                        onToggle={_e => false}
                    />
                </Container>
            </div>
        </div>
    );
};

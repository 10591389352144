import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { CircleAlert, TriangleAlert, XIcon } from "lucide-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { NotificationDescription } from "./NotificationDescription";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch } from "@/hooks/types";
import { removeNotification } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import {
    NotificationDetails,
    NotificationMessageType,
    NotificationType,
} from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from "@/types/tracking";
import { getIsResumeBuilderPage } from "@/utils/routes";
import { trackClarityError, trackGAEvent } from "@/utils/tracking";

const timeToDelete = {
    SUCCESS: 5000,
    WARNING: 7500,
    ERROR: 7500,
};

interface NotificationProps {
    show: boolean;
    notification: NotificationDetails;
}

export const Notification = ({ show, notification }: NotificationProps) => {
    const [shouldShow, setShouldShow] = useState(show);
    const isResumeBuilderPage = getIsResumeBuilderPage();
    const dispatch = useAppDispatch();

    const handleRemove = useCallback(() => {
        dispatch(removeNotification(notification));
    }, [dispatch, notification]);

    const addNewSection = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.ADD_NEW_SECTION }));
        handleRemove();
    }, [handleRemove, dispatch]);

    const trackNotification = useCallback(() => {
        if (
            notification.type === NotificationType.ERROR ||
            notification.type === NotificationType.WARNING
        ) {
            trackGAEvent(
                GA_EVENT_CATEGORIES.NOTIFICATION,
                GA_EVENT_ACTIONS.ERROR,
                notification.messageType,
            );
            trackClarityError(notification.messageType);
        }
    }, [notification.messageType, notification.type]);

    useEffect(() => {
        setShouldShow(show);
    }, [show]);

    useEffect(() => {
        if (shouldShow) {
            trackNotification();
            const timeoutId = setTimeout(
                handleRemove,
                timeToDelete[notification.type],
            );

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [shouldShow, handleRemove, notification, trackNotification]);

    return (
        <>
            <div
                aria-live="assertive"
                className={clsx(
                    "pointer-events-none fixed inset-0 z-[100000] flex items-end px-4 py-6 sm:items-start sm:p-6",
                    isResumeBuilderPage && "mt-24",
                )}
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={shouldShow}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className={clsx(
                                "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-2",
                                notification.type ===
                                    NotificationType.SUCCESS &&
                                    "ring-success-500",
                                notification.type === NotificationType.ERROR &&
                                    "ring-error-500",
                                notification.type ===
                                    NotificationType.WARNING &&
                                    "ring-alert-300",
                            )}
                        >
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {notification.type ===
                                            NotificationType.SUCCESS && (
                                            <CheckCircleIcon
                                                className="h-6 w-6 text-success-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {notification.type ===
                                            NotificationType.ERROR && (
                                            <CircleAlert
                                                className="h-6 w-6 text-error-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {notification.type ===
                                            NotificationType.WARNING && (
                                            <TriangleAlert
                                                className="h-6 w-6 text-alert-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <AppText variant="contextheading">
                                            {notification.title}
                                        </AppText>
                                        {notification.desc && (
                                            <NotificationDescription
                                                description={notification.desc}
                                            />
                                        )}
                                        {notification.messageType ===
                                            NotificationMessageType.SECTION_HEIGHT_OVERLOWING && (
                                            <Button
                                                variant={"outline"}
                                                color={"grey"}
                                                className="mt-2"
                                                onClick={addNewSection}
                                            >
                                                Add Section
                                            </Button>
                                        )}
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-lg bg-white text-neutral-900 focus:outline-none"
                                            onClick={() => {
                                                setShouldShow(false);
                                            }}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XIcon
                                                size={20}
                                                aria-hidden="true"
                                                onClick={handleRemove}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
};

import clsx from "clsx";
import { CircleHelp } from "lucide-react";
import { useCallback, useMemo } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { getUserFeatureByActionCode } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";

export const HelpButton = () => {
    const dispatch = useAppDispatch();
    const isSurveyAvailable = useAppSelector(state =>
        getUserFeatureByActionCode(state, CreditActionCode.APP_FEEDBACK_SURVEY),
    );

    const onOpenHelpGuideModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.HELP_GUIDE }));
    }, [dispatch]);

    const position = useMemo(() => {
        if (isSurveyAvailable) {
            return "bottom-[58px] right-4";
        }
        return "bottom-4 right-4";
    }, [isSurveyAvailable]);

    return (
        <div className={clsx("group fixed", position)}>
            <IconButton
                variant={"solid"}
                color={"primary"}
                onClick={onOpenHelpGuideModal}
                size="36px"
                className={BuilderTourStep.HELP_GUIDE}
            >
                <CircleHelp size={20} />
            </IconButton>
            <Tooltip
                text="Open Help Guide"
                positionClassName="-translate-x-[90%] -translate-y-[110%]"
            />
        </div>
    );
};

import { loadStripe } from "@stripe/stripe-js/pure";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { PlanFeature } from "@/components/Plans/PlanFeature";
import { AppText } from "@/components/Text/AppText";
import { translate } from "@/helper/translate";
import { useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { StripeItem } from "@/types/billing";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { formatCapitalisedTitle } from "@/utils/string";
import { trackClarityEvent, trackMetaEvent } from "@/utils/tracking";

const getDescription = (product: StripeItem) => {
    if (product.interval === "month") {
        return `Unlock unlimited access for ongoing resume building and ${translate("customisation")}.`;
    }
    return "Credit pack";
};

export const Plans = ({
    activePlan = "free",
    isProfile = false,
    source = "",
}: {
    activePlan?: string;
    isProfile?: boolean;
    source?: "" | "upgrade" | "download" | "preview";
}) => {
    const [loading, setLoading] = useState(false);
    const allStripeItems = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const [error, setError] = useState<string | null>(null);

    const { getStripeProducts, createStripeCheckoutSession } = useStripe();

    const stripeProducts = useMemo(
        () => allStripeItems?.filter(product => !product.isSubscription),
        [allStripeItems],
    );
    const stripeSubscriptions = useMemo(
        () => allStripeItems?.filter(product => product.isSubscription),
        [allStripeItems],
    );

    const onPlanSelect = useCallback(
        async (priceId: string) => {
            setError(null);
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            );
            const redirectUrl = isProfile
                ? `${window.location.origin}${window.location.pathname}?tab=credits`
                : `${window.location.origin}${window.location.pathname}?source=${source}`;
            try {
                setLoading(true);
                const sessionId = await createStripeCheckoutSession(
                    priceId,
                    redirectUrl,
                );

                trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);

                trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                    content_category: "Plan selection",
                    content_name: "Select plan",
                    num_items: 1,
                    content_type: "product",
                    content_ids: [priceId],
                });

                const res = await stripe?.redirectToCheckout({
                    sessionId,
                });

                if (res?.error) {
                    console.error(
                        "Error initiating Stripe checkout:",
                        res.error,
                    );
                }
            } catch (error) {
                console.error("Error initiating Stripe checkout:", error);
                setError(error as string);
            } finally {
                setLoading(false);
            }
        },
        [isProfile, source, createStripeCheckoutSession],
    );

    useEffect(() => {
        if (allStripeItems === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, allStripeItems, loadingProducts, errorProducts]);

    if (errorProducts || error) {
        return (
            <div className="flex h-[400px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    const allPlans = [
        ...(stripeProducts || []),
        ...(stripeSubscriptions || []),
    ];

    return (
        <div
            className={clsx(
                "mb:mt-0 grid gap-0 gap-y-4 md:gap-4",
                loading || loadingProducts
                    ? "grid-cols-1"
                    : "grid-cols-1 md:grid-cols-4",
            )}
        >
            {loading || loadingProducts ? (
                <div className="flex min-h-[450px] items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                allPlans &&
                allPlans.map(product => {
                    const productDetails = product;
                    const isPopular = productDetails.metadata.popular;
                    const isClickable = productDetails.id !== activePlan;

                    return (
                        <div
                            key={productDetails.id}
                            className={clsx(
                                "overflow-hidden rounded-xl rounded-b-xl px-2 pb-2",
                                productDetails.id !== "free" &&
                                    isClickable &&
                                    "cursor-pointer",
                                !isProfile &&
                                    "transition-transform duration-300 ease-in-out",
                                isPopular
                                    ? "bg-primary-700"
                                    : "self-end bg-neutral-100",
                            )}
                            onClick={() =>
                                isClickable &&
                                onPlanSelect(productDetails.priceId)
                            }
                        >
                            {isPopular && (
                                <div className="flex h-12 items-center justify-center rounded-t-xl bg-primary-700 group-hover:border-primary-500">
                                    {isPopular && (
                                        <AppText
                                            variant="contextheading"
                                            className="text-neutral-50"
                                        >
                                            MOST POPULAR
                                        </AppText>
                                    )}
                                </div>
                            )}
                            <div
                                className={clsx(
                                    activePlan === productDetails.id &&
                                        productDetails.id !== "free"
                                        ? "border-primary-700 bg-primary-50"
                                        : "border-neutral-300",
                                    productDetails.id !== "free" &&
                                        "group-hover:border-primary-500",
                                    "col-span-1 overflow-hidden rounded-xl p-4 px-5",
                                    isPopular && "relative shadow-lg",
                                    isPopular
                                        ? "bg-neutral-50"
                                        : "bg-neutral-100",
                                )}
                            >
                                <div
                                    className={clsx(
                                        "mb-2 flex w-fit gap-2 py-1 text-neutral-900",
                                        isProfile
                                            ? "flex-col "
                                            : "flex-row items-end",
                                    )}
                                >
                                    <AppText variant="subheadings">
                                        {`${productDetails.name.toUpperCase()}`}
                                    </AppText>
                                </div>
                                <AppText
                                    variant="regular"
                                    className="text-neutral-900"
                                >
                                    {getDescription(product)}
                                </AppText>
                                <div className="my-4 flex items-center gap-x-1">
                                    <AppText
                                        variant="headings"
                                        className="text-neutral-900"
                                    >
                                        {productDetails.price > 0 &&
                                            productDetails.currencySymbol}
                                        {productDetails.price}
                                    </AppText>
                                    {productDetails.price > 0 &&
                                        productDetails.interval && (
                                            <AppText
                                                variant="labelsbuttons"
                                                className="leading-5 text-neutral-900"
                                            >
                                                {" "}
                                                (
                                                {formatCapitalisedTitle(
                                                    productDetails.interval,
                                                )}
                                                )
                                            </AppText>
                                        )}
                                </div>
                                <div
                                    className={clsx(
                                        "h-1 w-full border-b-2 border-dashed",
                                        isPopular
                                            ? "border-primary-700"
                                            : "border-neutral-300",
                                    )}
                                />
                                <ul
                                    role="list"
                                    className="mb-6 mt-6 space-y-2"
                                >
                                    {productDetails.interval === "month" && (
                                        <>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Complete app access
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited edits
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited <b>downloads</b>
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited resumes
                                                </AppText>
                                            </PlanFeature>
                                        </>
                                    )}
                                </ul>
                                {activePlan &&
                                activePlan === productDetails.id ? (
                                    <Button
                                        color={
                                            activePlan === "free"
                                                ? "softIndigo"
                                                : "softIndigo"
                                        }
                                        variant={
                                            activePlan === "free"
                                                ? "outline"
                                                : "solid"
                                        }
                                        className="pointer-events-none w-full cursor-not-allowed"
                                    >
                                        Active
                                    </Button>
                                ) : activePlan && activePlan !== "free" ? (
                                    <Button
                                        color={"softIndigo"}
                                        variant={"solid"}
                                        className="w-full"
                                        onClick={() =>
                                            onPlanSelect(productDetails.priceId)
                                        }
                                    >
                                        Switch to this Plan
                                    </Button>
                                ) : (
                                    <Button
                                        color={isPopular ? "primary" : "white"}
                                        className={clsx(
                                            "w-full",
                                            !isPopular &&
                                                "hover:bg-neutral-50/80",
                                        )}
                                        onClick={() =>
                                            onPlanSelect(productDetails.priceId)
                                        }
                                    >
                                        Select Plan
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};

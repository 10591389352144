import { loadStripe } from "@stripe/stripe-js/pure";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { pluralise } from "@/utils/string";
import { trackClarityEvent, trackMetaEvent } from "@/utils/tracking";

interface CreditPackButtonsProps {
    sourceModal?: ModalTypes;
    title?: "Buy More Credits: ";
    buttonPrefix?: string;
}

export const CreditPackButtons = ({
    sourceModal,
    title,
    buttonPrefix,
}: CreditPackButtonsProps) => {
    const [loading, setLoading] = useState(false);
    const allStripeItems = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const [error, setError] = useState<string | null>(null);
    const { getCreditActionsByType } = useTransaction();
    const purchaseCreditActions = useMemo(
        () => getCreditActionsByType("PURCHASE"),
        [getCreditActionsByType],
    );
    const { getStripeProducts, createStripeCheckoutSession } = useStripe();

    const stripeProducts = useMemo(
        () => allStripeItems?.filter(product => !product.isSubscription),
        [allStripeItems],
    );

    const onPackSelect = useCallback(
        async (priceId: string) => {
            setError(null);
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            );
            const redirectUrl = `${window.location.origin}${window.location.pathname}?source=${sourceModal}`;
            try {
                setLoading(true);
                const sessionId = await createStripeCheckoutSession(
                    priceId,
                    redirectUrl,
                );

                trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);

                trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                    content_category: "Pack selection",
                    content_name: "Select pack",
                    num_items: 1,
                    content_type: "product",
                    content_ids: [priceId],
                });

                const res = await stripe?.redirectToCheckout({
                    sessionId,
                });

                if (res?.error) {
                    console.error(
                        "Error initiating Stripe checkout:",
                        res.error,
                    );
                }
            } catch (error) {
                console.error("Error initiating Stripe checkout:", error);
                setError(error as string);
            } finally {
                setLoading(false);
            }
        },
        [sourceModal, createStripeCheckoutSession],
    );

    useEffect(() => {
        if (allStripeItems === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, allStripeItems, loadingProducts, errorProducts]);

    if (errorProducts || error) {
        return (
            <div className="flex h-[230px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    return (
        <div className="flex w-full flex-col gap-2">
            {title && (
                <AppText
                    variant="labelsbuttons"
                    className="text-left text-neutral-900"
                >
                    {title}
                </AppText>
            )}
            <div
                className={clsx(
                    "no-scrollbar flex gap-3 overflow-x-scroll",
                    loading || loadingProducts ? "flex-col" : "flex-row ",
                )}
            >
                {loading || loadingProducts ? (
                    <div className="flex min-h-[66px] items-center justify-center">
                        <LoadingSpinner />
                    </div>
                ) : (
                    stripeProducts &&
                    stripeProducts.map(product => {
                        const productDetails = product;
                        const creditAction = purchaseCreditActions.find(
                            action =>
                                action.metadata?.stripePriceId ===
                                productDetails.priceId,
                        );

                        if (!creditAction) {
                            return null;
                        }
                        const credits = creditAction.credits ?? 0;

                        return (
                            <Button
                                key={productDetails.id}
                                onClick={() =>
                                    onPackSelect(productDetails.priceId)
                                }
                                className="flex !h-full flex-col"
                            >
                                <AppText
                                    variant="labelsbuttons"
                                    className="mb-1 block text-neutral-50"
                                >
                                    {buttonPrefix}
                                </AppText>
                                <AppText
                                    variant="labelsbuttons"
                                    className="mb-2 block text-neutral-50"
                                >
                                    {credits}{" "}
                                    {pluralise(credits, "Credit", "Credits")}
                                </AppText>
                                <AppText
                                    variant="labelsbuttons"
                                    className="block text-neutral-50"
                                >
                                    {productDetails.price > 0 &&
                                        productDetails.currencySymbol}
                                    {productDetails.price}
                                </AppText>
                            </Button>
                        );
                    })
                )}
            </div>
        </div>
    );
};

import { InputLabel } from "@/components/form/InputLabel";

interface ReadOnlyFieldProps {
    label: string;
    value?: string | number | null;
    description?: string;
}

export const ReadOnlyField = ({
    label,
    value,
    description,
}: ReadOnlyFieldProps) => (
    <div className="px-1 py-1">
        <InputLabel
            label={label}
            description={description}
        />
        <div className="rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-sm text-gray-600">
            {value || "—"}
        </div>
    </div>
);

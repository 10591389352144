import { Text } from "@react-email/components";

export const EmailText = ({
    children,
    noMargin,
    additionalStyle,
}: {
    children: React.ReactNode;
    noMargin?: boolean;
    additionalStyle?: React.CSSProperties;
}) => (
    <Text
        style={{
            fontSize: "18px",
            color: "#303330",
            fontWeight: 500,
            lineHeight: 1.2,
            margin: noMargin ? "0" : "0 0 12px",
            ...additionalStyle,
        }}
    >
        {children}
    </Text>
);

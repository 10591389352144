export enum ModalTypes {
  ADMIN_LAYOUT_FIELD_OPTIONS = "ADMIN_LAYOUT_FIELD_OPTIONS",
  ADD_LINK = "ADD_LINK",
  ADD_NEW_SECTION = "ADD_NEW_SECTION",
  ADMIN_SECTION_PREVIEW = "ADMIN_SECTION_PREVIEW",
  ASSISTANT_JOB_ROLE = "ASSISTANT_JOB_ROLE",
  ASSISTANT_PREFERENCES = "ASSISTANT_PREFERENCES",
  BUY_CREDITS = "BUY_CREDITS",
  CREDITS_INFO = "CREDITS_INFO",
  CREDITS_SUCCESS = "CREDITS_SUCCESS",
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  DELETE_RESUME = "DELETE_RESUME",
  DOWNLOAD_RESUME = "DOWNLOAD_RESUME",
  EDIT_IMAGE = "EDIT_IMAGE",
  HELP_GUIDE = "HELP_GUIDE",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  DOCUMENT_LIMIT = "DOCUMENT_LIMIT",
  PLANS = "PLANS",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  REARRANGE = "REARRANGE",
  RENAME_RESUME = "RENAME_RESUME",
  RESUME_ADMIN = "RESUME_ADMIN",
  RESUME_PREVIEW = "RESUME_PREVIEW",
  TOUR = "TOUR",
  VERIFY_EMAIL = "VERIFY_EMAIL",
}
import { Formik } from "formik";
import { InfoIcon } from "lucide-react";
import { useState } from "react";
import * as Yup from "yup";
import creditTransactionApi, {
    CreateTransactionRequest,
} from "@/api/creditTransaction";
import { Button } from "@/components/Button/Button";
import { Input } from "@/components/form/Input";
import { InputError } from "@/components/form/InputError";
import { InputLabel } from "@/components/form/InputLabel";
import { Select } from "@/components/form/Select";
import { TextArea } from "@/components/form/Textarea";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { InlineCodeEditor } from "@/pages/Admin/InlineCodeEditor/InlineCodeEditor";
import { ReadOnlyField } from "@/pages/Admin/ReadOnlyField";
import { addNotification } from "@/store/app/slice";
import { getUserDetails } from "@/store/user/selectors";
import { NotificationMessageType, NotificationType } from "@/types";
import { CREDIT_ACTION_TYPES, ICreditAction } from "@/types/creditAction";

interface CreditTransactionFormProps {
    userId: string;
    onSuccess?: () => void;
    userEmail?: string;
    creditActions: Array<ICreditAction>;
}

const transactionTypes = CREDIT_ACTION_TYPES.map(type => ({
    id: type,
    name: type,
}));

export const CreditTransactionForm = ({
    userId,
    onSuccess,
    userEmail,
    creditActions,
}: CreditTransactionFormProps) => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(getUserDetails);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [pendingValues, setPendingValues] =
        useState<CreateTransactionRequest | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVariableAmount, setIsVariableAmount] = useState(false);

    const validationSchema = Yup.object().shape({
        type: Yup.string()
            .oneOf(CREDIT_ACTION_TYPES, "Invalid transaction type")
            .required("Transaction type is required"),
        actionCode: Yup.string().required("Action code is required"),
        amount: Yup.number()
            .required("Amount is required")
            .integer("Amount must be a whole number")
            .test("non-zero", "Amount cannot be zero", value => value !== 0),
        description: Yup.string()
            .required("Description is required")
            .min(3, "Description must be at least 3 characters")
            .max(200, "Description must be less than 200 characters"),
        metadata: Yup.mixed().required("Metadata is required"),
        createdBy: Yup.string().required("Created by is required"),
    });

    const handleSubmit = async (values: CreateTransactionRequest) => {
        try {
            const formattedValues = {
                ...values,
                metadata:
                    typeof values.metadata === "string"
                        ? JSON.parse(values.metadata)
                        : values.metadata,
                createdBy: currentUser?.id || "",
            };

            setPendingValues(formattedValues);
            setShowConfirmation(true);
        } catch (error) {
            dispatch(
                addNotification({
                    title: "Error",
                    messageType:
                        NotificationMessageType.ADMIN_CREDIT_TRANSACTION_SAVE,
                    type: NotificationType.ERROR,
                }),
            );
        }
    };

    const handleConfirm = async () => {
        if (!pendingValues) return;
        setIsSubmitting(true);
        try {
            await creditTransactionApi.createTransaction(pendingValues);

            dispatch(
                addNotification({
                    title: "Success",
                    desc: "Credit transaction created successfully",
                    messageType:
                        NotificationMessageType.ADMIN_CREDIT_TRANSACTION_SAVE,
                    type: NotificationType.SUCCESS,
                }),
            );

            setShowConfirmation(false);
            setPendingValues(null);
            onSuccess?.();
        } catch (error) {
            dispatch(
                addNotification({
                    title: "Error",
                    desc: "Failed to create credit transaction",
                    messageType:
                        NotificationMessageType.ADMIN_CREDIT_TRANSACTION_SAVE,
                    type: NotificationType.ERROR,
                }),
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="rounded-lg border border-gray-200 px-4 py-2">
            <h3 className="mb-4 text-lg font-medium">Create Transaction</h3>

            <div className="mb-6 grid grid-cols-2 gap-4">
                <div className="rounded-lg bg-gray-50 px-4 py-2">
                    <h4 className="mb-1 font-medium text-gray-700">
                        User Details
                    </h4>
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                            <span className="text-sm font-medium text-gray-500">
                                Email:
                            </span>
                            <span className="text-sm">{userEmail}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">
                                ID:
                            </span>
                            <span className="text-sm">{userId}</span>
                        </div>
                    </div>
                </div>

                <div className="bg-warning-50 border-warning-200 rounded-lg border bg-alert-50 px-4 py-2">
                    <div className="flex items-center gap-3">
                        <InfoIcon
                            className="text-warning-500 h-5 w-5 flex-shrink-0"
                            strokeWidth={2}
                        />
                        <div className="flex flex-col gap-1">
                            <span className="text-warning-700 text-sm font-medium">
                                Important Information
                            </span>
                            <span className="text-warning-600 text-sm leading-tight">
                                This form is used to create a credit transaction
                                for a user. The transaction will be created
                                immediately and the user&apos;s balance will be
                                updated.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={{
                    userId,
                    type: "ADMIN",
                    actionCode: "",
                    amount: 0,
                    description: "",
                    metadata: JSON.stringify({}, null, 2),
                    createdBy: currentUser?.id || "",
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <InputLabel
                                    label="Transaction Type"
                                    htmlFor="type"
                                    required
                                    description="Add or remove credits"
                                />
                                <Select
                                    value={transactionTypes.find(
                                        item => item.id === values.type,
                                    )}
                                    items={transactionTypes}
                                    onChange={item =>
                                        setFieldValue("type", item.id)
                                    }
                                />
                                <InputError error={errors.type} />
                            </div>

                            <div>
                                <InputLabel
                                    label="Action Code"
                                    htmlFor="actionCode"
                                    required
                                    description="Reason for transaction"
                                />
                                <Select
                                    value={creditActions.find(
                                        item => item.code === values.actionCode,
                                    )}
                                    items={creditActions.map(action => ({
                                        id: action.code,
                                        name: action.code,
                                    }))}
                                    onChange={item => {
                                        setFieldValue("actionCode", item.id);
                                        const action = creditActions.find(
                                            action => action.code === item.id,
                                        );
                                        if (action) {
                                            setIsVariableAmount(
                                                action.amountType ===
                                                    "VARIABLE",
                                            );
                                            if (action.amountType === "FIXED") {
                                                setFieldValue(
                                                    "amount",
                                                    action.credits ?? 0,
                                                );
                                            }
                                        }
                                    }}
                                />
                                <InputError error={errors.actionCode} />
                            </div>
                            {isVariableAmount ? (
                                <div>
                                    <InputLabel
                                        label="Amount"
                                        htmlFor="amount"
                                        required
                                        description="Enter custom amount (positive adds credits, negative removes)"
                                    />
                                    <Input
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        value={values.amount}
                                        formikChange={handleChange}
                                        error={errors.amount}
                                        onWheel={e => e.currentTarget.blur()}
                                    />
                                </div>
                            ) : (
                                <div />
                            )}
                            <div>
                                <ReadOnlyField
                                    label="Created By"
                                    value={currentUser?.id}
                                    description="This is your ID. It is automatically filled in and required for auditing purposes."
                                />
                            </div>
                            <div className="col-span-1">
                                <InputLabel
                                    label="Description"
                                    htmlFor="description"
                                    required
                                    description="Detailed reason for the transaction"
                                />
                                <TextArea
                                    name="description"
                                    id="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                                <InputError error={errors.description} />
                            </div>
                            <div className="col-span-1">
                                <InputLabel
                                    label="Metadata"
                                    htmlFor="metadata"
                                    required
                                    description="Metadata for the transaction in JSON format."
                                />
                                <InlineCodeEditor
                                    language="json"
                                    value={
                                        typeof values.metadata === "string"
                                            ? values.metadata
                                            : JSON.stringify(
                                                  values.metadata,
                                                  null,
                                                  2,
                                              )
                                    }
                                    onChange={value =>
                                        setFieldValue("metadata", value)
                                    }
                                />
                                <InputError error={errors.metadata} />
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <Button type="submit">Create Transaction</Button>
                        </div>
                    </form>
                )}
            </Formik>
            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="max-w-md rounded-lg bg-white p-6">
                        <h3 className="mb-4 text-lg font-medium">
                            Confirm Transaction
                        </h3>
                        <p className="mb-4">
                            Are you sure you want to create this credit
                            transaction?
                        </p>
                        <div className="flex justify-end space-x-4">
                            <Button
                                variant={"solid"}
                                color={"white"}
                                onClick={() => setShowConfirmation(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color={"red"}
                                onClick={handleConfirm}
                                loading={isSubmitting}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

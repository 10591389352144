import { useState, useEffect } from "react";
import { Button } from "@/components/Button/Button";
import { Select } from "@/components/form/Select";
import { AssistantItemStatus } from "@/types/assistant";

interface BulkActionsProps {
    selectedCount: number;
    showStatusUpdate?: boolean;
    onStatusUpdate?: (status: AssistantItemStatus) => void;
    onDelete: () => void;
}

export const BulkActions = ({
    selectedCount,
    onStatusUpdate,
    onDelete,
    showStatusUpdate,
}: BulkActionsProps) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [bulkStatus, setBulkStatus] = useState<AssistantItemStatus>(
        AssistantItemStatus.DRAFT,
    );

    useEffect(() => {
        setConfirmDelete(false);
    }, [selectedCount]);

    if (selectedCount === 0) return null;

    return (
        <div className="z-20 flex items-center space-x-2">
            {showStatusUpdate && (
                <div className="flex items-center space-x-2">
                    <div className="w-40">
                        <Select
                            value={
                                bulkStatus
                                    ? {
                                          id: bulkStatus,
                                          name: bulkStatus,
                                      }
                                    : {
                                          id: "select",
                                          name: "Select status",
                                      }
                            }
                            onChange={item =>
                                setBulkStatus(item.name as AssistantItemStatus)
                            }
                            items={Object.values(AssistantItemStatus).map(
                                status => ({
                                    id: status,
                                    name: status,
                                }),
                            )}
                        />
                    </div>
                    <Button
                        onClick={() =>
                            onStatusUpdate && onStatusUpdate(bulkStatus)
                        }
                        disabled={!bulkStatus}
                    >
                        Update Status
                    </Button>
                </div>
            )}
            <Button
                variant={confirmDelete ? "solid" : "outline"}
                color={confirmDelete ? "red" : "softIndigo"}
                onClick={
                    confirmDelete ? onDelete : () => setConfirmDelete(true)
                }
            >
                {confirmDelete ? "Confirm Delete" : "Delete"}
            </Button>
        </div>
    );
};

import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Button as HeadlessButton,
} from "@headlessui/react";
import { BubbleMenu, Editor } from "@tiptap/react";
import clsx from "clsx";
import {
    ALargeSmall,
    Link2,
    Minimize2,
    Repeat,
    SpellCheck,
} from "lucide-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { CreditsIcon, SmartScribe } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { TransactionContext } from "@/context/TransactionContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getAiToolboxState } from "@/store/app/selectors";
import { resetAiToolbox, showAiToolbox } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { TransactionContextType } from "@/types/creditTransaction";
import { ModalTypes } from "@/types/modal";
import { formatCreditAmount } from "@/utils/string";

interface EditorBubbleMenuProps {
    editor: Editor | null;
}

export const EditorBubbleMenu = ({ editor }: EditorBubbleMenuProps) => {
    const dispatch = useAppDispatch();
    const {
        validateCreditAction,
        getCanAffordCreditAction,
        getCreditActionByCode,
    } = useContext(TransactionContext) as TransactionContextType;
    const [canShowMenu, setCanShowMenu] = useState(true);
    const { isVisible } = useAppSelector(getAiToolboxState);
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const aiPromptAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.AI_PROMPT_REWRITE),
        [getCreditActionByCode],
    );
    const canAffordAiWritingPrompt = getCanAffordCreditAction(
        CreditActionCode.AI_PROMPT_REWRITE,
    );
    const [isLinkActive, setIsLinkActive] = useState(false);
    useEffect(() => {
        if (isVisible) {
            setCanShowMenu(true);
        }
    }, [isVisible]);

    const onUseCreditClick = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.BUY_CREDITS }));
    }, [dispatch]);

    const openLinkModal = useCallback(() => {
        let url = "";
        if (editor) {
            url = editor.getAttributes("link").href;
        }

        dispatch(
            openModal({
                modalType: ModalTypes.ADD_LINK,
                props: { url },
            }),
        );
        setCanShowMenu(false);
    }, [dispatch, editor]);

    const onPromptClick = useCallback(
        async (promptCode: CreditActionCode) => {
            if (!editor) return;
            const { creditActionError, hasInsufficientFunds } =
                await validateCreditAction({
                    actionCode: promptCode,
                });

            if (hasInsufficientFunds || creditActionError) {
                return;
            }

            const selectionTo = editor.state.selection.to;
            const selectionFrom = editor.state.selection.from;
            const selectedText = editor.view.state.doc.textBetween(
                selectionFrom,
                selectionTo,
            );
            setCanShowMenu(false);

            dispatch(
                showAiToolbox({
                    promptValue: selectedText,
                    promptActionCode: promptCode,
                    selection: {
                        from: selectionFrom,
                        to: selectionTo,
                    },
                }),
            );
        },
        [editor, validateCreditAction, dispatch],
    );

    useEffect(() => {
        const handleSelectionChange = () => {
            if (isVisible) {
                dispatch(resetAiToolbox());
            }
            setCanShowMenu(true);
            const url = editor?.getAttributes("link").href;
            setIsLinkActive(!!url);
        };

        editor?.on("selectionUpdate", handleSelectionChange);

        return () => {
            editor?.off("selectionUpdate", handleSelectionChange);
        };
    }, [editor, dispatch, isVisible]);

    return (
        <div id="editor-bubble-menu">
            {editor && (
                <BubbleMenu
                    editor={editor}
                    tippyOptions={{
                        placement: "bottom",
                        animation: "fade",
                        onHide: () => {
                            setCanShowMenu(true);
                        },
                        zIndex: 333,
                    }}
                    shouldShow={({ editor }) => {
                        const shouldShow =
                            editor.isEditable &&
                            canShowMenu &&
                            !editor.state.selection.empty;
                        return shouldShow;
                    }}
                    className="flex items-center gap-3 rounded-lg bg-white p-1 font-body shadow-md"
                >
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <PopoverButton
                                    as="div"
                                    className="outline-none"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-label="AI writing prompts"
                                >
                                    <Button
                                        color="gradient"
                                        leftIcon={
                                            <SmartScribe
                                                className="h-5 w-5"
                                                gradient={!open}
                                            />
                                        }
                                        variant={open ? "solid" : "outline"}
                                        size="small"
                                    >
                                        Smart Scribe
                                    </Button>
                                </PopoverButton>
                                <PopoverPanel
                                    className="absolute left-1/2 z-50 mt-2 flex w-52 -translate-x-1/2 flex-col rounded-lg bg-white shadow-md ring-2 ring-neutral-100"
                                    modal
                                >
                                    <HeadlessButton
                                        onClick={() =>
                                            onPromptClick(
                                                CreditActionCode.AI_PROMPT_REWRITE,
                                            )
                                        }
                                        disabled={!canAffordAiWritingPrompt}
                                        className="flex gap-3 border-b border-neutral-100 px-3 py-2 text-left text-neutral-900 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                    >
                                        <Repeat className="h-4 w-4" />
                                        <AppText variant="labelsbuttons">
                                            Rewrite
                                        </AppText>
                                    </HeadlessButton>
                                    <HeadlessButton
                                        onClick={() =>
                                            onPromptClick(
                                                CreditActionCode.AI_PROMPT_SHORTEN,
                                            )
                                        }
                                        disabled={!canAffordAiWritingPrompt}
                                        className="flex gap-3 border-b border-neutral-100 px-3 py-2 text-left text-neutral-900 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                    >
                                        <Minimize2 className="h-4 w-4" />
                                        <AppText variant="labelsbuttons">
                                            Shorten
                                        </AppText>
                                    </HeadlessButton>
                                    <HeadlessButton
                                        onClick={() =>
                                            onPromptClick(
                                                CreditActionCode.AI_PROMPT_FORMAL,
                                            )
                                        }
                                        className="flex gap-3 border-b border-neutral-100 px-3 py-2 text-left text-neutral-900 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                        disabled={!canAffordAiWritingPrompt}
                                    >
                                        <ALargeSmall className="h-4 w-4" />
                                        <AppText variant="labelsbuttons">
                                            More formal
                                        </AppText>
                                    </HeadlessButton>
                                    <HeadlessButton
                                        onClick={() =>
                                            onPromptClick(
                                                CreditActionCode.AI_PROMPT_GRAMMAR,
                                            )
                                        }
                                        disabled={!canAffordAiWritingPrompt}
                                        className="flex gap-3 border-b-2 border-neutral-300 px-3 py-2 text-left text-neutral-900 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                    >
                                        <SpellCheck className="h-4 w-4" />
                                        <AppText variant="labelsbuttons">
                                            Fix grammar
                                        </AppText>
                                    </HeadlessButton>
                                    <HeadlessButton
                                        onClick={onUseCreditClick}
                                        className="flex items-center gap-3 px-3 py-2 hover:bg-neutral-100/50 disabled:pointer-events-none disabled:opacity-25"
                                    >
                                        {canAffordAiWritingPrompt ? (
                                            <div className="flex items-center gap-3">
                                                <CreditsIcon className="h-4 w-4" />
                                                <AppText
                                                    variant="regular"
                                                    applyGradient
                                                    className="mx-auto !font-medium"
                                                >
                                                    Use{" "}
                                                    {Math.abs(
                                                        aiPromptAction?.credits ??
                                                            0,
                                                    )}{" "}
                                                    of{" "}
                                                    {formatCreditAmount(
                                                        userCreditBalance,
                                                    )}
                                                </AppText>
                                            </div>
                                        ) : (
                                            <div className="mx-auto flex items-center gap-3">
                                                <AppText
                                                    variant="labelsbuttons"
                                                    applyGradient
                                                >
                                                    {formatCreditAmount(
                                                        userCreditBalance,
                                                    )}
                                                </AppText>
                                                <Button
                                                    color="gradient"
                                                    variant="solid"
                                                    size="small"
                                                >
                                                    Add More
                                                </Button>
                                            </div>
                                        )}
                                    </HeadlessButton>
                                </PopoverPanel>
                            </>
                        )}
                    </Popover>
                    <IconButton
                        size="28px"
                        color="white"
                        onClick={openLinkModal}
                        className={clsx("", {
                            "!bg-neutral-200": isLinkActive,
                        })}
                    >
                        <Link2 className="h-4 w-4" />
                        {/* <Tooltip
                        text={`Insert link (${getButtonCommand("link", isMac)})`}
                        size="small"
                        className="min-w-28"
                        positionClassName="-translate-x-1/2 translate-y-12"
                        showArrow
                    /> */}
                    </IconButton>
                </BubbleMenu>
            )}
        </div>
    );
};

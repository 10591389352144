import { Trash2 } from "lucide-react";
import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import creditActionApi from "@/api/creditAction";
import { Checkbox } from "@/components/form/Checkbox";
import { useAppDispatch } from "@/hooks/types";
import { AdminListHeader } from "@/pages/Admin/AdminListHeader";
import { BulkActions } from "@/pages/Admin/components/AdminBulkActions";
import { LoadingOverlay } from "@/pages/Admin/components/AdminLoadingOverlay";
import { AdminNoResults } from "@/pages/Admin/components/AdminNoResults";
import { Pagination } from "@/pages/Admin/components/AdminPagination";
import { AdminPip } from "@/pages/Admin/components/AdminPip";
import { AdminSearchBar } from "@/pages/Admin/components/AdminSearchBar";
import {
    AdminTable,
    AdminTableHeader,
    AdminTableHeaderCell,
    AdminTableBody,
    AdminTableBodyCell,
    AdminTableBodyRow,
} from "@/pages/Admin/components/Table";
import { handleAdminError } from "@/pages/Admin/utils/handleAdminError";
import { NotificationMessageType } from "@/types";
import {
    CreditActionAmountType,
    CreditActionCategory,
    CreditActionType,
    ICreditAction,
} from "@/types/creditAction";

type SortColumn =
    | "name"
    | "code"
    | "type"
    | "category"
    | "credits"
    | "createdAt"
    | "isActive"
    | "amountType";

const columnNames = [
    { key: "code", label: "Code", width: 180 },
    { key: "name", label: "Name", width: 180 },
    { key: "type", label: "Type", width: 120 },
    { key: "category", label: "Category", width: 120 },
    { key: "credits", label: "Credits", width: 100 },
    { key: "amountType", label: "Amount Type", width: 100 },
    { key: "isActive", label: "Active?", width: 100 },
    { key: "createdAt", label: "Created", width: 180 },
    { key: "actions", label: "Actions", width: 80 },
];

const getTypeColour = (type: CreditActionType) => {
    switch (type) {
        case "EARN":
            return "bg-primary-300";
        case "SPEND":
            return "bg-success-300";
        case "REFUND":
            return "bg-error-300";
        case "PURCHASE":
            return "bg-alert-300";
        case "ADMIN":
            return "bg-neutral-300";
        default:
            return "bg-neutral-500";
    }
};

const getCategoryColour = (category: CreditActionCategory) => {
    switch (category) {
        case "FEATURE":
            return "bg-primary-300";
        default:
            return "bg-neutral-500";
    }
};

const getAmountTypeColour = (amountType: CreditActionAmountType) => {
    switch (amountType) {
        case "FIXED":
            return "bg-primary-300";
        default:
            return "bg-neutral-500";
    }
};

const itemsPerPage = 50;

export const CreditActionsPage = () => {
    const [creditActions, setCreditActions] = useState<ICreditAction[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>("code");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasInitialised = useRef(false);

    const fetchCreditActions = useCallback(
        async (
            page: number,
            sortCol: SortColumn = sortColumn,
            sortDir: "asc" | "desc" = sortDirection,
            search: string = searchTerm,
        ) => {
            setIsLoading(true);
            try {
                const result = await creditActionApi.getCreditActionsApi({
                    page: page,
                    limit: itemsPerPage,
                    sortBy: `${sortCol}:${sortDir}`,
                    search: search || undefined,
                });
                setCreditActions(result.results);
                setTotalItems(result.totalResults);
                setCurrentPage(page);
            } catch (error) {
                handleAdminError(
                    dispatch,
                    error,
                    "Failed to fetch credit actions",
                    NotificationMessageType.ADMIN_CREDIT_ACTION_SAVE,
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, searchTerm, sortColumn, sortDirection],
    );

    const handleSort = useCallback(
        (column: SortColumn) => {
            const newSortDirection =
                sortColumn === column && sortDirection === "asc"
                    ? "desc"
                    : "asc";
            setSortColumn(column);
            setSortDirection(newSortDirection);
            setCurrentPage(1);
            fetchCreditActions(1, column, newSortDirection);
        },
        [sortColumn, sortDirection, fetchCreditActions],
    );

    useEffect(() => {
        if (!hasInitialised.current) {
            hasInitialised.current = true;
            fetchCreditActions(1);
        }
    }, [fetchCreditActions]);

    const handleRowClick = (creditActionId: string) => {
        navigate(`${creditActionId}`);
    };

    const handleDelete = useCallback(
        async (e: React.MouseEvent, creditActionId: string) => {
            e.stopPropagation();
            try {
                await creditActionApi.deleteCreditActionApi(creditActionId);
                setCreditActions(prev =>
                    prev.filter(action => action.id !== creditActionId),
                );
            } catch (err: unknown) {
                handleAdminError(
                    dispatch,
                    err,
                    "Failed to delete credit action",
                    NotificationMessageType.ADMIN_CREDIT_ACTION_DELETE,
                );
            }
        },
        [dispatch],
    );

    const handleSearchChange = useCallback(
        (term: string) => {
            if (searchTerm === term) return;
            setSearchTerm(term);
            setCurrentPage(1);
            fetchCreditActions(1, sortColumn, sortDirection, term);
        },
        [searchTerm, fetchCreditActions, sortColumn, sortDirection],
    );

    const handlePageChange = (page: number) => {
        fetchCreditActions(page);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleSelectItem = (creditActionId: string) => {
        setSelectedItems(prev =>
            prev.includes(creditActionId)
                ? prev.filter(id => id !== creditActionId)
                : [...prev, creditActionId],
        );
    };

    const handleSelectAll = () => {
        setSelectedItems(
            selectedItems.length === creditActions.length
                ? []
                : creditActions.map(action => action.id),
        );
    };

    const handleBulkDelete = useCallback(async () => {
        if (selectedItems.length === 0) return;
        try {
            await Promise.all(
                selectedItems.map(creditActionId =>
                    creditActionApi.deleteCreditActionApi(creditActionId),
                ),
            );
            setSelectedItems([]);
            fetchCreditActions(currentPage);
        } catch (error) {
            handleAdminError(
                dispatch,
                error,
                "Failed to delete credit actions",
                NotificationMessageType.ADMIN_CREDIT_ACTION_DELETE,
            );
        }
    }, [selectedItems, dispatch, fetchCreditActions, currentPage]);

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2">
            <AdminListHeader
                title="Credit Actions"
                description="A list of all credit actions including their code, type, category and credit value."
                buttonText="Add Credit Action"
                to="new"
            />
            <div className="py-4">
                <div className="mb-4 flex items-center justify-between">
                    <div className="ml-3 flex items-center space-x-4">
                        <Checkbox
                            id="select-all"
                            label={`${selectedItems.length} selected`}
                            checked={
                                selectedItems.length === creditActions.length
                            }
                            onChange={handleSelectAll}
                            className="w-36"
                        />
                        <BulkActions
                            selectedCount={selectedItems.length}
                            onDelete={handleBulkDelete}
                        />
                    </div>
                    <div className="flex items-center">
                        <span className="text-sm text-gray-400">
                            {totalItems} credit actions
                        </span>
                    </div>
                    <div className="h-9 w-96">
                        <AdminSearchBar
                            setSearchTerm={handleSearchChange}
                            placeholder="Search by name or code"
                        />
                    </div>
                </div>
                <div className="relative overflow-x-auto">
                    <LoadingOverlay isLoading={isLoading} />
                    <AdminTable>
                        <AdminTableHeader>
                            <th className="w-8 px-6 py-3 text-left text-xs tracking-wider text-gray-500"></th>
                            {columnNames.map(({ key, label, width }) => (
                                <AdminTableHeaderCell
                                    key={key}
                                    label={label}
                                    sortKey={key}
                                    currentSortColumn={sortColumn}
                                    currentSortDirection={sortDirection}
                                    onSort={key =>
                                        handleSort(key as SortColumn)
                                    }
                                    isSortable={
                                        key !== "actions" && key !== "id"
                                    }
                                    width={width}
                                />
                            ))}
                        </AdminTableHeader>
                        <AdminTableBody>
                            {creditActions.map(action => (
                                <AdminTableBodyRow
                                    key={action.id}
                                    onClick={() => handleRowClick(action.id)}
                                >
                                    <AdminTableBodyCell
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Checkbox
                                            checked={selectedItems.includes(
                                                action.id,
                                            )}
                                            onChange={() =>
                                                handleSelectItem(action.id)
                                            }
                                            id={action.id}
                                            label=""
                                            borderless
                                            className="h-5 w-5"
                                        />
                                    </AdminTableBodyCell>
                                    {columnNames.map(({ key, width }) => (
                                        <AdminTableBodyCell
                                            key={key}
                                            width={width}
                                        >
                                            {key === "code" && action.code}
                                            {key === "name" && action.name}
                                            {key === "type" && (
                                                <AdminPip
                                                    colour={getTypeColour(
                                                        action.type,
                                                    )}
                                                >
                                                    {action.type}
                                                </AdminPip>
                                            )}
                                            {key === "category" && (
                                                <AdminPip
                                                    colour={getCategoryColour(
                                                        action.category,
                                                    )}
                                                >
                                                    {action.category}
                                                </AdminPip>
                                            )}
                                            {key === "amountType" && (
                                                <AdminPip
                                                    colour={getAmountTypeColour(
                                                        action.amountType,
                                                    )}
                                                >
                                                    {action.amountType}
                                                </AdminPip>
                                            )}
                                            {key === "credits" &&
                                                action.credits === null &&
                                                "∅"}
                                            {key === "credits" &&
                                                action.credits !== null &&
                                                action.credits}
                                            {key === "isActive" && (
                                                <AdminPip
                                                    colour={
                                                        action.isActive
                                                            ? "bg-success-300"
                                                            : "bg-error-300"
                                                    }
                                                >
                                                    {action.isActive
                                                        ? "Yes"
                                                        : "No"}
                                                </AdminPip>
                                            )}
                                            {key === "createdAt" &&
                                                new Date(
                                                    action.createdAt,
                                                ).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            {key === "actions" && (
                                                <div className="flex gap-4">
                                                    <button
                                                        onClick={e =>
                                                            handleDelete(
                                                                e,
                                                                action.id,
                                                            )
                                                        }
                                                        className="text-error-600 hover:text-error-900"
                                                    >
                                                        <Trash2 className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            )}
                                        </AdminTableBodyCell>
                                    ))}
                                </AdminTableBodyRow>
                            ))}
                        </AdminTableBody>
                    </AdminTable>
                    {creditActions.length === 0 && !isLoading && (
                        <AdminNoResults />
                    )}
                </div>
                <div className="mt-4 flex justify-end">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

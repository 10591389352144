import { BaseAiFeature } from "./BaseAiFeature";
import aiApi from "@/api/ai";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getUserPreferences } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";

export const AiSummaryFeature = ({ sectionType }: { sectionType?: string }) => {
    const userPreferences = useAppSelector(getUserPreferences);
    const dispatch = useAppDispatch();

    const handleGenerate = async (input: string) => {
        const response = await aiApi.generateResumeSummary(
            {
                temperature: 0.7,
                includeUsage: false,
                content: input,
                industries: userPreferences?.industries ?? [],
                jobRoles: userPreferences?.jobRoles ?? [],
                experienceLevel: userPreferences?.experienceLevel ?? "Any",
                sectionType,
                // mockResponse: true,
            },
            dispatch,
        );

        return response.output;
    };

    return (
        <BaseAiFeature
            title="Paragraph Pro"
            creditActionCode={CreditActionCode.AI_GENERATE_SUMMARY}
            onGenerate={handleGenerate}
            sectionType={sectionType}
        />
    );
};

import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { NavLink, useNavigate, createSearchParams } from "react-router-dom";
import { object } from "yup";
import userApi from "@/api/user";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { SSOLogin } from "@/components/SSO/SSOLogin";
import { AppText } from "@/components/Text/AppText";
import { Input } from "@/components/form/Input";
import { emailValidation, passwordValidation } from "@/constants/schema";
import { sendVerifyEmail } from "@/email/send";
import { getErrorMessage } from "@/helper/getErrorMessage";
import { useAppDispatch } from "@/hooks/types";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import {
    setAccessToken,
    setRefreshToken,
    setUserDetails,
    setUserPlan,
} from "@/store/user/slice";
import { setWizardUserPreferences } from "@/store/wizard/slice";
import { User } from "@/types";
import { UserPlan } from "@/types/billing";
import { AppRoutes } from "@/types/routes";
import { Tokens } from "@/types/token";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { UserPreferences } from "@/types/user";
import { isFeatureEnabled } from "@/utils/featureFlags";
import { trackClarityEvent, trackClarityIdentify } from "@/utils/tracking";

const loginSchema = object({
    email: emailValidation,
    password: passwordValidation,
});

interface LoginFormFields {
    email: string;
    password: string;
}

export const LoginPage = () => {
    const initialValues: LoginFormFields = useMemo(
        () => ({
            email: "",
            password: "",
        }),
        [],
    );
    const [, setLocalAccessToken] = useLocalStorage("accessToken", null);
    const [, setLocalRefreshToken] = useLocalStorage("refreshToken", null);
    const [loading, setLoading] = useState(false);
    const isSSOEnabled = useMemo(
        () => isFeatureEnabled("VITE_FEATURE_GOOGLE_SSO"),
        [],
    );

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string | null>("");

    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onLoginSuccess = async (
        user: User,
        tokens: Tokens,
        plan: UserPlan,
        verifyCode?: string,
    ) => {
        dispatch(setAccessToken(tokens.access));
        dispatch(setRefreshToken(tokens.refresh));
        setLocalAccessToken(tokens.access);
        setLocalRefreshToken(tokens.refresh);
        dispatch(setUserDetails(user));
        dispatch(setWizardUserPreferences(user.preferences || null));
        trackClarityIdentify(user.email);

        if (plan) {
            dispatch(setUserPlan(plan));
        }

        trackClarityEvent(CLARITY_EVENT_NAMES.AUTH_LOGIN_SUCCESS);

        if (user.isEmailVerified) {
            const preferences: UserPreferences | null =
                user.preferences ?? null;
            if (!preferences) {
                navigate(`/${AppRoutes.Preferences}`, { replace: true });
                return;
            }

            const hasAPreferenceSet =
                (preferences.jobTypes ?? []).length > 0 ||
                (preferences.industries ?? []).length > 0 ||
                (preferences.jobRoles ?? []).length > 0 ||
                !!preferences.experienceLevel;

            if (!hasAPreferenceSet) {
                navigate(`/${AppRoutes.Preferences}`, { replace: true });
            } else {
                navigate(AppRoutes.Dashboard, { replace: true });
            }
        } else {
            if (verifyCode) {
                sendVerifyEmail(user.email, verifyCode);
            }
            navigate({
                pathname: `/${AppRoutes.Verify}`,
                search: createSearchParams({ email: user.email }).toString(),
            });
        }
    };

    const handleLogin = async (values: LoginFormFields) => {
        setLoading(true);
        if (!executeRecaptcha) {
            setError(
                "ReCAPTCHA not available. Please refresh the page and try again.",
            );
            setLoading(false);
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha("login");

            const response = await userApi.loginApi({
                email: values.email,
                password: values.password,
                recaptchaToken,
            });
            const { user, tokens, plan, verifyCode } = response;
            onLoginSuccess(user, tokens, plan, verifyCode);
        } catch (e) {
            setError(getErrorMessage(e, "Login"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Sign in"
                description="Sign in to 1Template to create and manage your resumes"
                robotsOverride="noindex, nofollow"
            />
            <div>
                <AppText
                    variant="headings"
                    className="mb-8 text-center"
                >
                    Sign in
                </AppText>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleLogin}
                    validationSchema={loginSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                >
                    {({ values, errors, handleChange }) => (
                        <Form>
                            <div className="m-auto max-w-[450px]">
                                {error && (
                                    <Alert
                                        type="error"
                                        message={error}
                                        className="mb-6"
                                    />
                                )}
                                <Input
                                    type="text"
                                    autoComplete="email"
                                    label="Email"
                                    name="email"
                                    value={values.email}
                                    formikChange={handleChange}
                                    error={errors.email}
                                    containerClassName="mb-4"
                                />
                                <Input
                                    type="password"
                                    autoComplete="password"
                                    label="Password"
                                    name="password"
                                    formikChange={handleChange}
                                    value={values.password}
                                    error={errors.password}
                                    containerClassName="mb-4"
                                />
                                <div className="flex justify-end">
                                    <NavLink
                                        to={`/${AppRoutes.ForgotPassword}`}
                                        className="text-primary-700 hover:text-primary-600"
                                    >
                                        <AppText variant="labelsbuttons">
                                            Forgot password?
                                        </AppText>
                                    </NavLink>
                                </div>
                                <Button
                                    className="mt-4 w-full"
                                    type="submit"
                                    disabled={loading}
                                    loading={loading}
                                >
                                    Sign in
                                </Button>
                                <Button
                                    to={`/${AppRoutes.Signup}`}
                                    color={"primary"}
                                    variant={"text"}
                                    className="mb-4 mt-4 w-full lg:mb-0 lg:mt-2"
                                >
                                    New to 1Template? Create account
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {isSSOEnabled && (
                <SSOLogin
                    setError={setError}
                    onSuccess={onLoginSuccess}
                />
            )}
        </NoNavPageContainer>
    );
};

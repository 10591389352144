import { bulletBuilderSectionTypeToPlaceholder, paragraphProSectionTypeToPlaceholder } from "@/components/AiToolbox/constants";
import { CreditActionCode } from "@/types/creditAction";

export const getAiFeaturePlaceholder = (
  creditActionCode?: CreditActionCode,
  sectionType: string = "default",
) => {
  switch (creditActionCode) {
    case CreditActionCode.AI_GENERATE_BULLET_POINTS:
      return bulletBuilderSectionTypeToPlaceholder[sectionType] || bulletBuilderSectionTypeToPlaceholder.default;
    case CreditActionCode.AI_GENERATE_SUMMARY:
      return paragraphProSectionTypeToPlaceholder[sectionType] || paragraphProSectionTypeToPlaceholder.default;
    case CreditActionCode.AI_PROMPT_REWRITE:
      return "Enter text you’d like to refine or rephrase for improved clarity and flow.";
    case CreditActionCode.AI_PROMPT_SHORTEN:
      return "Add text you’d like to make more concise while retaining its meaning.";
    case CreditActionCode.AI_PROMPT_FORMAL:
      return "Provide text you’d like to elevate to a professional or formal tone.";
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return "Insert text you’d like checked for grammar, punctuation, and spelling errors.";
    default:
      return "";
  }
};

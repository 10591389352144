import clsx from "clsx";
import { ReactNode, useState } from "react";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";

interface AlertProps {
    type: "success" | "error" | "info" | "warning";
    message: string | ReactNode;
    className?: string;
    icon?: ReactNode;
    actionLabel?: string;
    onAction?: () => void;
    actionLink?: string;
    dismissable?: boolean;
}

export const Alert = ({
    type,
    message,
    className,
    icon,
    actionLabel,
    onAction,
    actionLink,
    dismissable = false,
}: AlertProps) => {
    const [isVisible, setIsVisible] = useState(true);
    if (!isVisible) return null;
    return (
        <div
            className={`${className} relative flex flex-col rounded-lg border-2 ${
                type === "success"
                    ? "border-success-300 bg-success-100"
                    : type === "error"
                      ? "border-error-300 bg-red-100"
                      : type === "warning"
                        ? "border-alert-300 bg-alert-100"
                        : "border-primary-300 bg-primary-50"
            } p-4 text-neutral-700`}
            role="alert"
        >
            {dismissable && (
                <button
                    onClick={() => setIsVisible(false)}
                    className="absolute right-3 top-1 text-neutral-500 hover:text-neutral-700"
                    aria-label="Dismiss"
                >
                    ×
                </button>
            )}
            <div className={clsx("flex items-center", dismissable && "pr-2")}>
                {icon && <span className="mr-4">{icon}</span>}
                {typeof message === "string" ? (
                    <AppText
                        className="block text-center text-neutral-700"
                        variant="labelsbuttons"
                    >
                        {message}
                    </AppText>
                ) : (
                    message
                )}
            </div>

            {actionLabel && (onAction || actionLink) && (
                <Button
                    variant={"solid"}
                    color={
                        type === "error" || type === "warning"
                            ? "red"
                            : "primary"
                    }
                    onClick={onAction}
                    to={actionLink}
                    className="mr-auto mt-4"
                >
                    {actionLabel}
                </Button>
            )}
        </div>
    );
};

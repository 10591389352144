import clsx from "clsx";

interface AdminTableBodyCellProps {
    children: React.ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLTableCellElement>) => void;
    width?: number;
    rawValue?: string;
}

export const AdminTableBodyCell = ({
    children,
    className,
    onClick,
    width,
    rawValue,
}: AdminTableBodyCellProps) => {
    return (
        <td
            className={clsx(
                "group relative whitespace-nowrap px-4 py-2 text-sm font-medium text-neutral-900",
                className,
            )}
            style={{
                maxWidth: width,
                width: width,
            }}
            onClick={onClick}
        >
            <div className="overflow-hidden text-ellipsis">{children}</div>
            {rawValue && (
                <div className="pointer-events-none absolute left-0 z-50 max-w-[300px] -translate-y-1/2 text-wrap rounded-md bg-gray-900 px-2 py-1 text-xs text-white opacity-0 shadow-lg transition-opacity delay-1000 duration-150 ease-in-out group-hover:opacity-100">
                    {rawValue}
                </div>
            )}
        </td>
    );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import assistantApi from "@/api/assistant";
import userApi from "@/api/user";
import { Button } from "@/components/Button/Button";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { JobRoleList } from "@/pages/Preferences/components/JobRoleList";
import { jobRoles } from "@/pages/Preferences/constants";
import { addNotification } from "@/store/app/slice";
import { setAssistantItems } from "@/store/assistant/slice";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getUserDetails, getUserPreferences } from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { setWizardUserPreference } from "@/store/wizard/slice";
import { NotificationMessageType, NotificationType } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";
import { UserPreferences } from "@/types/user";

const PreferencesModal = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUserDetails);
    const isOpen = useAppSelector(
        getIsModalOpen(ModalTypes.ASSISTANT_PREFERENCES),
    );
    const modalProps = useAppSelector(
        getModalProps(ModalTypes.ASSISTANT_PREFERENCES),
    );
    const userPreferences = useAppSelector(getUserPreferences);

    const hasCustomRole = useMemo(
        () =>
            userPreferences?.jobRoles?.some(
                role => !jobRoles.map(r => r.value).includes(role),
            ),
        [userPreferences],
    );

    const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>(
        userPreferences?.jobRoles ?? [],
    );
    const [localPreference, setLocalPreference] = useState<
        string | undefined
    >();

    const [localTitle, setLocalTitle] = useState<string | undefined>();
    const [localDescription, setLocalDescription] = useState<
        string | undefined
    >();
    const [isClosing, setIsClosing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setSelectedJobRoles(userPreferences?.jobRoles ?? []);
    }, [userPreferences]);

    useEffect(() => {
        if (modalProps && !isClosing) {
            setLocalPreference(modalProps.preference);
            setLocalTitle(modalProps.title);
            setLocalDescription(modalProps.description);
        }
    }, [modalProps, isClosing]);

    const onClose = useCallback(() => {
        setIsClosing(true);
        dispatch(closeModal(ModalTypes.ASSISTANT_PREFERENCES));
    }, [dispatch]);

    const onSavePreferences = useCallback(async () => {
        setIsSaving(true);
        const newPreferences: UserPreferences = {
            ...(userPreferences ?? {}),
            jobRoles: selectedJobRoles,
            experienceLevel: userPreferences?.experienceLevel ?? "",
            industries: userPreferences?.industries ?? [],
            jobTypes: userPreferences?.jobTypes ?? [],
        };
        try {
            const newUser = await userApi.saveUserApi(
                {
                    preferences: newPreferences,
                },
                user?.id,
            );
            dispatch(setUserDetails(newUser));
            dispatch(
                setWizardUserPreference({
                    preference: "jobRoles",
                    value: selectedJobRoles,
                }),
            );

            assistantApi.getUserAssistantItems().then(assistantItems => {
                dispatch(setAssistantItems(assistantItems.results));
            });
        } catch (error) {
            dispatch(
                addNotification({
                    title: "Unable to save preferences",
                    desc: "Please try again.",
                    type: NotificationType.ERROR,
                    messageType: NotificationMessageType.PREFERENCES_SAVED,
                }),
            );
        } finally {
            onClose();
            setIsSaving(false);
        }
    }, [dispatch, selectedJobRoles, onClose, userPreferences, user]);

    const onJobRoleClick = useCallback((role: string, checked: boolean) => {
        setSelectedJobRoles(prev =>
            checked
                ? [...new Set([...prev, role])]
                : prev.filter(r => r !== role),
        );
    }, []);
    const navigate = useNavigate();
    const goToPreferencesWizard = useCallback(() => {
        const currentPath = window.location.pathname.replace(/^\//, "");
        navigate(
            `/${AppRoutes.Preferences}?redirect=${encodeURIComponent(currentPath)}`,
        );
        dispatch(closeModal(ModalTypes.ASSISTANT_PREFERENCES));
        dispatch(closeModal(ModalTypes.ASSISTANT_JOB_ROLE));
    }, [navigate, dispatch]);

    return (
        <Modal
            modalType={ModalTypes.ASSISTANT_PREFERENCES}
            open={isOpen}
            title={localTitle ?? ""}
            width="4xl"
            footer={
                <ModalFooter
                    primaryActionText="Update Preferences"
                    primaryActionOnClick={onSavePreferences}
                    secondaryActionText="Cancel"
                    secondaryActionOnClick={onClose}
                    primaryActionLoading={isSaving}
                />
            }
        >
            <div>
                <AppText
                    variant="regular"
                    className="mb-4 text-neutral-900"
                >
                    {localDescription}
                </AppText>
                {localPreference === "jobRoles" && (
                    <>
                        <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-3 overflow-y-auto px-20 py-2">
                            <JobRoleList
                                onJobRoleClick={onJobRoleClick}
                                selectedJobRoles={selectedJobRoles}
                            />
                        </div>
                        {hasCustomRole && (
                            <div className="mx-20 mt-8 flex items-center justify-center gap-10 rounded-lg bg-neutral-100 px-4 py-3 text-neutral-700">
                                <div className="flex flex-col gap-2">
                                    <AppText variant="contextheading">
                                        Role not listed?
                                    </AppText>
                                    <AppText variant="regular">
                                        Currently, the builder assistant doesn’t
                                        allow the addition or selection of other
                                        job roles. To manage all of your
                                        preferences, visit the preferences
                                        wizard.
                                    </AppText>
                                </div>
                                <Button
                                    variant={"solid"}
                                    color={"softIndigo"}
                                    className="w-64"
                                    onClick={goToPreferencesWizard}
                                >
                                    Preferences Wizard
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default PreferencesModal;

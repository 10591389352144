import { BaseAiFeature } from "./BaseAiFeature";
import aiApi from "@/api/ai";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getUserPreferences } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";

export const AiBulletPointsFeature = ({
    sectionType,
}: {
    sectionType?: string;
}) => {
    const dispatch = useAppDispatch();
    const userPreferences = useAppSelector(getUserPreferences);

    const handleGenerate = async (input: string) => {
        const response = await aiApi.generateBulletPoints(
            {
                temperature: 0.7,
                includeUsage: false,
                content: input,
                // mockResponse: true,
                sectionType,
                industries: userPreferences?.industries ?? [],
                jobRoles: userPreferences?.jobRoles ?? [],
                experienceLevel: userPreferences?.experienceLevel ?? "Any",
            },
            dispatch,
        );

        return response.output;
    };

    return (
        <BaseAiFeature
            title="Bullet Builder"
            creditActionCode={CreditActionCode.AI_GENERATE_BULLET_POINTS}
            onGenerate={handleGenerate}
            sectionType={sectionType}
        />
    );
};

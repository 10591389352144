import { useContext } from 'react';
import { EditorContext } from '../context/EditorContext';
import { EditorContextType } from '@/types/editor';

export const useContentEditor = () => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error("useEditor must be used within a EditorProvider");
  }
  return context as EditorContextType;
};

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown, ChevronRight } from "lucide-react";
import { CopyButton } from "@/components/Button/CopyButton";
import { AppText } from "@/components/Text/AppText";

interface AssistantItemBlockProps {
    canCopy: boolean;
    isExpandable: boolean;
    content: string;
    isLastItem: boolean;
    title?: string;
}

export const AssistantItemBlock = ({
    canCopy,
    isExpandable,
    content,
    isLastItem,
    title,
}: AssistantItemBlockProps) => {
    return (
        <Disclosure defaultOpen={!isExpandable}>
            {({ open }) => (
                <div
                    className={clsx(
                        "relative min-h-[44px] rounded-lg bg-neutral-50 p-2",
                        !isLastItem && "mb-3",
                    )}
                >
                    <DisclosureButton
                        className="flex items-center gap-2"
                        as="div"
                    >
                        {(isExpandable || canCopy) && (
                            <div>
                                <motion.div
                                    className={clsx(
                                        "absolute left-2 z-10 flex gap-2",
                                        open
                                            ? "pointer-events-none"
                                            : "cursor-pointer",
                                    )}
                                    initial={false}
                                    animate={{
                                        opacity: open ? 0 : 1,
                                    }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                        delay: open ? 0.3 : 0.1,
                                    }}
                                >
                                    {title ? (
                                        <AppText
                                            className="mr-20 mt-1 line-clamp-1 text-left text-neutral-900"
                                            variant="regular"
                                        >
                                            {title}
                                        </AppText>
                                    ) : (
                                        <div
                                            className="mr-20 line-clamp-1 pt-1 text-left text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop"
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                    )}
                                </motion.div>
                                <motion.div
                                    initial={false}
                                    animate={{
                                        top: open ? "auto" : 8,
                                        bottom: open ? 8 : "auto",
                                    }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                        delay: open ? 0.1 : 0,
                                    }}
                                    className="absolute right-2 z-10 flex gap-2 bg-neutral-50"
                                >
                                    {canCopy && (
                                        <CopyButton content={content} />
                                    )}
                                    {isExpandable && (
                                        <div className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-neutral-100 text-neutral-900 hover:bg-neutral-200">
                                            {open ? (
                                                <ChevronDown
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            ) : (
                                                <ChevronRight
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            )}
                                        </div>
                                    )}
                                </motion.div>
                            </div>
                        )}
                    </DisclosureButton>
                    <AnimatePresence initial={false}>
                        {open && (
                            <DisclosurePanel
                                static
                                as={motion.div}
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 },
                                }}
                                transition={{
                                    duration: 0.3,
                                    ease: "easeInOut",
                                }}
                                className="overflow-hidden"
                            >
                                {title && (
                                    <AppText
                                        className="mb-2 mt-1 text-neutral-900"
                                        variant="regular"
                                    >
                                        {title}
                                    </AppText>
                                )}
                                <div
                                    className={clsx(
                                        "pt-1 text-app-regular-5-mobile text-neutral-900 md:text-app-regular-5-desktop",
                                        canCopy || isExpandable
                                            ? "pb-8"
                                            : "pb-1",
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            </DisclosurePanel>
                        )}
                    </AnimatePresence>
                </div>
            )}
        </Disclosure>
    );
};

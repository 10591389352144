import { SparklesIcon } from "lucide-react";
import { AppText } from "@/components/Text/AppText";

export const LaunchPromotion = () => {
    return null;
    return (
        <div className="relative mx-auto flex w-[600px] items-center justify-center gap-4 overflow-hidden rounded-lg bg-primary-700 px-4 py-3 text-white shadow-md">
            <SparklesIcon className="h-6 w-6 animate-pulse" />
            <div className="flex flex-col items-center">
                <AppText variant="contextheading">LAUNCH PROMOTION:</AppText>
                <AppText
                    variant="regular"
                    className="text-primary-100"
                >
                    No credit card details required.
                </AppText>
            </div>
        </div>
    );
};

import clsx from "clsx";
import React, { createElement } from "react";
import { appTextClassMap } from "@/components/Text/constants";

interface AppTextProps {
    children: React.ReactNode;
    variant:
        | "headings"
        | "subheadings"
        | "contextheading"
        | "labelsbuttons"
        | "regular"
        | "footnote";
    className?: string;
    applyGradient?: boolean;
}

export const AppText = ({
    children,
    variant,
    className,
    applyGradient,
}: AppTextProps) => {
    const { element, elementClassName } = appTextClassMap[variant];
    return createElement(
        element,
        {
            className: clsx(
                elementClassName,
                className,
                applyGradient && [
                    "bg-gradient-primary",
                    "bg-clip-text",
                    "text-transparent",
                ],
            ),
        },
        children,
    );
};

import { Token, User } from "@/types";
import { UserPlan } from "@/types/billing";
import { CreditActionCode } from "@/types/creditAction";
import { ICredit } from "@/types/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserState {
    userDetails: User | undefined;
    accessToken: Token | null;
    refreshToken: Token | null;
    role?: "admin" | "user";
    isEmailVerified: boolean;
    id?: string;
    name?: string;
    plan: UserPlan | undefined;
    features: Record<CreditActionCode, boolean>;
}

const initialState: UserState = {
    userDetails: undefined,
    accessToken: null,
    refreshToken: null,
    role: undefined,
    isEmailVerified: false,
    id: undefined,
    name: undefined,
    plan: {
        planStatus: null,
        details: null,
        isSubscribed: false,
    },
    features: {} as Record<CreditActionCode, boolean>,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetails: (state, { payload }) => {
            return {
                ...state,
                userDetails: {
                    ...payload,
                },
            };
        },
        setUserEmail: (state, { payload }) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    email: payload,
                },
            };
        },
        setAccessToken: (state, { payload }: PayloadAction<Token | null>) => {
            return {
                ...state,
                accessToken: payload,
            };
        },
        setRefreshToken: (state, { payload }: PayloadAction<Token | null>) => {
            return {
                ...state,
                refreshToken: payload,
            };
        },
        resetTokens: state => {
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
            };
        },
        setUserPlan: (state, { payload }: PayloadAction<UserPlan>) => {
            let daysBeforeEnd;
            if (payload?.details?.endDate) {
                const endDate = new Date(payload.details.endDate);
                const now = new Date();
                // Calculate the difference in days
                daysBeforeEnd = Math.ceil((endDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
            }

            return {
                ...state,
                plan: {
                    ...payload,
                    details: {
                        ...payload.details,
                        daysBeforeEnd,
                    },
                },
            };
        },
        setUserCredits: (state, { payload }: PayloadAction<ICredit>) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    credits: {
                        ...state.userDetails!.credits,
                        balance: payload.balance,
                        lastUpdated: payload.lastUpdated,
                    },
                },
            };
        },
        setUserFeatures: (state, { payload }: PayloadAction<Record<CreditActionCode, boolean>>) => {
            return {
                ...state,
                features: payload,
            };
        },
        updateUserDetails: (state, { payload }: PayloadAction<Partial<User>>) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    ...payload,
                },
            };
        },
    },
});

export const { setUserDetails, resetTokens, setAccessToken, setRefreshToken, setUserEmail, setUserPlan, setUserCredits, setUserFeatures, updateUserDetails } =
    userSlice.actions;
export const { reducer: userReducer } = userSlice;

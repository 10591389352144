import clsx from "clsx";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import creditActionApi from "@/api/creditAction";
import creditTransactionApi from "@/api/creditTransaction";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { TransactionLineItem } from "@/pages/Profile/TransactionLineItem";
import { getUserId } from "@/store/user/selectors";
import { ICreditAction } from "@/types/creditAction";
import { ICreditTransaction } from "@/types/creditTransaction";

const getVisiblePages = (currentPage: number, totalPages: number) => {
    const maxVisible = 5;
    let start = Math.max(1, currentPage - Math.floor((maxVisible - 1) / 2));
    if (start + maxVisible > totalPages + 1) {
        start = Math.max(1, totalPages - maxVisible + 1);
    }
    const end = Math.min(start + maxVisible - 1, totalPages);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

export const ProfileTransactions = () => {
    const userId = useAppSelector(getUserId);
    const [transactions, setTransactions] = useState<ICreditTransaction[]>([]);
    const [creditActions, setCreditActions] = useState<ICreditAction[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [transactionsResult, actionsResult] = await Promise.all([
                    creditTransactionApi.getTransactions({
                        userId,
                        page,
                        limit: 5,
                    }),
                    creditActionApi.getCreditActionsApi({
                        limit: 999,
                    }),
                ]);

                setTransactions(transactionsResult.results);
                setCreditActions(actionsResult.results);
                setTotalPages(Math.ceil(transactionsResult.totalResults / 10));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userId, page]);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    if (isLoading && !transactions.length) {
        return (
            <div className="flex h-[200px] items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-4">
            <AppText variant="subheadings">Transaction History</AppText>
            <div className="flex flex-col gap-2 overflow-x-auto">
                {transactions.map(transaction => (
                    <TransactionLineItem
                        key={transaction.id}
                        transaction={transaction}
                        creditAction={
                            creditActions.find(
                                action =>
                                    action.code === transaction.actionCode,
                            ) as ICreditAction
                        }
                    />
                ))}
            </div>
            {totalPages > 1 && (
                <div className="mt-2 flex justify-center gap-2">
                    <PaginationButton
                        onClick={() => handlePageChange(page - 1)}
                        disabled={page === 1 || isLoading}
                        isActive={false}
                    >
                        <ChevronLeft
                            size={16}
                            strokeWidth={3}
                        />
                    </PaginationButton>
                    {getVisiblePages(page, totalPages).map(pageNumber => (
                        <PaginationButton
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            isActive={pageNumber === page}
                        >
                            <AppText
                                variant="regular"
                                className="!font-semibold"
                            >
                                {pageNumber}
                            </AppText>
                        </PaginationButton>
                    ))}
                    <PaginationButton
                        onClick={() => handlePageChange(page + 1)}
                        disabled={page === totalPages || isLoading}
                        isActive={false}
                    >
                        <ChevronRight
                            size={16}
                            strokeWidth={3}
                        />
                    </PaginationButton>
                </div>
            )}
        </div>
    );
};

const PaginationButton = ({
    onClick,
    disabled,
    children,
    isActive,
}: {
    onClick: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    isActive?: boolean;
}) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={clsx(
                isActive
                    ? "bg-primary-900 text-neutral-50"
                    : "text-primary-900",
                "flex h-8 w-8 items-center justify-center rounded-lg disabled:opacity-30",
            )}
        >
            {children}
        </button>
    );
};

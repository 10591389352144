import { Trash2 } from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { Input } from "@/components/form/Input";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { EditorContextType } from "@/types/editor";
import { ModalTypes } from "@/types/modal";
import { validateUrl } from "@/utils/string";

const AddLinkModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.ADD_LINK));
    const modalProps = useAppSelector(getModalProps(ModalTypes.ADD_LINK));
    const [linkUrl, setLinkUrl] = useState<string | undefined>(
        modalProps?.url ?? "",
    );
    const [error, setError] = useState<string | undefined>();
    const { activeEditor } = useContext(EditorContext) as EditorContextType;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modalProps?.url) {
            setLinkUrl(modalProps.url);
            setError(undefined);
        }
        if (!isOpen) {
            setLinkUrl(undefined);
            setError(undefined);
        }
    }, [modalProps?.url, isOpen]);

    const handleApplyLink = useCallback(() => {
        if (!activeEditor || !linkUrl) return;

        if (linkUrl === "") {
            activeEditor.chain().extendMarkRange("link").unsetLink().run();
            dispatch(closeModal(ModalTypes.ADD_LINK));
            return;
        }

        // Validate URL before applying
        if (!validateUrl(linkUrl)) {
            setError("Please enter a valid URL (e.g., https://example.com)");
            return;
        }

        // update link
        activeEditor
            .chain()
            .extendMarkRange("link")
            .setLink({ href: linkUrl })
            .run();

        // Close modal first
        dispatch(closeModal(ModalTypes.ADD_LINK));

        // Clean up state
        setLinkUrl(undefined);
        setError(undefined);
    }, [activeEditor, linkUrl, dispatch]);

    const handleRemoveLink = useCallback(() => {
        if (!activeEditor) return;
        activeEditor.chain().focus().extendMarkRange("link").unsetLink().run();

        // Focus editor before closing modal
        activeEditor.commands.focus();
        dispatch(closeModal(ModalTypes.ADD_LINK));
    }, [activeEditor, dispatch]);

    // Handle cleanup when modal closes
    useEffect(() => {
        if (!isOpen) {
            setLinkUrl(undefined);
            setError(undefined);
        }
    }, [isOpen]);

    return (
        <Modal
            modalType={ModalTypes.ADD_LINK}
            open={isOpen}
            title="Add Link"
            width="lg"
        >
            <AppText variant="labelsbuttons">
                Attach a link to the selected text.
            </AppText>
            <div className="mt-4 flex flex-col gap-2">
                <div className="flex items-center gap-4">
                    <Input
                        type="text"
                        placeholder="https://example.com"
                        className="w-full"
                        onChange={(_, val) => {
                            setLinkUrl(val);
                            setError(undefined);
                        }}
                        containerClassName="w-full"
                        value={linkUrl || ""}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                handleApplyLink();
                            }
                        }}
                    />
                    <div className="flex items-center gap-2">
                        <Button
                            onClick={handleApplyLink}
                            disabled={!linkUrl}
                        >
                            Apply
                        </Button>
                        <IconButton
                            onClick={handleRemoveLink}
                            color={"grey"}
                            disabled={!linkUrl}
                        >
                            <Trash2 size={16} />
                        </IconButton>
                    </div>
                </div>
            </div>
            {error && (
                <AppText
                    variant="regular"
                    className="mt-2 text-red-500"
                >
                    {error}
                </AppText>
            )}
        </Modal>
    );
};

export default AddLinkModal;

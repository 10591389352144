import { SmileIcon } from "lucide-react";
import React, { useState } from "react";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { Radio } from "@/components/form/Radio";
import { TextArea } from "@/components/form/Textarea";
import { sendFeedbackSurveyEmail } from "@/email/send";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { useTransaction } from "@/hooks/useTransaction";
import { setShowFeedbackSurvey } from "@/store/app/slice";
import { getUserDetails } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { DeviceContextType } from "@/types/device";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

interface FeedbackData {
    satisfaction: number;
    nps: number;
    comments?: string;
}

/**
 * Feedback survey rules:
 * 1. User will be prompted once they have downloaded a resume THREE times.
 * 2. When a user has been prompted, they will have a timestamp flag set on their account
 * 3. When the user has a timestamp flag set, they will not be prompted again until the timestamp is older than 30 days.
 * 4. Each time the survey is shown, the timestamp flag is set to the current time and the download count is ignored.
 */
export const FeedbackSurvey = () => {
    const [feedback, setFeedback] = useState<FeedbackData>({
        satisfaction: 3,
        nps: 5,
        comments: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const userDetails = useAppSelector(getUserDetails);
    const { deviceType } = useDevice() as DeviceContextType;
    const dispatch = useAppDispatch();
    const { getAndFormatCreditActionCostByCode, validateAndAddTransaction } =
        useTransaction();
    const surveyEarnAmount = getAndFormatCreditActionCostByCode(
        CreditActionCode.APP_FEEDBACK_SURVEY,
    );

    const submitFeedback = async (feedback: FeedbackData) => {
        const browserDetails = {
            userAgent: navigator.userAgent,
            language: navigator.language,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
        };

        await sendFeedbackSurveyEmail(
            userDetails!,
            window.location.href,
            feedback.satisfaction,
            feedback.nps,
            deviceType,
            JSON.stringify(browserDetails),
            feedback.comments,
        );

        await validateAndAddTransaction({
            actionCode: CreditActionCode.APP_FEEDBACK_SURVEY,
            userId: userDetails?.id,
        });
        setIsSubmitting(false);
        setIsSuccess(true);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await submitFeedback(feedback);
            trackClarityEvent(CLARITY_EVENT_NAMES.FEEDBACK_SURVEY_SUBMITTED);
            setIsSuccess(true);
            setTimeout(() => {
                dispatch(setShowFeedbackSurvey(false));
            }, 3000);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleChange = (
        field: keyof FeedbackData,
        value: number | string,
    ) => {
        setFeedback(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    return (
        <>
            {isSuccess ? (
                <div className="animate-in fade-in mx-auto max-w-xl rounded-lg px-4 py-6 text-center duration-700">
                    <SmileIcon className="mx-auto mb-2 h-10 w-10 animate-pulse text-primary-700" />
                    <AppText
                        variant="labelsbuttons"
                        className="mb-2 block !leading-tight text-neutral-900"
                    >
                        Thank you!
                    </AppText>
                    <AppText
                        variant="footnote"
                        className="block !leading-tight text-neutral-700"
                    >
                        We appreciate you taking the time to help us improve.
                    </AppText>
                    <AppText
                        variant="footnote"
                        className="mt-1 block !leading-tight text-neutral-700"
                    >
                        You&apos;ve earned {surveyEarnAmount} for submitting
                        your feedback!
                    </AppText>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit}
                    className="mx-auto max-w-xl rounded-lg px-4 py-4"
                >
                    <div className="space-y-2">
                        <div className="space-y-1">
                            <AppText
                                variant="footnote"
                                className="mb-1 block leading-tight text-neutral-900"
                            >
                                How satisfied are you with your experience?
                            </AppText>
                            <div className="flex justify-between gap-2 px-2">
                                {[1, 2, 3, 4, 5].map(value => (
                                    <Radio
                                        key={value}
                                        id={`satisfaction-${value}`}
                                        label={value.toString()}
                                        checked={
                                            feedback.satisfaction === value
                                        }
                                        onChange={() =>
                                            handleChange("satisfaction", value)
                                        }
                                        borderless
                                        vertical
                                        className="scale-90 !px-0 transition-all hover:scale-100"
                                    />
                                ))}
                            </div>
                            <div className="flex justify-between px-1">
                                <AppText
                                    variant="footnote"
                                    className="text-neutral-500"
                                >
                                    Not at all
                                </AppText>
                                <AppText
                                    variant="footnote"
                                    className="text-neutral-500"
                                >
                                    Completely
                                </AppText>
                            </div>
                        </div>

                        <div className="h-px bg-gradient-to-r from-transparent via-neutral-200 to-transparent" />

                        <div className="space-y-1">
                            <AppText
                                variant="footnote"
                                className="mb-1 block leading-tight text-neutral-900"
                            >
                                How likely are you to recommend 1Template to a
                                friend?
                            </AppText>
                            <div className="flex flex-wrap justify-between">
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                    value => (
                                        <Radio
                                            key={value}
                                            id={`nps-${value}`}
                                            label={value.toString()}
                                            checked={feedback.nps === value}
                                            onChange={() =>
                                                handleChange("nps", value)
                                            }
                                            borderless
                                            vertical
                                            className="scale-75 !px-0 transition-all hover:scale-100"
                                        />
                                    ),
                                )}
                            </div>
                            <div className="flex justify-between px-1">
                                <AppText
                                    variant="footnote"
                                    className="text-neutral-500"
                                >
                                    Not likely
                                </AppText>
                                <AppText
                                    variant="footnote"
                                    className="text-neutral-500"
                                >
                                    Very likely
                                </AppText>
                            </div>
                        </div>

                        <div className="h-px bg-gradient-to-r from-transparent via-neutral-200 to-transparent" />

                        <div className="space-y-1">
                            <AppText
                                variant="footnote"
                                className="block leading-tight text-neutral-900"
                            >
                                Please tell us what we can improve and what
                                features you&apos;d like to see next. (10-300
                                characters)
                            </AppText>
                            <TextArea
                                value={feedback.comments}
                                onChange={e =>
                                    handleChange("comments", e.target.value)
                                }
                                rows={3}
                                name="comments"
                                id="comments"
                                required
                                minLength={10}
                                maxLength={300}
                                className="text-sm placeholder:py-1 placeholder:text-xs"
                            />
                        </div>
                    </div>

                    <div className="mt-3 space-y-2">
                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                        >
                            Submit Feedback
                        </Button>
                        {surveyEarnAmount && (
                            <AppText
                                variant="footnote"
                                className="block text-center leading-tight text-neutral-600"
                            >
                                Earn {surveyEarnAmount} by submitting your
                                feedback!
                            </AppText>
                        )}
                    </div>
                </form>
            )}
        </>
    );
};

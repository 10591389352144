import { AIModel } from "./AIPreferencesConfig";

interface GenerationStats {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
    estimatedCost: number;
}

interface AIGenerationStatsProps {
    currentStats: GenerationStats;
    totalStats: GenerationStats;
    onResetTotals: () => void;
    model: AIModel;
    temperature: number;
}

export const AIGenerationStats = ({
    currentStats,
    totalStats,
    onResetTotals,
    model,
    temperature,
}: AIGenerationStatsProps) => {
    return (
        <div className="grid grid-cols-2 gap-2">
            <div className="rounded-lg border border-gray-200 bg-gray-50 p-3">
                <div className="mb-1 flex items-center justify-between">
                    <h3 className="text-xs font-medium text-gray-700">
                        Current Generation Statistics
                    </h3>
                    <div className="text-xs text-gray-500">
                        {model} | {temperature}
                    </div>
                </div>
                <table className="w-full text-xs">
                    <tbody>
                        <tr>
                            <td className="py-0.5 text-neutral-600">
                                Prompt Tokens:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {currentStats.promptTokens}
                            </td>
                            <td className="py-0.5 pl-4 text-neutral-600">
                                Completion Tokens:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {currentStats.completionTokens}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-0.5 text-neutral-600">
                                Total Tokens:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {currentStats.totalTokens}
                            </td>
                            <td className="py-0.5 pl-4 text-neutral-600">
                                Estimated Cost (AUD):
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                ${currentStats.estimatedCost.toFixed(4)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="rounded-lg border border-neutral-200 bg-primary-50/50 p-3">
                <div className="mb-1 flex items-center justify-between">
                    <h3 className="text-xs font-medium text-neutral-700">
                        Total Usage Statistics
                    </h3>
                    <button
                        onClick={onResetTotals}
                        className="text-xs text-neutral-500 hover:text-neutral-700"
                    >
                        Reset Totals
                    </button>
                </div>
                <table className="w-full text-xs">
                    <tbody>
                        <tr>
                            <td className="py-0.5 text-neutral-600">
                                Prompt Tokens:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {totalStats.promptTokens}
                            </td>
                            <td className="py-0.5 pl-4 text-neutral-600">
                                Completion Tokens:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {totalStats.completionTokens}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-0.5 text-neutral-600">
                                Tokens Used:
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                {totalStats.totalTokens}
                            </td>
                            <td className="py-0.5 pl-4 text-neutral-600">
                                Estimated Cost (AUD):
                            </td>
                            <td className="py-0.5 text-right font-medium">
                                ${totalStats.estimatedCost.toFixed(4)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

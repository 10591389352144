import clsx from "clsx";
import { Settings2 } from "lucide-react";
import { useState } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { Image } from "@/components/EditorComponents/Image";
import { PresetIconButton } from "@/components/Presets/PresetIconButton";
import { ProfilePresets } from "@/components/Presets/Profile/ProfilePresets";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowSideBar, getSideBarState } from "@/store/app/selectors";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { getActiveResumeSectionById } from "@/store/resume/selectors";
import { setActiveSection } from "@/store/template/slice";
import { SideBarState } from "@/types/app";

interface HeaderToolbarProps {
    sectionId: string;
}

export const HeaderToolbar = ({ sectionId }: HeaderToolbarProps) => {
    const dispatch = useAppDispatch();
    const [isPresetsMenuOpen, setIsPresetsMenuOpen] = useState(false);
    const sideBarIsOpen = useAppSelector(getShowSideBar);
    const sideBarState = useAppSelector(getSideBarState);
    const headerSectionDetails = useAppSelector(state =>
        getActiveResumeSectionById(state, sectionId),
    );
    const imageSrc = headerSectionDetails?.header?.image;

    return (
        <div
            className={clsx(
                "absolute -top-8 left-1/2 z-20 flex h-[52px] w-max -translate-x-1/2 transform items-center justify-center gap-2 rounded-lg bg-neutral-50 px-2 font-body shadow-tooltip ring-2 ring-neutral-300 transition-opacity",
                isPresetsMenuOpen
                    ? "opacity-100"
                    : "opacity-0 group-hover:opacity-100",
            )}
        >
            <Image
                src={imageSrc ?? ""}
                sectionId={sectionId}
                propId="image"
                showPreview={false}
                propType="header"
                triggerType="iconButton"
                editable={true}
            />
            <IconButton
                onClick={() => {
                    if (sideBarState === SideBarState.EDIT && sideBarIsOpen) {
                        dispatch(setShowSideBar(false));
                    } else {
                        dispatch(setActiveSection(sectionId));
                        dispatch(setSideBarState(SideBarState.EDIT));
                        dispatch(setShowSideBar(true));
                    }
                }}
                variant={"solid"}
                color={"white"}
                className={clsx(
                    sideBarState === SideBarState.EDIT &&
                        sideBarIsOpen &&
                        "!bg-neutral-200",
                )}
            >
                <Settings2 size={16} />
            </IconButton>
            <PresetIconButton
                title="Header Presets"
                setIsPresetsMenuOpen={setIsPresetsMenuOpen}
                isPresetsMenuOpen={isPresetsMenuOpen}
                anchorTo="bottom"
                type="header"
            >
                <ProfilePresets
                    sectionId={sectionId}
                    setIsPresetsMenuOpen={setIsPresetsMenuOpen}
                />
            </PresetIconButton>
        </div>
    );
};

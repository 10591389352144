import { formatTemplateTimestamps } from "@/pages/Dashboard/utils";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { getActiveSection } from "@/store/template/selectors";
import { getSections } from "@/store/sections/selectors";
import { ResumeSections, Section } from "@/types";
import { getDefaultSectionsDataDocumentType } from "@/helper/getDefaultSectionsData";
import { ColumnLayout, DocumentVisibility, Resume, SectionDetails } from "@/types/resume";
import { GLOBAL_STYLE_KEY, PROFILE_IMAGE_KEY } from "@/constants/resume";

export const getResumeState = (state: RootState) => state.resume;

export const getHasUnsavedChanges = createSelector(
    getResumeState,
    resume => resume.hasUnsavedChanges,
);

export const getActiveResume = createSelector(
    getResumeState,
    resume => resume.activeResume,
);

export const getActiveResumeSections = createSelector(
    getActiveResume,
    resume => resume?.sections,
);

export const getActiveResumeName = createSelector(
    getActiveResume,
    resume => resume?.name,
);

export const getActiveResumeIsTemplate = createSelector(
    getActiveResume,
    resume => resume?.isTemplate,
);

export const getActiveResumeId = createSelector(
    getActiveResume,
    resume => resume?.id,
);

export const getActiveResumeSectionById = createSelector(
    [getActiveResumeSections, (_: RootState, sectionId: string) => sectionId],
    (sections, sectionId) => sections?.[sectionId],
);

export const getActiveResumeSectionIds = createSelector(
    getActiveResume,
    resume => Object.keys(resume?.sections ?? {}),
);

export const getActiveResumeStyles = createSelector(
    getActiveResume,
    resume => resume?.styles,
);

export const getActiveResumeColumnLayout = createSelector(
    getActiveResume,
    resume => resume?.columnLayout,
);

export const getIsTwoColumnLayout = createSelector(
    getActiveResumeColumnLayout,
    columnLayout => columnLayout === ColumnLayout.DOUBLE,
);

export const getActiveResumeDocumentType = createSelector(
    getActiveResume,
    resume => resume?.documentType,
);

export const getActiveResumeGlobalStyles = createSelector(
    getActiveResumeStyles,
    styles => styles?.[GLOBAL_STYLE_KEY] ?? {},
);

export const getActiveResumeProfileImageStyles = createSelector(
    getActiveResumeStyles,
    styles => styles?.[PROFILE_IMAGE_KEY] ?? {},
);

export const getActiveResumeIconsEnabled = createSelector(
    getActiveResumeStyles,
    styles => styles?.[GLOBAL_STYLE_KEY]?.showIcons ?? false,
);

export const getActiveResumeFontSize = createSelector(
    getActiveResumeStyles,
    styles => styles?.[GLOBAL_STYLE_KEY]?.fontSize
);

export const getActiveResumeUnderlineSectionHeadingsEnabled = createSelector(
    getActiveResumeStyles,
    styles => styles?.[GLOBAL_STYLE_KEY]?.underlineSectionHeadings ?? false,
);

export const getActiveResumeDateLocationPosition = createSelector(
    getActiveResumeStyles,
    styles => styles?.[GLOBAL_STYLE_KEY]?.dateLocationPosition ?? "column",
);

export const getResumes = createSelector(
    getResumeState,
    resume => resume.resumes,
);

export const getResumeExamples = createSelector(
    getResumeState,
    resume => resume.examples,
);

export const getResumeExamplesWithDates = createSelector(getResumeExamples, examples =>
    formatTemplateTimestamps(examples),
);

export const getResumesWithDates = createSelector(getResumeState, resume =>
    formatTemplateTimestamps(resume.resumes),
);

// Fallback to examples if resume is not found in resumes
export const getResumeById = createSelector(
    [getResumesWithDates, getResumeExamplesWithDates, (_: RootState, id: string) => id],
    (resumes, examples, id): Resume | undefined => resumes.find(resume => resume.id === id) || examples.find(example => example.id === id),
);

export const getResumeVisibilityById = createSelector(
    [getResumes, (_: RootState, id: string) => id],
    (resumes, id) => resumes.find(resume => resume.id === id)?.visibility,
);

export const getActiveResumeVisibility = createSelector(
    getActiveResume,
    resume => resume?.visibility as DocumentVisibility | undefined,
);

export const getDocumentTypeSections = createSelector(
    getSections,
    getActiveResumeDocumentType,
    (sections, documentType) => sections.filter(section => section.documentType === documentType),
);

export const getLoaderSections = createSelector(
    getActiveResumeSections,
    getDocumentTypeSections,
    (activeSections, allSections): { resumeSections: ResumeSections, allDefaultSections: ResumeSections, sectionConfigs: Section[]; } => {
        const nonHeaderSections = allSections.filter(section => !section.isStaticHeader);
        const defaultSectionContent = getDefaultSectionsDataDocumentType(nonHeaderSections, allSections[0]?.documentType);

        return {
            resumeSections: activeSections,
            allDefaultSections: defaultSectionContent,
            sectionConfigs: allSections
        };
    }
);

export const getUnusedSections = createSelector(
    getDocumentTypeSections,
    getActiveResumeSections,
    (sections, activeSections) => {
        const sectionConfigIds = Object.values(activeSections ?? {}).map(section => section.sectionConfigId);
        return sections.filter(section => !sectionConfigIds.includes(section.id));
    });


export const getUsedSectionsConfigs = createSelector(
    getDocumentTypeSections,
    getActiveResumeSections,
    (sections, usedResumeSections) => {
        const sectionConfigIds = Object.values(usedResumeSections ?? {}).map(section => section.sectionConfigId);
        return sections.filter(section => sectionConfigIds.includes(section.id));
    });

export const getResumeSectionById = createSelector(
    [getActiveResumeSections, (_: RootState, sectionId: string) => sectionId],
    (resumeSections, sectionId) => {
        if (!resumeSections) return null;
        return resumeSections[sectionId];
    }
);

export const getSectionsByActiveDocumentType = createSelector(
    getActiveResume, getSections,
    (resumes, sections): Section[] => {
        const resDocType = resumes?.documentType;
        if (!resDocType) return [];
        return sections.filter(section => section.documentType === resDocType);
    }
);

export const getSectionsByDocumentTypeId = createSelector(
    [getSections, (_: RootState, documentType: string) => documentType],
    (sections, documentType) => sections.filter(section => section.documentType === documentType),
);

export const getDefaultHeaderImage = createSelector(
    getActiveResumeDocumentType, getSections,
    (documentType, sections) => {
        const headerSection = sections.find(section => section.documentType === documentType && section.isStaticHeader);
        const headerFieldImage = headerSection?.headerFields.find(field => field.name === "image");
        const bodyFieldImage = headerSection?.bodyFields.find(field => field.name === "image");

        return headerFieldImage?.defaultValue || bodyFieldImage?.defaultValue;
    }
);

export const getActiveSectionDetails = createSelector(
    getActiveSection, getActiveResumeSections,
    (activeSection, resumeSections): SectionDetails | undefined => resumeSections?.[activeSection ?? ""]
);

export const getActiveSectionActiveWidth = createSelector(
    getActiveSectionDetails,
    sectionDetails => sectionDetails?.activeWidth
);

export const getResumeImages = createSelector(
    getResumeState, resume => resume.images
);

export const getActiveResumeHeaderImage = createSelector(
    getActiveResumeDocumentType, getSections, getResumeImages, getActiveResumeSections,
    (documentType, sections, images, resumeSections) => {
        const headerSection = sections.find(section => section.documentType === documentType && section.isStaticHeader);
        if (!headerSection) return null;
        const headerSectionId = Object.keys(resumeSections ?? {}).find(sectionId => resumeSections?.[sectionId].sectionConfigId === headerSection?.id);
        const resumeHeader = resumeSections?.[headerSectionId ?? ""];
        if (!resumeHeader) return null;

        const headerFieldImage = resumeHeader?.header["image"];
        const imageId = headerFieldImage;
        return images[imageId];
    }
);

export const getAllVisibleBodyFieldsForSection = createSelector(
    [getActiveResume, getSectionsByActiveDocumentType, (_: RootState, query: { sectionId: string, itemId: string; sectionConfigId?: string; }) => query],
    (resume, sectionConfigs, query) => {
        const { sectionId, itemId, sectionConfigId } = query;
        if (!sectionId) return [];
        const sectionConfig = sectionConfigs.find(section => section.id === sectionConfigId);
        if (!sectionConfig) return [];
        const resumeSection = resume?.sections?.[sectionId];
        const bodyDetails = resumeSection?.body?.find(item => item.__id === itemId);
        const hiddenFields = bodyDetails?.hiddenFields ?? [];
        const sectionConfigFields = sectionConfig.bodyFields;

        // Return all bodyFields not in hiddenFields
        return sectionConfigFields.filter(field => !hiddenFields.includes(field.name));
    }
);

export const getResumeSectionColumnIndexById = createSelector(
    [getActiveResumeSections, (_: RootState, sectionId: string) => sectionId],
    (resumeSections, sectionId) => {
        if (!resumeSections) return null;
        return resumeSections[sectionId]?.columnIndex;
    }
);

import { ArrowLeft, ArrowRight, WrapText } from "lucide-react";
import { Button } from "@/components/Button/Button";
import { CopyButton } from "@/components/Button/CopyButton";
import { CreditsIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { getUserCreditBalance } from "@/store/user/selectors";
import { pluralise } from "@/utils/string";

interface AiToolboxFooterProps {
    estimatedCredits: number;
    onClear: () => void;
    onGenerate: () => void;
    onInsert?: () => void;
    isGenerating: boolean;
    hasInput: boolean;
    generatedOutput: string;
    onClose: () => void;
    onBack: () => void;
}

export const AiToolboxFooter = ({
    estimatedCredits,
    onClear,
    onGenerate,
    onInsert,
    isGenerating,
    hasInput,
    generatedOutput,
    onClose,
    onBack,
}: AiToolboxFooterProps) => {
    const userCreditBalance = useAppSelector(getUserCreditBalance);

    if (generatedOutput) {
        return (
            <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between p-2">
                    <Button
                        onClick={onBack}
                        variant={"solid"}
                        color={"softIndigo"}
                        size="small"
                        leftIcon={<ArrowLeft size={16} />}
                    >
                        Back
                    </Button>
                    <div className="flex items-center gap-2">
                        <Button
                            onClick={onClose}
                            variant={"solid"}
                            color={"grey"}
                            disabled={isGenerating}
                            size="small"
                        >
                            Close
                        </Button>
                        <CopyButton
                            content={generatedOutput}
                            size="small"
                            variant="full"
                        />
                        <Button
                            onClick={onInsert}
                            variant={"solid"}
                            size="small"
                            color={"primary"}
                            leftIcon={<WrapText size={16} />}
                        >
                            Insert
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-between px-3 pb-3">
            <div className="flex items-center gap-2">
                <CreditsIcon />
                <AppText
                    variant="labelsbuttons"
                    applyGradient
                >
                    Use {estimatedCredits} of {userCreditBalance}{" "}
                    {pluralise(userCreditBalance, "Credit", "Credits")}
                </AppText>
            </div>

            <div className="flex gap-2">
                <Button
                    onClick={onClose}
                    variant={"solid"}
                    color={"grey"}
                    disabled={isGenerating}
                    size="small"
                >
                    Close
                </Button>
                <Button
                    onClick={onClear}
                    variant={"outline"}
                    color={"primary"}
                    size="small"
                    disabled={isGenerating || (!hasInput && !generatedOutput)}
                >
                    Clear
                </Button>
                <Button
                    onClick={onGenerate}
                    disabled={isGenerating || !hasInput || !!generatedOutput}
                    loading={isGenerating}
                    size="small"
                    rightIcon={<ArrowRight size={16} />}
                >
                    Generate
                </Button>
            </div>
        </div>
    );
};

import { Formik } from "formik";
import { Loader } from "lucide-react";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { creditActionSchema } from "./schema/creditAction";
import creditActionApi from "@/api/creditAction";
import { Checkbox } from "@/components/form/Checkbox";
import { Input } from "@/components/form/Input";
import { InputError } from "@/components/form/InputError";
import { InputLabel } from "@/components/form/InputLabel";
import { Select, SelectItem } from "@/components/form/Select";
import { useAppDispatch } from "@/hooks/types";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { InlineCodeEditor } from "@/pages/Admin/InlineCodeEditor/InlineCodeEditor";
import { ReadOnlyField } from "@/pages/Admin/ReadOnlyField";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType } from "@/types";
import { AdminNavigationItem } from "@/types/admin";
import {
    ICreditAction,
    CREDIT_ACTION_TYPES,
    CREDIT_ACTION_CATEGORIES,
    CREDIT_ACTION_AMOUNT_TYPES,
} from "@/types/creditAction";

const creditActionFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
];

const typeItems: SelectItem[] = CREDIT_ACTION_TYPES.map(type => ({
    id: type,
    name: type,
}));

const amountTypeItems: SelectItem[] = CREDIT_ACTION_AMOUNT_TYPES.map(type => ({
    id: type,
    name: type,
}));

const categoryItems: SelectItem[] = CREDIT_ACTION_CATEGORIES.map(category => ({
    id: category,
    name: category,
}));

export const CreditActionForm = () => {
    const { getAdminCreditAction } = useLoaderData() as {
        getAdminCreditAction: Promise<ICreditAction>;
    };

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminCreditAction}
                errorElement={<div>Failed to load credit action</div>}
            >
                {resolvedValues => (
                    <CreditActionFormFields creditAction={resolvedValues} />
                )}
            </Await>
        </Suspense>
    );
};

export const CreditActionFormFields = ({
    creditAction,
}: {
    creditAction?: ICreditAction;
}) => {
    const [activeTab, setActiveTab] = useState<string>(
        creditActionFormNavItems[0].id,
    );
    const initialValues: Partial<ICreditAction> = useMemo(
        () => ({
            code: creditAction?.code || "",
            name: creditAction?.name || "",
            description: creditAction?.description || "",
            type: creditAction?.type || "SPEND",
            credits: creditAction?.credits || null,
            category: creditAction?.category || "FEATURE",
            isActive: creditAction?.isActive ?? true,
            usageLimit: creditAction?.usageLimit || undefined,
            cooldownPeriod: creditAction?.cooldownPeriod || undefined,
            metadata: creditAction?.metadata || {},
            amountType: creditAction?.amountType || "FIXED",
        }),
        [creditAction],
    );
    const dispatch = useAppDispatch();

    const { creditActionId: id } = useParams();
    const creditActionId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (values: Partial<ICreditAction>) => {
        try {
            const { credits, ...rest } = values;
            const creditAction = {
                ...rest,
                credits: !credits ? null : Number(credits),
                metadata:
                    typeof values.metadata === "string"
                        ? JSON.parse(values.metadata)
                        : values.metadata,
            };
            if (creditActionId) {
                await creditActionApi.saveCreditActionApi(
                    creditAction as ICreditAction,
                    creditActionId,
                );
            } else {
                await creditActionApi.createCreditActionApi(
                    creditAction as ICreditAction,
                );
                navigate("/admin/credit-actions", { replace: true });
            }

            dispatch(
                addNotification({
                    title: "Successfully saved!",
                    desc: "Credit action details are successfully saved!",
                    messageType:
                        NotificationMessageType.ADMIN_CREDIT_ACTION_SAVE,
                    type: NotificationType.SUCCESS,
                }),
            );
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_CREDIT_ACTION_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        try {
            if (!creditActionId) {
                navigate("/admin/credit-actions", { replace: true });
                return;
            }
            await creditActionApi.deleteCreditActionApi(creditActionId);
            navigate("/admin/credit-actions", { replace: true });
            dispatch(
                addNotification({
                    title: "Successfully deleted",
                    desc: "Credit action successfully deleted",
                    messageType:
                        NotificationMessageType.ADMIN_CREDIT_ACTION_DELETE,
                    type: NotificationType.SUCCESS,
                }),
            );
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_CREDIT_ACTION_DELETE,
            );
        }
    }, [dispatch, creditActionId, navigate, handleError]);

    return (
        <AdminFormContainer
            navigationItems={creditActionFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle="Credit Action"
        >
            <Formik
                initialValues={initialValues}
                validationSchema={creditActionSchema}
                validateOnChange={false}
                onSubmit={async values => {
                    await handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    submitForm,
                    setFieldValue,
                }) => (
                    <div className="flex min-h-[500px] flex-col gap-4">
                        {activeTab === "details" && (
                            <div className="grid grid-cols-8 items-end">
                                <div className="col-span-2 px-8 py-2">
                                    <InputLabel
                                        label="Code"
                                        htmlFor="code"
                                        required
                                        description="Unique identifier for the credit action. E.g. 'DOWNLOAD_DOCUMENT'. Cannot be changed once created."
                                    />
                                    {creditActionId ? (
                                        <ReadOnlyField
                                            label={""}
                                            value={values.code}
                                        />
                                    ) : (
                                        <Input
                                            type="text"
                                            name="code"
                                            id="code"
                                            value={values.code}
                                            formikChange={handleChange}
                                            error={errors.code}
                                        />
                                    )}
                                </div>
                                <div className="col-span-2 px-8 py-2">
                                    <InputLabel
                                        label="Name"
                                        htmlFor="name"
                                        required
                                        description="Internal name of the credit action. E.g. 'Download Document'"
                                    />
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={values.name}
                                        formikChange={handleChange}
                                        error={errors.name}
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Description"
                                        htmlFor="description"
                                        required
                                        description="Description of the credit action. E.g. 'Download a document'"
                                    />
                                    <Input
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={values.description}
                                        formikChange={handleChange}
                                        error={errors.description}
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Type"
                                        htmlFor="type"
                                        required
                                        description="Type of the credit action. E.g. 'EARN' or 'SPEND'"
                                    />
                                    <Select
                                        value={typeItems.find(
                                            item => item.id === values.type,
                                        )}
                                        items={typeItems}
                                        onChange={item =>
                                            setFieldValue("type", item.id)
                                        }
                                    />
                                    <InputError error={errors.type} />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Category"
                                        htmlFor="category"
                                        required
                                        description="Category of the credit action. E.g. 'FEATURE' or 'ENGAGEMENT'"
                                    />
                                    <Select
                                        value={categoryItems.find(
                                            item => item.id === values.category,
                                        )}
                                        items={categoryItems}
                                        onChange={item =>
                                            setFieldValue("category", item.id)
                                        }
                                    />
                                    <InputError error={errors.category} />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Amount Type"
                                        htmlFor="amountType"
                                        required
                                        description="Type of the amount. E.g. 'FIXED' or 'VARIABLE'"
                                    />
                                    <Select
                                        value={amountTypeItems.find(
                                            item =>
                                                item.id === values.amountType,
                                        )}
                                        items={amountTypeItems}
                                        onChange={item => {
                                            setFieldValue(
                                                "amountType",
                                                item.id,
                                            );
                                            if (item.id === "VARIABLE") {
                                                setFieldValue("credits", null);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Credits"
                                        htmlFor="credits"
                                        required={values.amountType === "FIXED"}
                                        description={
                                            values.amountType === "FIXED"
                                                ? "Number of credits to be awarded or deducted. Positive number for EARN, negative for SPEND"
                                                : "Disabled: Variable amounts are defined in the transaction"
                                        }
                                    />
                                    <Input
                                        type="number"
                                        name="credits"
                                        id="credits"
                                        value={
                                            values.amountType === "FIXED"
                                                ? (values.credits ?? "")
                                                : ""
                                        }
                                        formikChange={handleChange}
                                        error={errors.credits}
                                        disabled={values.amountType !== "FIXED"}
                                        onWheel={e => e.currentTarget.blur()}
                                    />
                                </div>
                                {/* <div className="col-span-2 mt-2 px-8 py-2">
                                    <InputLabel
                                        label="Cooldown Period (seconds)"
                                        htmlFor="cooldownPeriod"
                                        description="Cooldown period in seconds. E.g. '10' for 10 seconds"
                                    />
                                    <Input
                                        type="number"
                                        name="cooldownPeriod"
                                        id="cooldownPeriod"
                                        value={values.cooldownPeriod}
                                        formikChange={handleChange}
                                        error={errors.cooldownPeriod}
                                    />
                                </div> */}
                                <div className="col-span-2 mb-2 px-8">
                                    <Checkbox
                                        id="isActive"
                                        label="Is Active?"
                                        checked={!!values.isActive}
                                        onChange={(checked: boolean) =>
                                            setFieldValue("isActive", checked)
                                        }
                                    />
                                </div>
                                <div className="col-span-4 mb-2 mt-2 px-8">
                                    <InputLabel
                                        label="Metadata"
                                        htmlFor="metadata"
                                        required
                                        description="Metadata for the action in JSON format."
                                    />
                                    <InlineCodeEditor
                                        language="json"
                                        value={
                                            typeof values.metadata === "string"
                                                ? values.metadata
                                                : JSON.stringify(
                                                      values.metadata,
                                                  )
                                        }
                                        onChange={value =>
                                            setFieldValue("metadata", value)
                                        }
                                    />
                                    <InputError
                                        error={JSON.stringify(errors.metadata)}
                                    />
                                </div>
                            </div>
                        )}
                        <AdminFormFooter
                            onSave={submitForm}
                            listHref="/admin/credit-actions"
                            onDelete={handleDelete}
                        />
                    </div>
                )}
            </Formik>
        </AdminFormContainer>
    );
};

import { useCallback, useMemo } from "react";
import {
    smartScribeQuickPromptOptions,
    smartScribeToolsOptions,
} from "@/components/AiToolbox/constants";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import { SmartScribe } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getAiToolboxPromptActionCode } from "@/store/app/selectors";
import { showAiToolbox } from "@/store/app/slice";
import { CreditActionCode } from "@/types/creditAction";

interface AiToolboxHeaderProps {
    subtitle?: string;
}

export const AiToolboxHeader = ({ subtitle }: AiToolboxHeaderProps) => {
    const dispatch = useAppDispatch();
    const promptActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const onFeatureChange = useCallback(
        (promptActionCode: CreditActionCode) => {
            dispatch(showAiToolbox({ promptActionCode }));
        },
        [dispatch],
    );

    const allOptions = useMemo(
        () => [...smartScribeToolsOptions, ...smartScribeQuickPromptOptions],
        [],
    );

    const activeOption = allOptions.find(
        option => option.id === promptActionCode,
    );

    return (
        <div className="bg-gradient-primary flex items-center justify-between rounded-t-lg border-b-2 border-neutral-300 px-2 py-3 text-neutral-50">
            <div className="flex items-center gap-2">
                <SmartScribe className="h-6 w-6" />
                {subtitle ? (
                    <div className="max-w-[400px]">
                        <AppText
                            variant="regular"
                            className="truncate"
                        >
                            {subtitle}
                        </AppText>
                    </div>
                ) : (
                    <AppText variant="contextheading">Smart Scribe</AppText>
                )}
            </div>
            <div className="flex items-center gap-2">
                <Dropdown
                    items={allOptions}
                    activeId={promptActionCode || ""}
                    title={activeOption?.title || ""}
                    handleChange={id => {
                        onFeatureChange?.(id as CreditActionCode);
                    }}
                    className="w-[200px] text-primary-700"
                    size="small"
                    borderless
                    hideActiveId={!!promptActionCode}
                    leftIcon={activeOption?.icon}
                />
            </div>
        </div>
    );
};

import { AIModel } from "@/pages/Admin/AI/AiDashboard";

interface PromptInputTokenEstimationProps {
    generatedPrompt: string;
    systemPrompt: string;
    model: AIModel;
    getTokenCost: (model: AIModel) => {
        prompt: number;
        completion: number;
    };
}

const estimateTokens = (text: string) => {
    // Common patterns that affect tokenization
    const words = text.split(/\s+/).length; // Spaces often mark token boundaries
    const numbers = (text.match(/\d+/g) || []).length; // Numbers often tokenize as individual digits
    const specialChars = (text.match(/[^a-zA-Z0-9\s]/g) || []).length; // Special characters often get their own tokens

    // Base calculation using words as primary metric
    // Add adjustments for numbers and special characters
    return Math.ceil(words * 1.3 + numbers * 0.4 + specialChars * 0.3);
};

export const PromptInputTokenEstimation = ({
    generatedPrompt,
    systemPrompt,
    model,
    getTokenCost,
}: PromptInputTokenEstimationProps) => {
    const promptTokens = estimateTokens(generatedPrompt);
    const systemTokens = estimateTokens(systemPrompt);
    const totalTokens = promptTokens + systemTokens;
    const tokenCost = getTokenCost(model).prompt * totalTokens;

    return (
        <div className="col-span-1 flex items-center justify-between rounded-lg bg-gray-100 p-4">
            <div className="flex flex-col items-center space-y-2 border-r pr-4">
                <span className="text-xs font-semibold text-neutral-600">
                    Characters
                </span>
                <span className="text-lg font-bold text-primary-600">
                    {generatedPrompt.length + systemPrompt.length}
                </span>
            </div>
            <div className="flex flex-col items-center space-y-2 border-r px-4">
                <span className="flex items-center gap-1 text-xs font-semibold text-neutral-600">
                    Est. Tokens
                </span>
                <span className="text-lg font-bold text-primary-600">
                    ~{totalTokens}
                </span>
            </div>
            <div className="flex flex-col items-center space-y-2 pl-4">
                <span className="text-xs font-semibold text-neutral-600">
                    Est. Input Cost
                </span>
                <span className="text-lg font-bold text-primary-600">
                    ${tokenCost.toFixed(4)}
                    <span className="text-xs font-normal text-neutral-500">
                        {" "}
                        AUD
                    </span>
                </span>
            </div>
        </div>
    );
};

import { apiRequest } from "./request";

interface EmailData {
    html: string;
    to: string;
    subject: string;
    attachment?: File | Blob;
    fileName?: string;
}

const sendEmail = (data: EmailData) => {
    const formData = new FormData();
    const { attachment, ...bodyData } = data;

    formData.append("body", JSON.stringify(bodyData));

    if (attachment) {
        const filename = data.fileName || "Resume.pdf";
        formData.append("attachment", attachment, filename);
    }

    return apiRequest("/email", "POST", formData, {
        "Content-Type": "multipart/form-data"
    });
};

export default {
    sendEmail,
};

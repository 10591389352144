import { Resume, User } from "@/types";
import { apiRequest } from "./request";

export interface StatsRequest {
    startDate: string | null;
    endDate: string | null;
    userId: string;
}

const getAllResumes = (params: StatsRequest) => apiRequest<Resume[]>(`/stats/resumes`, "GET", params);

const getAllUsers = (params: StatsRequest) => apiRequest<User[]>(`/stats/users`, "GET", params);

const getCreditTransactionAnalytics = (params: StatsRequest) => apiRequest(`/stats/transactions`, "GET", params);

const getResumesPerUser = (params: StatsRequest) => apiRequest(`/stats/resumes-per-user`, "GET", params);

const getDocumentLimitStats = (params: StatsRequest) => apiRequest(`/stats/document-limit`, "GET", params);

export default {
    getAllResumes,
    getAllUsers,
    getCreditTransactionAnalytics,
    getDocumentLimitStats,
    getResumesPerUser,
};

import { InfoTooltip } from "@/components/InfoTooltip/InfoTooltip";
import { getSummarySectionPreviewText } from "@/pages/CreateResume/getSummarySectionPreviewText";
import {
    experienceLevels,
    industries,
    jobRoles,
} from "@/pages/Preferences/constants";
import { CreditActionCode } from "@/types/creditAction";

export type AIModel = "gpt-4o-mini" | "gpt-3.5-turbo";

const sectionOptions = ["Projects", "Education", "Work Experience"];
const writePrompts = [
    {
        label: "Rewrite",
        value: CreditActionCode.AI_PROMPT_REWRITE,
    },
    {
        label: "Shorten",
        value: CreditActionCode.AI_PROMPT_SHORTEN,
    },
    {
        label: "Formal",
        value: CreditActionCode.AI_PROMPT_FORMAL,
    },
    {
        label: "Grammar",
        value: CreditActionCode.AI_PROMPT_GRAMMAR,
    },
];

const getContextPlaceholder = (activeTab: string) => {
    switch (activeTab) {
        case "ai-summary":
            return "Additional content a user could enter, such as an already written summary or a list of keywords.";
        case "ai-bullet-points":
            return "Content the user would enter. This could be a list of bullet points to summarise impact, roles and responsibilities, or a list of keywords to summarise the user's skills.";
        case "ai-quick-prompts":
            return "Content the user would highlight within the resume.";
        default:
            return "";
    }
};

interface AIModelConfig {
    model: AIModel;
    temperature: number;
}

interface AIPreferencesConfigProps {
    toggleJobRole: (jobRole: string) => void;
    toggleIndustry: (industry: string) => void;
    setSelectedExperience: (experience: string) => void;
    setContext: (context: string) => void;
    resetJobRoles: () => void;
    resetIndustries: () => void;
    resetExperience: () => void;
    selectedJobRoles: string[];
    selectedIndustries: string[];
    selectedExperience: string;
    additionalContext: string;
    modelConfig: AIModelConfig;
    setModelConfig: (modelConfig: AIModelConfig) => void;
    sectionName: string;
    setSectionName: (sectionName: string) => void;
    activeTab: string;
    setWritePrompt: (writePrompt: CreditActionCode) => void;
    writePrompt: CreditActionCode;
}

export const AIPreferencesConfig = ({
    toggleJobRole,
    toggleIndustry,
    setSelectedExperience,
    resetJobRoles,
    resetIndustries,
    resetExperience,
    setContext,
    selectedJobRoles,
    selectedIndustries,
    selectedExperience,
    additionalContext,
    modelConfig,
    setModelConfig,
    sectionName,
    setSectionName,
    activeTab,
    setWritePrompt,
    writePrompt,
}: AIPreferencesConfigProps) => {
    const jobRolesList = jobRoles.map(({ value }) => value);
    const experienceLevelsList = experienceLevels.map(({ value }) => value);
    const industriesList = industries.map(({ value }) => value);
    const onUseExampleSummary = () => {
        setContext(getSummarySectionPreviewText([], true));
    };
    return (
        <div className="col-span-4 space-y-2">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            Model
                        </label>
                        <select
                            value={modelConfig.model}
                            onChange={e =>
                                setModelConfig({
                                    ...modelConfig,
                                    model: e.target.value as AIModel,
                                })
                            }
                            className="w-full rounded-lg border border-gray-300 p-2 text-sm"
                        >
                            <option value="gpt-4o-mini">GPT-4o Mini</option>
                            {/* <option value="gpt-3.5-turbo">
                                        GPT-3.5 Turbo
                                    </option> */}
                        </select>
                    </div>

                    <div className="space-y-2">
                        <div className="flex items-center space-x-1">
                            <span>Temperature</span>
                            <InfoTooltip
                                content="Controls randomness in the output. Lower values (0.0) make the output more focused, while higher values (1.0) make it more creative. Normal ChatGPT is 0.7."
                                position="bottom"
                            />
                        </div>
                        <input
                            type="number"
                            value={modelConfig.temperature}
                            onChange={e =>
                                setModelConfig({
                                    ...modelConfig,
                                    temperature: parseFloat(e.target.value),
                                })
                            }
                            step="0.1"
                            min="0"
                            max="1"
                            className="w-full rounded-lg border border-gray-300 p-2 text-sm"
                        />
                    </div>
                </div>
            </div>

            <div className="space-y-2">
                <div>
                    <div className="mb-2 flex items-center justify-between">
                        <h3 className="text-lg font-semibold">
                            Additional Context
                        </h3>
                        <button
                            onClick={() => setContext("")}
                            className="text-xs text-gray-500 hover:text-gray-700"
                        >
                            Reset
                        </button>
                    </div>
                    <textarea
                        value={additionalContext}
                        onChange={e => setContext(e.target.value)}
                        className="w-full rounded-lg border border-gray-300 p-2 text-xs"
                        rows={6}
                        placeholder={getContextPlaceholder(activeTab)}
                    />
                    {activeTab === "ai-summary" && (
                        <button
                            onClick={onUseExampleSummary}
                            className="rounded-md bg-primary-700 px-4 py-2 text-sm font-medium text-white hover:bg-primary-900"
                        >
                            Use Example Summary
                        </button>
                    )}
                </div>

                {activeTab === "ai-bullet-points" && (
                    <div>
                        <div className="mb-2 flex items-center justify-between">
                            <h3 className="text-lg font-semibold">
                                Section Name
                            </h3>
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {sectionOptions.map(option => (
                                <ToggleButton
                                    key={option}
                                    label={option}
                                    isSelected={sectionName === option}
                                    onClick={() => setSectionName(option)}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {activeTab === "ai-quick-prompts" && (
                    <div className="space-y-2">
                        <div className="mb-2 flex items-center justify-between">
                            <h3 className="text-lg font-semibold">
                                Write Prompt
                            </h3>
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {writePrompts.map(prompt => (
                                <ToggleButton
                                    key={prompt.value}
                                    label={prompt.label}
                                    isSelected={writePrompt === prompt.value}
                                    onClick={() => setWritePrompt(prompt.value)}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {activeTab === "ai-summary" && (
                    <div className="space-y-2">
                        <div>
                            <div className="mb-2 flex items-center justify-between">
                                <h3 className="text-lg font-semibold">
                                    Job Roles
                                </h3>
                                <button
                                    onClick={resetJobRoles}
                                    className="text-xs text-gray-500 hover:text-gray-700"
                                >
                                    Reset
                                </button>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {jobRolesList.map(role => (
                                    <ToggleButton
                                        key={role}
                                        label={role}
                                        isSelected={selectedJobRoles.includes(
                                            role,
                                        )}
                                        onClick={() => toggleJobRole(role)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div>
                            <div className="mb-2 flex items-center justify-between">
                                <h3 className="text-lg font-semibold">
                                    Experience Levels
                                </h3>
                                <button
                                    onClick={resetExperience}
                                    className="text-xs text-gray-500 hover:text-gray-700"
                                >
                                    Reset
                                </button>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {experienceLevelsList.map(level => (
                                    <ToggleButton
                                        key={level}
                                        label={level}
                                        isSelected={
                                            selectedExperience === level
                                        }
                                        onClick={() =>
                                            setSelectedExperience(
                                                selectedExperience === level
                                                    ? ""
                                                    : level,
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </div>

                        <div>
                            <div className="mb-2 flex items-center justify-between">
                                <h3 className="text-lg font-semibold">
                                    Industries
                                </h3>
                                <button
                                    onClick={resetIndustries}
                                    className="text-xs text-gray-500 hover:text-gray-700"
                                >
                                    Reset
                                </button>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {industriesList.map(industry => (
                                    <ToggleButton
                                        key={industry}
                                        label={industry}
                                        isSelected={selectedIndustries.includes(
                                            industry,
                                        )}
                                        onClick={() => toggleIndustry(industry)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

interface ToggleButtonProps {
    label: string;
    isSelected: boolean;
    onClick: () => void;
}

const ToggleButton = ({ label, isSelected, onClick }: ToggleButtonProps) => {
    return (
        <button
            onClick={onClick}
            className={`rounded-full border px-2 py-0.5 text-xs ${
                isSelected
                    ? "bg-primary-600 text-white"
                    : "bg-white text-neutral-700"
            }`}
        >
            {label}
        </button>
    );
};

import clsx from "clsx";
import { ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";
import { ButtonColour, ButtonVariant } from "@/components/Button/Button";
import {
    buttonColours,
    buttonSharedStyles,
    buttonVariantStyles,
} from "@/components/Button/buttonStyles";
import { InlineLoader } from "@/components/Icons/Icons";
import { UsingReactChildren } from "@/types";

const getIconButtonStyles = () =>
    ` !p-0 outline-none focus:outline-none cursor-pointer group inline-flex items-center justify-center rounded-lg`;

interface IconButtonProps extends UsingReactChildren {
    variant?: ButtonVariant;
    color?: ButtonColour;
    className?: string;
    to?: string;
    onClick?: (e?: React.MouseEvent) => void;
    disabled?: boolean;
    size?: string;
    notificationDot?: boolean;
    notificationDotColor?: string;
    loading?: boolean;
    chevronDirection?: "up" | "down";
}

export const IconButton = ({
    children,
    variant = "solid",
    color = "primary",
    className,
    onClick,
    disabled,
    size = "36px",
    notificationDot = false,
    notificationDotColor = "bg-error-500",
    to,
    loading = false,
    chevronDirection,
    ...props
}: IconButtonProps) => {
    const buttonClassName = clsx(
        buttonSharedStyles,
        buttonVariantStyles[variant],
        buttonColours[variant][color],
        getIconButtonStyles(),
        className,
    );

    const content = (
        <div className="relative flex flex-row items-center gap-[2px]">
            {children}
            {notificationDot && (
                <div
                    className={`absolute -right-[13px] -top-2 h-2 w-2 rounded-full ${notificationDotColor}`}
                />
            )}
            {chevronDirection && (
                <ChevronUp
                    size={12}
                    className={clsx(
                        chevronDirection === "up" ? "rotate-180" : "",
                        "transition-transform duration-100",
                    )}
                />
            )}
        </div>
    );

    return onClick ? (
        <button
            type="button"
            className={clsx(className, buttonClassName)}
            onClick={onClick}
            disabled={disabled}
            style={{
                height: size,
                width: size,
            }}
            {...props}
        >
            {loading ? <InlineLoader /> : content}
        </button>
    ) : to ? (
        <Link
            to={to}
            className={clsx(className, buttonClassName)}
            style={{
                height: size,
                width: size,
            }}
            {...props}
        >
            {loading ? <InlineLoader /> : content}
        </Link>
    ) : (
        <div
            className={clsx(className, buttonClassName)}
            {...props}
            style={{
                height: size,
                width: size,
            }}
        >
            {loading ? <InlineLoader /> : content}
        </div>
    );
};

import { useEffect, useState } from "react";
import { Button } from "@/components/Button/Button";
import { CreditsIcon } from "@/components/Icons/Icons";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { getUserDetails } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { formatCreditAmount } from "@/utils/string";

export const BalanceIndicator = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUserDetails);
    const balance = user?.credits?.balance || 0;
    const [localBalance, setLocalBalance] = useState(balance);

    const handleClick = () => {
        dispatch(openModal({ modalType: ModalTypes.BUY_CREDITS }));
    };

    useEffect(() => {
        setLocalBalance(balance);
    }, [balance]);

    return (
        <>
            <Button
                color="gradient"
                variant="outline"
                onClick={handleClick}
                leftIcon={<CreditsIcon />}
            >
                {formatCreditAmount(localBalance)}
            </Button>
        </>
    );
};

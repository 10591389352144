import {
    Coins,
    Download,
    ExternalLink,
    Files,
    WandSparkles,
} from "lucide-react";
import { useMemo } from "react";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsModalOpen } from "@/store/modal/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { SiteRoutes } from "@/types/routes";
import { formatCreditAmount } from "@/utils/string";

const CreditsInfoModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.CREDITS_INFO));
    const { getCreditActionByCode } = useTransaction();
    const additionalResumeAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.INCREASE_DOCUMENT_LIMIT),
        [getCreditActionByCode],
    );
    const rewriteAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.AI_PROMPT_REWRITE),
        [getCreditActionByCode],
    );
    const downloadAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.DOWNLOAD_DOCUMENT),
        [getCreditActionByCode],
    );

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.CREDITS_INFO}
            noHeader
            width="2xl"
            gradientBackground
            title=""
            floatingCloseButton
        >
            <div className="flex flex-col items-center">
                <div className="mb-2 text-primary-700">
                    <Coins size={55} />
                </div>

                <AppText
                    variant="headings"
                    className="mb-4 w-full text-center text-2xl font-bold text-primary-900 lg:w-2/3"
                >
                    WHAT ARE CREDITS?
                </AppText>

                <AppText
                    variant="labelsbuttons"
                    className="mb-4 w-full text-center text-neutral-700 lg:w-2/3"
                >
                    Credits let you access more features and download your
                    resume as you need them.
                </AppText>

                <a
                    href={SiteRoutes.Credits}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mb-4 flex justify-center gap-2 text-primary-700 hover:underline"
                >
                    <ExternalLink
                        size={16}
                        strokeWidth={3}
                    />
                    <AppText variant="labelsbuttons">
                        Learn more about Credits
                    </AppText>
                </a>

                <div className="mt-4 grid grid-cols-1 gap-8 px-2 lg:w-full lg:grid-cols-3">
                    {additionalResumeAction && (
                        <FeatureCard
                            icon={<Files size={19} />}
                            title="MULTIPLE RESUMES"
                            description="Create specialised resumes for different jobs"
                            creditLine={`${formatCreditAmount(additionalResumeAction?.credits ?? 0, true, true)} Per Resume`}
                        />
                    )}
                    {rewriteAction && (
                        <FeatureCard
                            icon={<WandSparkles size={19} />}
                            title="SMART SCRIBE"
                            description="AI tools and features to build better resumes"
                            creditLine={`From ${formatCreditAmount(rewriteAction?.credits ?? 0, true, true)} Per Use`}
                        />
                    )}
                    {downloadAction && (
                        <FeatureCard
                            icon={<Download size={19} />}
                            title="DOWNLOAD RESUMES"
                            description="Download your resume as PDFs for applications"
                            creditLine={`${formatCreditAmount(downloadAction?.credits ?? 0, true, true)} Per Download`}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

interface FeatureCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    creditLine: string;
}

const FeatureCard = ({
    icon,
    title,
    description,
    creditLine,
}: FeatureCardProps) => {
    return (
        <div className="flex flex-col items-center">
            <div className="bg-primary mb-3 flex h-8 w-8 items-center justify-center rounded-lg bg-primary-700 text-white">
                {icon}
            </div>
            <AppText
                variant="labelsbuttons"
                className="mb-2 text-center font-semibold text-neutral-900"
            >
                {title}
            </AppText>
            <AppText
                variant="regular"
                className="mb-3 text-center text-neutral-900"
            >
                {description}
            </AppText>
            <AppText
                variant="footnote"
                className="bg-primary rounded-lg bg-primary-700 px-2 py-[2px] !font-semibold text-white"
            >
                {creditLine}
            </AppText>
        </div>
    );
};

export default CreditsInfoModal;

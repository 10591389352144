import Chip from "@/components/Chip/Chip";
import { AppText } from "@/components/Text/AppText";
import { pluralise } from "@/utils/string";

interface CreditsChipProps {
    credits: number;
    type: "primary" | "secondary" | "gradient";
    onClick?: () => void;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    borderless?: boolean;
}

export const CreditsChip = ({
    credits,
    type,
    onClick,
    leftIcon,
    rightIcon,
    borderless,
}: CreditsChipProps) => (
    <Chip
        type={type}
        onClick={onClick}
        borderless={borderless}
    >
        {leftIcon}
        <AppText variant="labelsbuttons">
            {credits} {pluralise(credits, "Credit", "Credits")}
        </AppText>
        {rightIcon}
    </Chip>
);

// src/components/InfoTooltip.tsx
import { useState } from "react";

interface InfoTooltipProps {
    content: string;
    position?: "top" | "bottom" | "left" | "right";
}

export const InfoTooltip = ({
    content,
    position = "top",
}: InfoTooltipProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const positionClasses = {
        top: "bottom-full left-1/2 -translate-x-1/2 mb-2",
        bottom: "top-full left-1/2 -translate-x-1/2 mt-2",
        left: "right-full top-1/2 -translate-y-1/2 mr-2",
        right: "left-full top-1/2 -translate-y-1/2 ml-2",
    };

    return (
        <div className="relative inline-block">
            <div
                className="cursor-help"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-4 w-4 text-primary-700 hover:text-primary-900"
                >
                    <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            {isVisible && (
                <div
                    className={`absolute z-10 w-48 rounded-md bg-primary-900 px-2 py-1.5 text-xs text-white shadow-lg ${positionClasses[position]}`}
                    role="tooltip"
                >
                    {content}
                    <div
                        className={`absolute ${
                            position === "top"
                                ? "bottom-[-4px] left-1/2 -translate-x-1/2 border-t-4"
                                : position === "bottom"
                                  ? "left-1/2 top-[-4px] -translate-x-1/2 border-b-4"
                                  : position === "left"
                                    ? "right-[-4px] top-1/2 -translate-y-1/2 border-l-4"
                                    : "left-[-4px] top-1/2 -translate-y-1/2 border-r-4"
                        } border-4 border-gray-900 border-transparent`}
                    />
                </div>
            )}
        </div>
    );
};

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../../Modal/Modal";
import { Alert } from "@/components/Alert/Alert";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { LaunchPromotion } from "@/components/Plans/LaunchPromotion";
import { Plans } from "@/components/Plans/Plans";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { getShowPlansModal } from "@/store/app/selectors";
import { setShowPlansModal } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import {
    getUserActivePlanName,
    getUserPlanStatusIssue,
} from "@/store/user/selectors";
import { ModalWidth } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import {
    CLARITY_EVENT_NAMES,
    GA_EVENT_ACTIONS,
    GA_EVENT_CATEGORIES,
    META_CUSTOM_EVENT_ACTIONS,
    META_EVENT_ACTIONS,
} from "@/types/tracking";
import {
    trackClarityEvent,
    trackGAEvent,
    trackMetaCustomEvent,
    trackMetaEvent,
} from "@/utils/tracking";

export const PlansModal = () => {
    const openReason = useAppSelector(getShowPlansModal);
    const activePlan = useAppSelector(getUserActivePlanName);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [portalUrl, setPortalUrl] = useState<string | null>(null);
    const planStatusIssue = useAppSelector(getUserPlanStatusIssue);
    const { createCustomerPortalSession, getAndUpdateUserPlan } = useStripe();
    const [loading, setLoading] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const lastModalWidthRef = useRef<ModalWidth>("4xl");
    const getUserPlan = useCallback(async () => {
        return await getAndUpdateUserPlan();
    }, [getAndUpdateUserPlan]);

    useEffect(() => {
        if (planStatusIssue) {
            const returnUrl = `${window.location.origin}${window.location.pathname}?source=${openReason}&refresh=true`;
            createCustomerPortalSession(returnUrl).then(setPortalUrl);
        }
    }, [planStatusIssue, createCustomerPortalSession, openReason]);

    useEffect(() => {
        if (openReason) {
            dispatch(openModal({ modalType: ModalTypes.PLANS }));
        }
    }, [dispatch, openReason]);

    useEffect(() => {
        const source = searchParams.get("source");
        const res = searchParams.get("res"); // either success or cancel
        const shouldRefresh = searchParams.get("refresh") === "true";
        let timeout: NodeJS.Timeout;
        if (source && res !== "success") {
            dispatch(
                setShowPlansModal(source as "download" | "upgrade" | "preview"),
            );

            if (shouldRefresh) {
                setLoading(true);
                getUserPlan().then(plan => {
                    setLoading(false);
                    if (plan?.isSubscribed) {
                        timeout = setTimeout(() => {
                            setSearchParams({}, { replace: true });
                            if (source === "preview") {
                                // Trigger a hard reload to ensure we get the latest plan and document details
                                window.location.reload();
                            }
                        }, 2500);
                    }
                });
            }
            trackGAEvent(
                GA_EVENT_CATEGORIES.STRIPE,
                GA_EVENT_ACTIONS.SUBMIT,
                res === "success" ? "subscribe-success" : "subscribe-canceled",
            );

            if (res === "success") {
                trackClarityEvent(CLARITY_EVENT_NAMES.SUBSCRIPTION_SUCCESS);
            }

            if (res === "cancel") {
                trackMetaCustomEvent(META_CUSTOM_EVENT_ACTIONS.CANCEL_PURCHASE);
                trackClarityEvent(CLARITY_EVENT_NAMES.SUBSCRIPTION_CANCELED);
            }
            setSearchParams({}, { replace: true });
        }
        return () => clearTimeout(timeout);
    }, [searchParams, dispatch, setSearchParams, getUserPlan]);

    const modalWidth = useMemo(() => {
        if (isClosing) {
            return lastModalWidthRef.current;
        }

        let newWidth: ModalWidth = "7xl";
        if (planStatusIssue) {
            newWidth = "md";
        }

        lastModalWidthRef.current = newWidth;
        return newWidth;
    }, [planStatusIssue, isClosing]);

    const modalTitle = useMemo(() => {
        if (planStatusIssue) {
            return "Inactive Plan";
        }
        if (openReason === "download") {
            return "Choose a plan.";
        }
        return "Upgrade";
    }, [openReason, planStatusIssue]);

    return (
        <Modal
            modalType={ModalTypes.PLANS}
            open={!!openReason}
            onClose={() => {
                setIsClosing(true);
                dispatch(setShowPlansModal(""));
            }}
            afterClose={() => {
                setIsClosing(false);
            }}
            title={modalTitle}
            width={modalWidth as ModalWidth}
            mobileFullScreen
        >
            {loading ? (
                <div className="flex min-h-40 items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    <div className="flex flex-col gap-4">
                        {planStatusIssue ? (
                            <Alert
                                type="error"
                                actionLabel="Update billing information"
                                onAction={() => {
                                    window.location.href = portalUrl || "#";
                                }}
                                message={
                                    <AppText variant="labelsbuttons">
                                        There&apos;s an issue with your
                                        subscription. Please update your payment
                                        details.
                                    </AppText>
                                }
                            />
                        ) : (
                            <>
                                <AppText
                                    variant="contextheading"
                                    className="text-neutral-900"
                                >
                                    {openReason === "download"
                                        ? "You need to upgrade to a plan to download resumes."
                                        : "Select a plan to get full access and download resumes."}
                                </AppText>
                                <LaunchPromotion />
                                <Plans
                                    activePlan={activePlan}
                                    source={openReason}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </Modal>
    );
};

import { motion, AnimatePresence } from "framer-motion";
import { XIcon, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsAdminUser } from "@/store/user/selectors";
import { CreditActionCode } from "@/types/creditAction";

interface AdminControlPanelProps {
    children: React.ReactNode;
    topPosition?: number;
}

/**
 * The purpose of this component is to provide a panel for admin controls.
 * It can be placed anywhere and will house specific controls that are only available to admin users.
 */
export const AdminControlPanel = ({
    children,
    topPosition = 12,
}: AdminControlPanelProps) => {
    const isAdminUser = useAppSelector(getIsAdminUser);
    const [isOpen, setIsOpen] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [creditsToAdd, setCreditsToAdd] = useState(5);
    const [isAddingCredits, setIsAddingCredits] = useState(false);
    const { validateAndAddTransaction, getCreditActionByCode } =
        useTransaction();
    const adminAdjustmentAction = getCreditActionByCode(
        CreditActionCode.ADMIN_ADJUSTMENT,
    );

    const addCredits = async () => {
        if (!adminAdjustmentAction) return;
        setIsAddingCredits(true);
        try {
            await validateAndAddTransaction({
                actionCode: adminAdjustmentAction.code,
                amount: creditsToAdd,
            });
        } catch (error) {
            console.error("Error adding credits:", error);
        } finally {
            setIsAddingCredits(false);
        }
    };

    if (!isAdminUser || !isOpen) {
        return null;
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ x: "100%" }}
                    animate={{ x: isExpanded ? 0 : "calc(100% - 2px)" }}
                    exit={{ x: "100%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="fixed right-4 z-10 flex"
                    style={{ top: topPosition }}
                >
                    <motion.button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="flex h-6 w-5 items-center justify-center rounded-l-lg bg-primary-900 text-neutral-50 hover:bg-primary-700"
                    >
                        {isExpanded ? (
                            <ChevronRightIcon size={15} />
                        ) : (
                            <ChevronLeftIcon size={15} />
                        )}
                    </motion.button>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="flex flex-col rounded-b-lg rounded-r-lg bg-white shadow-lg ring-2 ring-inset ring-primary-900"
                        style={{ maxHeight: "calc(100vh - 100px)" }}
                    >
                        <div className="flex h-6 items-center justify-between  bg-primary-900 px-2">
                            <AppText
                                variant="labelsbuttons"
                                className="mr-2 text-neutral-50"
                            >
                                Admin Controls
                            </AppText>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => setIsOpen(false)}
                            >
                                <XIcon className="h-3 w-3 text-neutral-50 hover:text-neutral-100" />
                            </motion.button>
                        </div>
                        <div className="flex-grow overflow-y-auto p-4">
                            {children}
                            <div className="mt-2 flex flex-col justify-center border-t-2 border-neutral-300 pt-2">
                                <input
                                    type="number"
                                    className="w-full border border-neutral-300 p-2 text-xs"
                                    value={creditsToAdd}
                                    onChange={e =>
                                        setCreditsToAdd(Number(e.target.value))
                                    }
                                />
                                <Button
                                    variant="solid"
                                    color="gradient"
                                    size="small"
                                    className="mt-2"
                                    onClick={addCredits}
                                    loading={isAddingCredits}
                                >
                                    Add/Remove Credits
                                </Button>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

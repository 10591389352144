import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../../Modal/Modal";
import { CreditActionSummary } from "@/components/Credits/CreditActionSummary";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { addNotification } from "@/store/app/slice";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import { getUserDocumentLimit } from "@/store/user/selectors";
import { NotificationMessageType, NotificationType } from "@/types/app";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { formatCreditAmount, pluralise } from "@/utils/string";
import { trackClarityEvent } from "@/utils/tracking";

const DocumentLimitModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.DOCUMENT_LIMIT));
    const documentLimit = useAppSelector(getUserDocumentLimit);
    const dispatch = useAppDispatch();
    const [isUnlocking, setIsUnlocking] = useState(false);
    const { validateAndAddTransaction, getCreditActionByCode } =
        useTransaction();
    const increaseDocumentLimitAction = getCreditActionByCode(
        CreditActionCode.INCREASE_DOCUMENT_LIMIT,
    );

    const actionCost = useMemo(() => {
        return Math.abs(Number(increaseDocumentLimitAction?.credits)) ?? 0;
    }, [increaseDocumentLimitAction]);

    const handleUnlock = useCallback(async () => {
        setIsUnlocking(true);
        try {
            await validateAndAddTransaction({
                actionCode: CreditActionCode.INCREASE_DOCUMENT_LIMIT,
            });

            dispatch(closeModal(ModalTypes.DOCUMENT_LIMIT));
            dispatch(
                openModal({
                    modalType: ModalTypes.CREDITS_SUCCESS,
                    props: { mode: "document-limit" },
                }),
            );
        } catch (error) {
            console.error(error);
            dispatch(
                addNotification({
                    title: "Something went wrong",
                    desc: "Unable to increase document limit. Please refresh and try again.",
                    messageType: NotificationMessageType.CREDIT_ACTION_FAILED,
                    type: NotificationType.ERROR,
                }),
            );
        } finally {
            setIsUnlocking(false);
        }
    }, [dispatch, validateAndAddTransaction]);

    useEffect(() => {
        if (isOpen) {
            trackClarityEvent(CLARITY_EVENT_NAMES.RESUME_LIMIT_REACHED);
        }
    }, [isOpen]);

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.DOCUMENT_LIMIT}
            title="Increase Resume Limit"
            width="xl"
            footer={
                <ModalFooter
                    primaryActionText={`Add Resume Slot for ${actionCost} ${actionCost ? pluralise(actionCost, "credit", "credits") : 0}`}
                    primaryActionOnClick={handleUnlock}
                    primaryActionLoading={isUnlocking}
                />
            }
        >
            <div className="flex flex-col justify-center">
                <AppText
                    variant="regular"
                    className="mb-4"
                >
                    You can use Credits to increase how many resumes you can
                    create. You can currently create up to{" "}
                    <strong>{documentLimit} Resumes</strong>. An extra Resume is{" "}
                    <AppText
                        variant="regular"
                        applyGradient
                        className="inline-block !font-bold"
                    >
                        {formatCreditAmount(actionCost)}
                    </AppText>
                    .
                </AppText>
                {increaseDocumentLimitAction && (
                    <CreditActionSummary
                        creditAction={increaseDocumentLimitAction}
                        insufficientCreditsMessage="Purchase additional credits to increase your resume limit"
                        balanceAfterMessage="Balance after purchase:"
                    />
                )}
            </div>
        </Modal>
    );
};

export default DocumentLimitModal;

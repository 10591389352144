import {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TabTitle,
} from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import {
    AtSign,
    Diamond,
    DollarSign,
    FileIcon,
    HandCoins,
    PiggyBank,
    RefreshCwIcon,
    UserCog,
    UserPlus,
    Users,
} from "lucide-react";
import { useMemo, useState } from "react";
import {
    creditActionCodeColumns,
    documentLimitColumns,
    experienceLevelColumns,
    industryColumns,
    jobRoleColumns,
    jobTypeColumns,
    resumeLayoutColumns,
    resumesPerUserColumns,
} from "./tableDefinitions";
import statsApi, { StatsRequest } from "@/api/stats";
import { Button } from "@/components/Button/Button";
import { useAppSelector } from "@/hooks/types";
import { StatCard } from "@/pages/Admin/Stats/StatCard";
import { StatTable } from "@/pages/Admin/Stats/StatTable";
import { StatsContainer } from "@/pages/Admin/Stats/StatsContainer";
import {
    calculatePreferenceStats,
    calculateResumeLayoutStats,
} from "@/pages/Admin/Stats/helper";
import {
    jobRoles,
    industries,
    experienceLevels,
    jobTypes,
} from "@/pages/Preferences/constants";
import { getUserDetails } from "@/store/user/selectors";
import { Resume, User } from "@/types";

type CreditStat = {
    count: number;
    amount: number;
    codeBreakdown: Record<string, { count: number; amount: number }>;
};
interface CreditTransactionAnalytics {
    purchase: CreditStat;
    spend: CreditStat;
    earn: CreditStat;
    refund: CreditStat;
    signUp: CreditStat;
    admin: CreditStat;
}

const defaultCreditTransactionAnalytics: CreditTransactionAnalytics = {
    purchase: { count: 0, amount: 0, codeBreakdown: {} },
    spend: { count: 0, amount: 0, codeBreakdown: {} },
    earn: { count: 0, amount: 0, codeBreakdown: {} },
    refund: { count: 0, amount: 0, codeBreakdown: {} },
    signUp: { count: 0, amount: 0, codeBreakdown: {} },
    admin: { count: 0, amount: 0, codeBreakdown: {} },
};

const defaultResumesPerUser: Record<string, number> = {};
const defaultDocumentLimitStats: Record<string, number> = {};

interface QuickDateRange {
    label: string;
    getDates: () => { startDate: string; endDate: string };
}

const quickDateRanges: QuickDateRange[] = [
    {
        label: "Last 3 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 3);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "Last 7 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 7);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "Last Month",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth() - 1);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "All Time",
        getDates: () => ({
            startDate: "2020-01-01", // Adjust this date as needed
            endDate: new Date().toISOString().split("T")[0],
        }),
    },
];

const defaultQueryParams = {
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
};

export const StatsPage = () => {
    const userDetails = useAppSelector(getUserDetails);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>("totals");

    // Update submitted dates type
    const [submittedDates, setSubmittedDates] = useState<{
        startDate: string | null;
        endDate: string | null;
    }>({
        startDate: null,
        endDate: null,
    });

    const params: StatsRequest = useMemo(
        () => ({
            startDate: submittedDates.startDate,
            endDate: submittedDates.endDate,
            userId: userDetails?.id ?? "",
        }),
        [submittedDates, userDetails?.id],
    );

    const {
        data: allResumes = [],
        refetch: refetchResumes,
        isLoading: isResumesLoading,
    } = useQuery<Resume[]>({
        queryKey: ["allResumes"],
        queryFn: () => statsApi.getAllResumes(params),
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const {
        data: allUsers = [],
        refetch: refetchUsers,
        isLoading: isUsersLoading,
    } = useQuery<User[]>({
        queryKey: ["allUsers"],
        queryFn: () => statsApi.getAllUsers(params),
        ...defaultQueryParams,
        enabled: activeTab === "users" || activeTab === "totals",
    });

    const {
        data: creditsAnalytics = defaultCreditTransactionAnalytics,
        refetch: refetchCreditTransactionAnalytics,
        isLoading: isTransactionsLoading,
    } = useQuery<CreditTransactionAnalytics>({
        queryKey: ["creditsAnalytics"],
        queryFn: async () => {
            const result = await statsApi.getCreditTransactionAnalytics(params);
            return result as CreditTransactionAnalytics;
        },
        ...defaultQueryParams,
        enabled: activeTab === "transactions",
    });

    const {
        data: resumesPerUser = defaultResumesPerUser,
        refetch: refetchResumesPerUser,
    } = useQuery<Record<string, number>>({
        queryKey: ["resumesPerUser"],
        queryFn: () =>
            statsApi.getResumesPerUser(params) as Promise<
                Record<string, number>
            >,
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const {
        data: documentLimitStats = defaultDocumentLimitStats,
        refetch: refetchDocumentLimitStats,
    } = useQuery<Record<string, number>>({
        queryKey: ["documentLimitStats"],
        queryFn: () =>
            statsApi.getDocumentLimitStats(params) as Promise<
                Record<string, number>
            >,
        ...defaultQueryParams,
        enabled: activeTab === "resumes" || activeTab === "totals",
    });

    const isLoading =
        isResumesLoading || isUsersLoading || isTransactionsLoading;

    const handleRefresh = () => {
        if (activeTab === "totals") {
            refetchResumes();
            refetchUsers();
        } else if (activeTab === "transactions") {
            refetchCreditTransactionAnalytics();
        } else if (activeTab === "users") {
            refetchUsers();
            refetchResumesPerUser();
        } else if (activeTab === "resumes") {
            refetchResumes();
            refetchDocumentLimitStats();
        }
    };

    const handleSubmit = () => {
        setSubmittedDates({ startDate, endDate });
        setTimeout(() => {
            handleRefresh(); // Trigger the refresh after updating dates
        }, 300);
    };

    const handleQuickDateSelect = (range: QuickDateRange) => {
        const { startDate: newStartDate, endDate: newEndDate } =
            range.getDates();
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setSubmittedDates({ startDate: newStartDate, endDate: newEndDate });
        setTimeout(handleRefresh, 300);
    };

    const industryStats = useMemo(
        () => calculatePreferenceStats(allUsers, industries, "industries"),
        [allUsers],
    );

    const jobRoleStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobRoles, "jobRoles"),
        [allUsers],
    );

    const experienceLevelStats = useMemo(
        () =>
            calculatePreferenceStats(
                allUsers,
                experienceLevels,
                "experienceLevel",
            ),
        [allUsers],
    );

    const jobTypeStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobTypes, "jobTypes"),
        [allUsers],
    );

    const resumeLayoutStats = useMemo(
        () => calculateResumeLayoutStats(allResumes),
        [allResumes],
    );

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
                <div className="flex flex-wrap items-center gap-4">
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="start-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            Start Date
                        </label>
                        <input
                            type="date"
                            id="start-date"
                            value={startDate ?? ""}
                            onChange={e => setStartDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="end-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            End Date
                        </label>
                        <input
                            type="date"
                            id="end-date"
                            value={endDate ?? ""}
                            onChange={e => setEndDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <Button
                        onClick={handleSubmit}
                        disabled={isLoading || !startDate || !endDate}
                        loading={isLoading}
                    >
                        Apply
                    </Button>
                    <div className="flex flex-wrap gap-2">
                        {quickDateRanges.map(range => (
                            <Button
                                key={range.label}
                                variant={"outline"}
                                color={"grey"}
                                onClick={() => handleQuickDateSelect(range)}
                                disabled={isLoading}
                                className="!px-1 !py-1 !text-sm"
                            >
                                {range.label}
                            </Button>
                        ))}
                    </div>
                </div>
                <button
                    onClick={handleRefresh}
                    disabled={isLoading}
                    className="inline-flex items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                >
                    <RefreshCwIcon
                        className={`h-4 w-4 ${isLoading ? "animate-spin" : ""}`}
                    />
                    {isLoading ? "Refreshing..." : "Refresh"}
                </button>
            </div>

            <TabGroup
                selectedIndex={[
                    "totals",
                    "transactions",
                    "users",
                    "resumes",
                ].indexOf(activeTab)}
                onChange={index =>
                    setActiveTab(
                        ["totals", "transactions", "users", "resumes"][index],
                    )
                }
            >
                <TabList className="flex space-x-1 rounded-xl bg-gray-100 p-1">
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Totals
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Transactions
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Users
                    </Tab>
                    <Tab
                        className={({ selected }) =>
                            `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                        ${
                            selected
                                ? "bg-white text-gray-900 shadow"
                                : "text-gray-500 hover:bg-white/[0.12] hover:text-gray-900"
                        }`
                        }
                    >
                        Resumes
                    </Tab>
                </TabList>

                <TabPanels className="mt-4">
                    <TabPanel>
                        <StatsContainer title="Totals">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
                                <StatCard
                                    title="Resume Created"
                                    value={allResumes.length}
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                />
                                <StatCard
                                    title="Users Created"
                                    value={allUsers.length}
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="Email Users"
                                    value={
                                        allUsers.filter(
                                            user =>
                                                user.loginMethod === "email",
                                        ).length
                                    }
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="SSO Users"
                                    value={
                                        allUsers.filter(
                                            user =>
                                                user.loginMethod !== "email",
                                        ).length
                                    }
                                    icon={<Users size={16} />}
                                    loading={isUsersLoading}
                                />
                                <StatCard
                                    title="Opt-In Emails"
                                    value={
                                        allUsers.filter(user =>
                                            user.marketingOptIns.some(
                                                optIn =>
                                                    optIn.channel === "email",
                                            ),
                                        ).length
                                    }
                                    icon={<AtSign size={16} />}
                                    loading={isUsersLoading}
                                />
                            </div>
                            <div className="flex flex-wrap gap-6">
                                <StatCard
                                    title="Document Limit"
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                    className="col-span-2"
                                >
                                    <StatTable
                                        columns={documentLimitColumns}
                                        data={Object.entries(
                                            documentLimitStats,
                                        ).map(([documentLimit, count]) => ({
                                            documentLimit,
                                            count,
                                            percentage:
                                                (count / allUsers.length) * 100,
                                        }))}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="Transaction Event Type Counts">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-6">
                                <StatCard
                                    title="Purchase Count"
                                    value={`${creditsAnalytics.purchase.count}`}
                                    icon={<PiggyBank size={16} />}
                                />
                                <StatCard
                                    title="Spend Count"
                                    value={`${creditsAnalytics.spend.count}`}
                                    icon={<DollarSign size={16} />}
                                />
                                <StatCard
                                    title="Refunded Count"
                                    value={`${creditsAnalytics.refund.count}`}
                                    icon={<HandCoins size={16} />}
                                />
                                <StatCard
                                    title="Earned Count"
                                    value={`${creditsAnalytics.earn.count}`}
                                    icon={<Diamond size={16} />}
                                />
                                <StatCard
                                    title="Sign Up Count"
                                    value={`${creditsAnalytics.signUp.count}`}
                                    icon={<UserPlus size={16} />}
                                />
                                <StatCard
                                    title="Admin Count"
                                    value={`${creditsAnalytics.admin.count}`}
                                    icon={<UserCog size={16} />}
                                />
                            </div>
                        </StatsContainer>
                        <div className="flex flex-wrap gap-6">
                            <StatCard title="Credit Action Breakdown: PURCHASE">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.purchase
                                            .codeBreakdown || {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.purchase
                                                    .count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: SPEND">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.spend.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.spend.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: EARN">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.earn.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.earn.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: REFUND">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.refund.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.refund.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: SIGN UP">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.signUp.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.signUp.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                            <StatCard title="Credit Action Breakdown: ADMIN">
                                <StatTable
                                    columns={creditActionCodeColumns}
                                    data={Object.entries(
                                        creditsAnalytics.admin.codeBreakdown ||
                                            {},
                                    ).map(([code, { count, amount }]) => ({
                                        code,
                                        count,
                                        amount,
                                        percentage:
                                            (count /
                                                creditsAnalytics.admin.count) *
                                            100,
                                    }))}
                                />
                            </StatCard>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="User Preferences">
                            <div className="flex flex-wrap gap-6">
                                <StatCard title="Industries">
                                    <StatTable
                                        columns={industryColumns}
                                        data={industryStats}
                                    />
                                </StatCard>
                                <StatCard title="Job Roles">
                                    <StatTable
                                        columns={jobRoleColumns}
                                        data={jobRoleStats}
                                    />
                                </StatCard>
                                <StatCard title="Experience Levels">
                                    <StatTable
                                        columns={experienceLevelColumns}
                                        data={experienceLevelStats}
                                    />
                                </StatCard>
                                <StatCard title="Job Types">
                                    <StatTable
                                        columns={jobTypeColumns}
                                        data={jobTypeStats}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>

                    <TabPanel>
                        <StatsContainer title="Resumes">
                            <div className="flex flex-wrap gap-6">
                                <StatCard title="Column Layout">
                                    <StatTable
                                        columns={resumeLayoutColumns}
                                        data={resumeLayoutStats}
                                    />
                                </StatCard>
                                <StatCard
                                    title="Average Resumes per User"
                                    icon={<FileIcon size={16} />}
                                    loading={isResumesLoading}
                                    className="col-span-2"
                                >
                                    <StatTable
                                        columns={resumesPerUserColumns}
                                        data={Object.entries(
                                            resumesPerUser,
                                        ).map(([numResumes, count]) => ({
                                            numResumes,
                                            count,
                                            percentage:
                                                (count / allUsers.length) * 100,
                                        }))}
                                    />
                                </StatCard>
                            </div>
                        </StatsContainer>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    );
};

import { Formik } from "formik";
import { Loader } from "lucide-react";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Await, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { CreditTransactionForm } from "./CreditTransactionForm";
import { userSchema } from "./schema/user";
import userApi from "@/api/user";
import { Checkbox } from "@/components/form/Checkbox";
import { Input } from "@/components/form/Input";
import { InputError } from "@/components/form/InputError";
import { InputLabel } from "@/components/form/InputLabel";
import { Select, SelectItem } from "@/components/form/Select";
import { useAppDispatch } from "@/hooks/types";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import { AdminFormFooter } from "@/pages/Admin/AdminFormFooter";
import { ReadOnlyField } from "@/pages/Admin/ReadOnlyField";
import { CreditTransactionsList } from "@/pages/Admin/Users/CreditTransactionsList";
import { addNotification } from "@/store/app/slice";
import { NotificationMessageType, NotificationType, User } from "@/types";
import { AdminNavigationItem } from "@/types/admin";
import { ICreditAction } from "@/types/creditAction";
import { getIsProductionEnv } from "@/utils/env";

const userFormNavItems: AdminNavigationItem[] = [
    { id: "details", label: "Details" },
    { id: "credits", label: "Credits" },
    { id: "transactions", label: "Transactions" },
];

const userRoleItems: SelectItem[] = [
    { id: "user", name: "User" },
    { id: "admin", name: "Admin" },
];

export const UserForm = () => {
    const { getAdminUser } = useLoaderData() as {
        getAdminUser: Promise<{
            user: User;
            creditActions: Array<ICreditAction>;
        }>;
    };

    return (
        <Suspense fallback={<Loader />}>
            <Await
                resolve={getAdminUser}
                errorElement={<div>Failed to load user</div>}
            >
                {resolvedValues => (
                    <UserFormFields
                        user={resolvedValues.user}
                        creditActions={resolvedValues.creditActions}
                    />
                )}
            </Await>
        </Suspense>
    );
};

const formatDate = (dateString: string | Date | null | undefined) => {
    if (!dateString) return "";
    const date =
        typeof dateString === "string" ? new Date(dateString) : dateString;
    return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

export const UserFormFields = ({
    user,
    creditActions,
}: {
    user?: User;
    creditActions: Array<ICreditAction>;
}) => {
    const isProduction = getIsProductionEnv();
    const [activeTab, setActiveTab] = useState<string>(userFormNavItems[0].id);
    const initialValues: Partial<User> = useMemo(
        () => ({
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            role: user?.role || "user",
            isEmailVerified: user?.isEmailVerified || false,
            loginMethod: user?.loginMethod || undefined,
            stripeCustomerId: user?.stripeCustomerId || "",
            createdAt: user?.createdAt || new Date(),
            scheduledDeletionDate: user?.scheduledDeletionDate || null,
            lastLoginAt: user?.lastLoginAt || null,
            credits: user?.credits || { balance: 0, lastUpdated: null },
            flags: user?.flags || {},
            marketingOptIns: user?.marketingOptIns || [],
            preferences: user?.preferences || {
                experienceLevel: "",
                industries: [],
                jobTypes: [],
                jobRoles: [],
            },
            acceptedTerms: user?.acceptedTerms || false,
        }),
        [user],
    );
    const dispatch = useAppDispatch();

    const { userId: id } = useParams();
    const userId = id !== "new" ? id : undefined;

    const navigate = useNavigate();

    const handleError = useCallback(
        (err: unknown, title: string, messageType: NotificationMessageType) => {
            let errorMessage;
            if (typeof err === "string") {
                errorMessage = err;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }

            dispatch(
                addNotification({
                    title,
                    desc: errorMessage,
                    messageType,
                    type: NotificationType.ERROR,
                }),
            );
        },
        [dispatch],
    );

    const handleSubmit = async (user: Partial<User>) => {
        try {
            await userApi.saveUserApi(user, userId).then(() => {
                if (!userId) {
                    navigate("/admin/users", { replace: true }); // push user back to users list
                }

                dispatch(
                    addNotification({
                        title: "Successfully saved!",
                        desc: "Your details are successfully saved!!",
                        messageType: NotificationMessageType.ADMIN_USER_SAVE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (err: unknown) {
            handleError(
                err,
                "Failed to save",
                NotificationMessageType.ADMIN_USER_SAVE,
            );
        }
    };

    const handleDelete = useCallback(async () => {
        if (isProduction) {
            dispatch(
                addNotification({
                    title: "Unable to complete action",
                    desc: "You cannot delete users in production via the admin panel. Removing users must be done via the proper process",
                    messageType: NotificationMessageType.ADMIN_USER_DELETE,
                    type: NotificationType.WARNING,
                }),
            );
            return;
        }
        try {
            if (!userId) {
                navigate("/admin/users", { replace: true }); // push user back to sections list
                return;
            }
            await userApi.deleteUserApi(userId).then(() => {
                navigate("/admin/users", { replace: true });
                dispatch(
                    addNotification({
                        title: "Successfully deleted",
                        desc: "User successfully deleted",
                        messageType: NotificationMessageType.ADMIN_USER_DELETE,
                        type: NotificationType.SUCCESS,
                    }),
                );
            });
        } catch (e) {
            handleError(
                e,
                "Failed to delete",
                NotificationMessageType.ADMIN_USER_DELETE,
            );
        }
    }, [isProduction, dispatch, userId, navigate, handleError]);

    return (
        <AdminFormContainer
            navigationItems={userFormNavItems}
            onNavigationItemClick={id => setActiveTab(id)}
            activeItemId={activeTab}
            pageTitle="User"
        >
            {activeTab === "details" && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={userSchema}
                    onSubmit={async values => {
                        await handleSubmit(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        submitForm,
                        setFieldValue,
                    }) => (
                        <>
                            <div className="space-y-6 px-2">
                                {/* Basic Information */}
                                <div className="rounded-lg border border-gray-200 px-4 py-2">
                                    <h3 className="mb-2 text-lg font-medium">
                                        Basic Information
                                    </h3>
                                    <div className="grid grid-cols-3 gap-4">
                                        <ReadOnlyField
                                            label="ID"
                                            value={user?.id}
                                        />
                                        <div className="px-1 py-1">
                                            <InputLabel
                                                label="First Name"
                                                htmlFor="firstName"
                                                required
                                            />
                                            <Input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                value={values.firstName}
                                                formikChange={handleChange}
                                                error={errors.firstName}
                                            />
                                        </div>
                                        <div className="px-1 py-1">
                                            <InputLabel
                                                label="Last Name"
                                                htmlFor="lastName"
                                                required
                                            />
                                            <Input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                value={values.lastName}
                                                formikChange={handleChange}
                                                error={errors.lastName}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Account Settings */}
                                <div className="rounded-lg border border-gray-200 px-4 py-2">
                                    <h3 className="mb-2 text-lg font-medium">
                                        Account Settings
                                    </h3>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="px-1 py-1">
                                            <InputLabel
                                                label="Email"
                                                htmlFor="email"
                                                required
                                            />
                                            <Input
                                                type="text"
                                                name="email"
                                                id="email"
                                                value={values.email}
                                                formikChange={handleChange}
                                                error={errors.email}
                                            />
                                        </div>
                                        <div className="px-1 py-1">
                                            <InputLabel
                                                label="Role"
                                                htmlFor="role"
                                                required
                                                description={`"user" or "admin"`}
                                            />
                                            <Select
                                                value={userRoleItems.find(
                                                    item =>
                                                        item.id === values.role,
                                                )}
                                                items={userRoleItems}
                                                onChange={item =>
                                                    setFieldValue(
                                                        "role",
                                                        item.id,
                                                    )
                                                }
                                            />
                                            <InputError error={errors.role} />
                                        </div>
                                        <div className="flex items-center px-1 py-1">
                                            <Checkbox
                                                id="isEmailVerified"
                                                label="Is Email Verified?"
                                                checked={
                                                    !!values.isEmailVerified
                                                }
                                                onChange={(checked: boolean) =>
                                                    setFieldValue(
                                                        "isEmailVerified",
                                                        checked,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* System Information */}
                                <div className="rounded-lg border border-gray-200 px-4 py-2">
                                    <h3 className="mb-2 text-lg font-medium">
                                        System Information
                                    </h3>
                                    <div className="grid grid-cols-3 gap-4">
                                        <ReadOnlyField
                                            label="Login Method"
                                            value={values.loginMethod}
                                            description="The method used to login to the account"
                                        />
                                        <ReadOnlyField
                                            label="Stripe Customer ID"
                                            value={values.stripeCustomerId}
                                            description="The Stripe customer ID associated with the user"
                                        />
                                        <ReadOnlyField
                                            label="Created At"
                                            value={formatDate(values.createdAt)}
                                            description="The date and time the user was created"
                                        />
                                        <ReadOnlyField
                                            label="Last Login At"
                                            value={formatDate(
                                                values.lastLoginAt,
                                            )}
                                            description="The date and time the user last logged in"
                                        />
                                        <ReadOnlyField
                                            label="Credits"
                                            value={values.credits?.balance}
                                            description="The number of credits the user has"
                                        />
                                        <ReadOnlyField
                                            label="Scheduled Deletion Date"
                                            value={formatDate(
                                                values.scheduledDeletionDate,
                                            )}
                                            description="The date and time the user's account will be deleted"
                                        />
                                    </div>
                                </div>

                                {/* Additional Data */}
                                <div className="rounded-lg border border-gray-200 px-4 py-2">
                                    <h3 className="mb-2 text-lg font-medium">
                                        Additional Data
                                    </h3>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="px-1">
                                            <ReadOnlyField
                                                label="Flags"
                                                value={JSON.stringify(
                                                    values.flags,
                                                    null,
                                                    2,
                                                )}
                                                description="Flags are used to track user behavior"
                                            />
                                        </div>
                                        <div className="px-1">
                                            <ReadOnlyField
                                                label="Marketing Opt Ins"
                                                value={JSON.stringify(
                                                    values.marketingOptIns,
                                                    null,
                                                    2,
                                                )}
                                                description="Opt ins for marketing purposes"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AdminFormFooter
                                onSave={submitForm}
                                listHref="/admin/users"
                                onDelete={handleDelete}
                            />
                        </>
                    )}
                </Formik>
            )}
            {activeTab === "credits" && (
                <div className="space-y-6 px-2">
                    <CreditTransactionForm
                        userId={userId!}
                        onSuccess={() => {
                            setActiveTab("transactions");
                        }}
                        userEmail={user?.email}
                        creditActions={creditActions}
                    />
                </div>
            )}
            {activeTab === "transactions" && (
                <CreditTransactionsList userId={userId!} />
            )}
        </AdminFormContainer>
    );
};

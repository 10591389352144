import clsx from "clsx";
import {
    ArrowDown,
    ArrowUp,
    CircleCheck,
    Coins,
    RefreshCcw,
    Shield,
} from "lucide-react";
import { AppText } from "@/components/Text/AppText";
import { CreditActionType, ICreditAction } from "@/types/creditAction";
import { ICreditTransaction } from "@/types/creditTransaction";
import { pluralise } from "@/utils/string";

const getLineItemDescriptionPrefix = (type: CreditActionType) => {
    switch (type) {
        case "PURCHASE":
            return "Purchased on";
        case "REFUND":
            return "Refunded on";
        case "EARN":
            return "Earned on";
        case "SPEND":
            return "Spent on";
        case "ADMIN":
            return "Admin Adjusted on";
        case "SIGNUP":
            return "Signed Up on";
        default:
            return "";
    }
};

const LineItemIcon = ({ type }: { type: CreditActionType }) => {
    switch (type) {
        case "PURCHASE":
            return <Coins size={12} />;
        case "REFUND":
            return <RefreshCcw size={12} />;
        case "EARN":
            return <ArrowUp size={12} />;
        case "SPEND":
            return <ArrowDown size={12} />;
        case "ADMIN":
            return <Shield size={12} />;
        case "SIGNUP":
            return <CircleCheck size={12} />;
        default:
            return <div />;
    }
};

interface TransactionLineItemProps {
    transaction: ICreditTransaction;
    creditAction: ICreditAction;
}

const LineItemDetails = ({
    transaction,
    creditAction,
}: TransactionLineItemProps) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <div className="flex h-6 w-6 items-center justify-center rounded-lg bg-primary-700 text-neutral-50">
                    <LineItemIcon type={transaction.type} />
                </div>
                <AppText
                    variant="contextheading"
                    className="capitalize text-neutral-900"
                >
                    {creditAction?.name ||
                        transaction.type?.toLowerCase() ||
                        ""}
                </AppText>
            </div>
            <AppText
                variant="labelsbuttons"
                className="text-neutral-600"
            >
                {getLineItemDescriptionPrefix(transaction.type)}{" "}
                {new Date(transaction.createdAt).toLocaleString("en-GB", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                })}
            </AppText>
        </div>
    );
};

const LineItemAmount = ({
    transaction,
}: {
    transaction: ICreditTransaction;
}) => {
    const isPositive = transaction.amount > 0;
    return (
        <div
            className={clsx(
                isPositive
                    ? "bg-success-700 text-neutral-50"
                    : "bg-red-100 text-red-500",
                "flex items-center gap-2 rounded-lg px-3 py-1",
            )}
        >
            <AppText
                variant="regular"
                className="!font-semibold"
            >
                {isPositive ? "+" : "-"} {Math.abs(transaction.amount)}{" "}
                {pluralise(transaction.amount, "Credit", "Credits")}
            </AppText>
        </div>
    );
};

export const TransactionLineItem = ({
    transaction,
    creditAction,
}: TransactionLineItemProps) => {
    return (
        <div className="flex items-center justify-between rounded-xl bg-neutral-100 p-4">
            <LineItemDetails
                transaction={transaction}
                creditAction={creditAction}
            />
            <LineItemAmount transaction={transaction} />
        </div>
    );
};

import { filterXSS } from "xss";

/**
 * On paste events for the editors
 * 1. remove zero-width spaces and replace other special spaces with " "
 * 2. split the string by newlines
 * 3. preserve allowed HTML tags, wrap plain text in p tags
 * @param input - The input string to format
 * @returns The formatted string
 */
export const formatPastedContent = (input: string, isHtml: boolean): string => {
    const SPACE_REGEX =
        /(\xA0|&ensp;|\u2002|&emsp;|\u2003|&thinsp;|\u2009|\u200A)/g;
    const ZERO_WIDTH_REGEX = /(\u200B|&#xfeff;)/g;
    const LINE_BREAK_REGEX = /\r\n|\r|\n/g;

    const result = input
        .replace(ZERO_WIDTH_REGEX, "")
        .replace(SPACE_REGEX, " ");

    if (!isHtml) {
        return result;
    }

    // Sanitize HTML once before processing lines
    const cleanHtml = filterXSS(result, {
        whiteList: {
            p: [],
            strong: [],
            em: [],
            u: [],
            h1: [],
            h2: [],
            h3: [],
            h4: [],
            h5: [],
            h6: [],
            ul: [],
            ol: [],
            li: [],
            a: ['href']
        },
        stripIgnoreTag: true,
        stripIgnoreTagBody: ["script", "style"]
    });

    const lines = cleanHtml.split(LINE_BREAK_REGEX);

    const linesWithParagraphs = lines
        .filter(line => line.trim().length > 0)
        .map(line => {
            const trimmedLine = line.trim();
            const parser = new DOMParser();
            const doc = parser.parseFromString(trimmedLine, "text/html");

            // Check if the parsed content has any elements
            const hasHtmlContent = doc.body.children.length > 0;

            if (hasHtmlContent) {
                return trimmedLine;
            }

            return `<p>${trimmedLine}</p>`;
        });

    return linesWithParagraphs.join("");
};

import { loadStripe } from "@stripe/stripe-js/pure";
import clsx from "clsx";
import { CheckIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { pluralise } from "@/utils/string";
import { trackClarityEvent, trackMetaEvent } from "@/utils/tracking";

export const CreditPacks = ({
    isProfile = false,
    source = "",
}: {
    isProfile?: boolean;
    source?: "" | "upgrade" | "download" | "preview";
}) => {
    const [loading, setLoading] = useState(false);
    const allStripeItems = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const [error, setError] = useState<string | null>(null);
    const { getCreditActionsByType } = useTransaction();
    const purchaseCreditActions = useMemo(
        () => getCreditActionsByType("PURCHASE"),
        [getCreditActionsByType],
    );
    const { getStripeProducts, createStripeCheckoutSession } = useStripe();

    const stripeProducts = useMemo(
        () => allStripeItems?.filter(product => !product.isSubscription),
        [allStripeItems],
    );

    const onPackSelect = useCallback(
        async (priceId: string) => {
            setError(null);
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            );
            const redirectUrl = isProfile
                ? `${window.location.origin}${window.location.pathname}?tab=credits`
                : `${window.location.origin}${window.location.pathname}?source=${source}`;
            try {
                setLoading(true);
                const sessionId = await createStripeCheckoutSession(
                    priceId,
                    redirectUrl,
                );

                trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);

                trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                    content_category: "Pack selection",
                    content_name: "Select pack",
                    num_items: 1,
                    content_type: "product",
                    content_ids: [priceId],
                });

                const res = await stripe?.redirectToCheckout({
                    sessionId,
                });

                if (res?.error) {
                    console.error(
                        "Error initiating Stripe checkout:",
                        res.error,
                    );
                }
            } catch (error) {
                console.error("Error initiating Stripe checkout:", error);
                setError(error as string);
            } finally {
                setLoading(false);
            }
        },
        [isProfile, source, createStripeCheckoutSession],
    );

    useEffect(() => {
        if (allStripeItems === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, allStripeItems, loadingProducts, errorProducts]);

    if (errorProducts || error) {
        return (
            <div className="flex h-[230px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    return (
        <div
            className={clsx(
                "flex gap-3",
                loading || loadingProducts ? "flex-col" : "flex-row flex-wrap",
            )}
        >
            {loading || loadingProducts ? (
                <div className="flex min-h-[230px] items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                stripeProducts &&
                stripeProducts.map(product => {
                    const productDetails = product;
                    const creditAction = purchaseCreditActions.find(
                        action =>
                            action.metadata?.stripePriceId ===
                            productDetails.priceId,
                    );
                    const productNameWithoutPack = productDetails.name.replace(
                        " Pack",
                        "",
                    );

                    if (!creditAction) {
                        return null;
                    }
                    const credits = creditAction.credits ?? 0;
                    const metadata = creditAction.metadata ?? {};
                    const shortSummary =
                        (metadata.shortSummary as string) || "";
                    const isPopular = !!(metadata.mostPopular ?? false);
                    const isBestValue = !!(metadata.bestValue ?? false);

                    return (
                        <div
                            key={productDetails.id}
                            className="relative flex flex-1 basis-0 flex-col"
                        >
                            <div className="h-8">
                                {isPopular ? (
                                    <div
                                        className={clsx(
                                            "h-full w-full rounded-t-xl px-4 text-center",
                                            isPopular &&
                                                "bg-primary-700 text-white ring-2 ring-primary-700",
                                        )}
                                    >
                                        <AppText
                                            variant="labelsbuttons"
                                            className="mt-2 leading-8"
                                        >
                                            MOST POPULAR
                                        </AppText>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className={clsx(
                                    "flex h-full flex-col rounded-xl shadow-md",
                                    isPopular ? "rounded-t-none" : "",
                                    isProfile
                                        ? "bg-neutral-100"
                                        : "bg-neutral-50",
                                    "p-4",
                                    isPopular && "ring-2 ring-primary-700",
                                )}
                            >
                                <div className="mb-6 flex h-full flex-col">
                                    <AppText
                                        variant="contextheading"
                                        className="text-left text-neutral-700"
                                    >
                                        {productNameWithoutPack}
                                    </AppText>
                                    <AppText
                                        variant="subheadings"
                                        className="flex items-center text-left text-neutral-900"
                                    >
                                        {productDetails.price > 0 &&
                                            productDetails.currencySymbol}
                                        {productDetails.price}
                                    </AppText>

                                    {shortSummary && (
                                        <AppText
                                            variant="footnote"
                                            className="mt-2 block text-left text-neutral-900"
                                        >
                                            {shortSummary}
                                        </AppText>
                                    )}
                                </div>
                                <Button
                                    onClick={() =>
                                        onPackSelect(productDetails.priceId)
                                    }
                                    className="mt-auto w-full !py-6"
                                >
                                    BUY {credits}{" "}
                                    {pluralise(credits, "CREDIT", "CREDITS")}
                                </Button>
                                <div className="mt-4 flex flex-col gap-1 border-t-2 border-neutral-100 pt-4 text-neutral-700">
                                    <div className="flex items-center gap-2">
                                        <CheckIcon className="h-5 w-5 text-primary-700" />
                                        <AppText variant="regular">
                                            Download Resumes
                                        </AppText>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CheckIcon className="h-5 w-5 text-primary-700" />
                                        <AppText variant="regular">
                                            Smart Scribe AI
                                        </AppText>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CheckIcon className="h-5 w-5 text-primary-700" />
                                        <AppText variant="regular">
                                            Bullet Builder AI
                                        </AppText>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CheckIcon className="h-5 w-5 text-primary-700" />
                                        <AppText variant="regular">
                                            Paragraph Pro AI
                                        </AppText>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <CheckIcon className="h-5 w-5 text-primary-700" />
                                        <AppText variant="regular">
                                            Additional Resumes
                                        </AppText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};

import clsx from "clsx";
import { RefreshCwIcon } from "lucide-react";
import { useCallback, useState } from "react";
import creditTransactionApi from "@/api/creditTransaction";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { useAppDispatch } from "@/hooks/types";
import { LoadingOverlay } from "@/pages/Admin/components/AdminLoadingOverlay";
import { AdminNoResults } from "@/pages/Admin/components/AdminNoResults";
import { Pagination } from "@/pages/Admin/components/AdminPagination";
import { AdminPip } from "@/pages/Admin/components/AdminPip";
import { AdminSearchBar } from "@/pages/Admin/components/AdminSearchBar";
import {
    AdminTable,
    AdminTableHeader,
    AdminTableHeaderCell,
    AdminTableBody,
    AdminTableBodyCell,
    AdminTableBodyRow,
} from "@/pages/Admin/components/Table";
import { handleAdminError } from "@/pages/Admin/utils/handleAdminError";
import { NotificationMessageType } from "@/types";
import { CreditActionType } from "@/types/creditAction";
import { ICreditTransaction } from "@/types/creditTransaction";

type SortColumn = "createdAt" | "type" | "actionCode" | "amount" | "balance";

const getTypeColour = (type: CreditActionType) => {
    switch (type) {
        case "EARN":
            return "bg-primary-300";
        case "SPEND":
            return "bg-success-300";
        case "REFUND":
            return "bg-error-300";
        case "PURCHASE":
            return "bg-alert-300";
        case "ADMIN":
            return "bg-neutral-300";
        default:
            return "bg-neutral-500";
    }
};

const columnNames = [
    { key: "id", label: "ID", width: 190 },
    { key: "createdAt", label: "Created", width: 140 },
    { key: "type", label: "Type", width: 100 },
    { key: "actionCode", label: "Action", width: 180 },
    { key: "amount", label: "Amount", width: 80 },
    { key: "balance", label: "New Balance", width: 80 },
    { key: "description", label: "Description", width: 200 },
    { key: "createdBy", label: "Created By", width: 190 },
];

const itemsPerPage = 50;

interface CreditTransactionsListProps {
    userId: string;
}

export const CreditTransactionsList = ({
    userId,
}: CreditTransactionsListProps) => {
    const [transactions, setTransactions] = useState<ICreditTransaction[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>("createdAt");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
    const [searchTerm, setSearchTerm] = useState("");
    const [hasLoaded, setHasLoaded] = useState(false);
    const dispatch = useAppDispatch();

    const fetchTransactions = useCallback(
        async (
            page: number,
            sortCol: SortColumn = sortColumn,
            sortDir: "asc" | "desc" = sortDirection,
            search: string = searchTerm,
        ) => {
            setIsLoading(true);
            try {
                const result = await creditTransactionApi.getTransactions({
                    userId,
                    page,
                    limit: itemsPerPage,
                    sortBy: `${sortCol}:${sortDir}`,
                    search: search || undefined,
                });
                setTransactions(result.results);
                setTotalItems(result.totalResults);
                setCurrentPage(page);
                setHasLoaded(true);
            } catch (error) {
                handleAdminError(
                    dispatch,
                    error,
                    "Failed to fetch transactions",
                    NotificationMessageType.ADMIN_CREDIT_TRANSACTION_SAVE,
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, userId, searchTerm, sortColumn, sortDirection],
    );

    const handleSort = useCallback(
        (column: SortColumn) => {
            const newSortDirection =
                sortColumn === column && sortDirection === "asc"
                    ? "desc"
                    : "asc";
            setSortColumn(column);
            setSortDirection(newSortDirection);
            setCurrentPage(1);
            fetchTransactions(1, column, newSortDirection);
        },
        [sortColumn, sortDirection, fetchTransactions],
    );

    const handleSearchChange = useCallback(
        (term: string) => {
            if (searchTerm === term) return;
            setSearchTerm(term);
            setCurrentPage(1);
            fetchTransactions(1, sortColumn, sortDirection, term);
        },
        [searchTerm, fetchTransactions, sortColumn, sortDirection],
    );

    const handlePageChange = (page: number) => {
        fetchTransactions(page);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="min-h-[550px] space-y-4 px-2">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                    {!hasLoaded ? (
                        <Button
                            onClick={() => fetchTransactions(1)}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Load Transactions
                        </Button>
                    ) : (
                        <>
                            <IconButton
                                onClick={() => fetchTransactions(currentPage)}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                <RefreshCwIcon className="h-4 w-4" />
                            </IconButton>
                            <span className="text-sm text-gray-400">
                                {totalItems} transactions
                            </span>
                            <span className="text-xs text-gray-400">
                                (Transactions can not be edited in the admin)
                            </span>
                        </>
                    )}
                </div>
                <div className="h-9 w-96">
                    <AdminSearchBar
                        setSearchTerm={handleSearchChange}
                        placeholder="Search id, action code, description, or type"
                    />
                </div>
            </div>

            {hasLoaded && (
                <div className="relative overflow-visible">
                    <LoadingOverlay isLoading={isLoading} />
                    <AdminTable>
                        <AdminTableHeader>
                            {columnNames.map(({ key, label, width }) => (
                                <AdminTableHeaderCell
                                    key={key}
                                    label={label}
                                    sortKey={key}
                                    currentSortColumn={sortColumn}
                                    currentSortDirection={sortDirection}
                                    onSort={key =>
                                        handleSort(key as SortColumn)
                                    }
                                    isSortable={
                                        key !== "description" &&
                                        key !== "createdBy"
                                    }
                                    width={width}
                                />
                            ))}
                        </AdminTableHeader>
                        <AdminTableBody>
                            {transactions.map(transaction => (
                                <AdminTableBodyRow key={transaction.id}>
                                    {columnNames.map(({ key, width }) => (
                                        <AdminTableBodyCell
                                            key={key}
                                            width={width}
                                            className={clsx(
                                                "!text-xs",
                                                key === "type" &&
                                                    "!overflow-visible",
                                            )}
                                            rawValue={
                                                key === "description"
                                                    ? transaction.description
                                                    : undefined
                                            }
                                        >
                                            {key === "id" && transaction.id}
                                            {key === "createdAt" &&
                                                new Date(
                                                    transaction.createdAt,
                                                ).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            {key === "type" && (
                                                <AdminPip
                                                    colour={getTypeColour(
                                                        transaction.type,
                                                    )}
                                                >
                                                    {transaction.type}
                                                </AdminPip>
                                            )}
                                            {key === "actionCode" &&
                                                transaction.actionCode}
                                            {key === "amount" &&
                                                transaction.amount}
                                            {key === "balance" &&
                                                transaction.balance}
                                            {key === "description" &&
                                                transaction.description}
                                            {key === "createdBy" &&
                                                transaction.createdBy}
                                        </AdminTableBodyCell>
                                    ))}
                                </AdminTableBodyRow>
                            ))}
                        </AdminTableBody>
                    </AdminTable>
                    {transactions.length === 0 && !isLoading && (
                        <AdminNoResults />
                    )}
                </div>
            )}

            {hasLoaded && transactions.length > 0 && (
                <div className="mt-4 flex justify-end">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
};

import { AnimatePresence } from "framer-motion";
import { ChevronRight } from "lucide-react";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Wizard } from "react-use-wizard";
import userApi from "@/api/user";
import { Button } from "@/components/Button/Button";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import {
    AnimatedStep,
    PreferencesFooter,
    PreferencesHeader,
} from "@/pages/Preferences/components";
import {
    PreferencesIndustry,
    PreferencesExperience,
    PreferencesJobRole,
    PreferencesJobType,
} from "@/pages/Preferences/steps";
import { PreferencesWelcome } from "@/pages/Preferences/steps/PreferencesWelcome";
import { getUserDetails } from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { getUserPreferenceWizard } from "@/store/wizard/selectors";
import { AppRoutes } from "@/types/routes";

export const PreferencesPage = () => {
    const previousStep = useRef<number>(0);
    const skipButtonRef = useRef<HTMLDivElement | null>(null);
    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get("redirect");
    const navigate = useNavigate();
    const userPrefences = useAppSelector(getUserPreferenceWizard);
    const user = useAppSelector(getUserDetails);
    const dispatch = useAppDispatch();
    const [, setLocalWelcome] = useLocalStorage("welcome", null);
    const onComplete = useCallback(async () => {
        userApi
            .saveUserApi({ preferences: userPrefences }, user?.id)
            .then(user => {
                dispatch(setUserDetails(user));
                if (!redirectUrl) setLocalWelcome(true);
                navigate(
                    redirectUrl ? `/${redirectUrl}` : AppRoutes.Dashboard,
                    {
                        replace: !redirectUrl,
                    },
                );
            });
    }, [
        dispatch,
        navigate,
        setLocalWelcome,
        redirectUrl,
        user?.id,
        userPrefences,
    ]);

    return (
        <NoNavPageContainer
            footer={
                <div
                    className="mb-4 flex justify-center opacity-0"
                    ref={skipButtonRef}
                >
                    <Button
                        variant={"solid"}
                        color={"grey"}
                        onClick={onComplete}
                        rightIcon={<ChevronRight size={16} />}
                        className=""
                    >
                        Skip
                    </Button>
                </div>
            }
        >
            <PageHelmet title="Preferences" />
            <Wizard
                startIndex={redirectUrl ? 1 : 0}
                header={<PreferencesHeader sourcePath={redirectUrl} />}
                footer={
                    <PreferencesFooter
                        onComplete={onComplete}
                        sourcePath={redirectUrl}
                        skipButtonRef={skipButtonRef}
                    />
                }
                wrapper={
                    <AnimatePresence
                        initial={false}
                        mode="wait"
                    />
                }
            >
                <AnimatedStep previousStep={previousStep}>
                    <PreferencesWelcome skipButtonRef={skipButtonRef} />
                </AnimatedStep>
                <AnimatedStep previousStep={previousStep}>
                    <PreferencesIndustry />
                </AnimatedStep>
                <AnimatedStep previousStep={previousStep}>
                    <PreferencesJobRole />
                </AnimatedStep>
                <AnimatedStep previousStep={previousStep}>
                    <PreferencesExperience />
                </AnimatedStep>
                <AnimatedStep previousStep={previousStep}>
                    <PreferencesJobType />
                </AnimatedStep>
            </Wizard>
        </NoNavPageContainer>
    );
};

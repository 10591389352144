import { CreditActionCode } from "@/types/creditAction";

const getTargetProfile = (
  selectedJobRoles: string[],
  selectedExperience: string,
  selectedIndustries: string[]
) => {
  const parts = [
    "Target Profile:",
    `Job Roles: ${selectedJobRoles.join(", ")}`,
    `Experience Level: ${selectedExperience}`,
    `Industry Context: ${selectedIndustries.join(", ")}`,
  ];
  return parts.join("\n");
};


export const generateSummaryPrompt = (
  customPrompt: string,
  selectedJobRoles: string[],
  selectedExperience: string,
  selectedIndustries: string[],
  additionalContext: string
) => {
  if (customPrompt) {
    return customPrompt;
  }

  const parts = [
    "Generate a resume summary. Response should be 3-4 sentences, professional, industry-appropriate language, focus on career trajectory and key strengths, avoid clichés and generic statements, make it as human as possible",
    getTargetProfile(selectedJobRoles, selectedExperience, selectedIndustries),
  ];

  if (additionalContext) {
    parts.push("", "Additional Context:", additionalContext);
  }

  return parts.join("\n");
};

export const generateBulletPointsPrompt = (
  customPrompt: string,
  additionalContext: string,
  sectionName: string,
) => {
  if (customPrompt) {
    return customPrompt;
  }

  switch (sectionName) {
    case "Work Experience":
      return `Generate 3-6 impactful bullet points for Work Experience using details like job title, company, responsibilities, achievements, and metrics. Focus on measurable outcomes, action verbs, and relevant skills. Use the context provided: ${additionalContext}`;
    case "Projects":
      return `Generate 2-4 impactful bullet points for Projects. Highlight technical skills, methodologies, and measurable results. Use the context provided: ${additionalContext}`;
    case "Education":
      return `Generate 1-3 impactful bullet points for Education. Highlight honours, relevant coursework, and leadership roles. Use the context provided: ${additionalContext}`;
    default:
      return `Generate 2-4 concise, impactful bullet points for the ${sectionName} section. Use the context provided: ${additionalContext}`;
  }
};

export const generateWritePrompt = (
  creditActionCode: CreditActionCode,
  content: string,
) => {
  switch (creditActionCode) {
    case CreditActionCode.AI_PROMPT_REWRITE:
      return `Rewrite this text to improve clarity, professionalism, and impact: ${content}`;
    case CreditActionCode.AI_PROMPT_SHORTEN:
      return `Shorten this text to 1-2 sentences: ${content}`;
    case CreditActionCode.AI_PROMPT_FORMAL:
      return `Rewrite this text to be more formal and professional: ${content}`;
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return `Fix grammar and spelling errors in this text: ${content}`;
    default:
      return `Rewrite this text to improve clarity, professionalism, and impact: ${content}`;
  }
};
/* eslint-disable import/no-named-as-default */
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { AnyExtension, Editor } from "@tiptap/react";
import { useState, useEffect, useRef } from "react";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { getHasUnsavedChanges } from "@/store/resume/selectors";
import { setHasUnsavedChanges } from "@/store/resume/slice";
import { formatPastedContent } from "@/utils/formatPastedContent";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Heading, { Level } from "@tiptap/extension-heading";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
/* eslint-enable import/no-named-as-default */

const extensions = [
    TextAlign.configure({
        types: ["heading", "paragraph"],
        alignments: ["left", "center", "right", "justify"],
    }),
    BulletList,
    Underline,
    Paragraph,
    ListItem.extend({
        content: "paragraph*", // When we want to support nested list, change this to paragraph block*
    }),
    OrderedList,
    Text,
    History,
    Italic,
    Bold,
];

interface MyEditorProvider {
    children: React.ReactNode;
}

export const MyEditorProvider = ({ children }: MyEditorProvider) => {
    const [activeEditor, setLocalActiveEditor] = useState<Editor | null>(null);
    const [editors, setEditors] = useState<Record<string, Editor>>({});
    const { updateField } = useUpdateTemplate();
    const dispatch = useAppDispatch();
    const hasUnsavedChanges = useAppSelector(getHasUnsavedChanges);
    const hasUnsavedChangesRef = useRef(hasUnsavedChanges);

    useEffect(() => {
        hasUnsavedChangesRef.current = hasUnsavedChanges;
    }, [hasUnsavedChanges]);

    const addEditor = (
        editorId: string,
        initialValue: string | undefined,
        itemId: string | undefined,
        propType: "header" | "body",
        sectionId: string,
        propId: string,
        placeholder?: string,
        titleVariant?: Level,
        icon?: string,
        isStaticHeader?: boolean,
    ) => {
        const editorIds = Object.keys(editors);

        if (editorIds.includes(editorId)) {
            return editors[editorId];
        }

        const editorExtensions = [
            Document.extend({
                content: titleVariant ? "heading+" : "block+",
            }),
            Placeholder.configure({
                emptyEditorClass: "is-empty-field",
                emptyNodeClass: "is-empty-node",
                showOnlyWhenEditable: false,
                showOnlyCurrent: true,
                placeholder,
            }),
            Link.configure({
                openOnClick: false,
                autolink: false,
                linkOnPaste: false,
                validate: href => /^https?:\/\//.test(href),
            }),
            Heading.configure({
                levels: titleVariant ? [titleVariant] : [],
            }),
            ...extensions,
        ].filter(Boolean) as AnyExtension[];

        const newEditor = new Editor({
            injectCSS: false,
            extensions: editorExtensions,
            editorProps: {
                attributes: {
                    id: editorId,
                    "data-placeholder": placeholder ?? "",
                    "data-icon": icon ?? "",
                    "data-static-header": isStaticHeader ? "true" : "",
                },
                transformPastedText(text: string) {
                    return formatPastedContent(text, false);
                },
                transformPastedHTML(html: string) {
                    return formatPastedContent(html, true);
                },
                handleDOMEvents: {
                    keydown: (view, event) => {
                        /**
                         * Prevent the deletion of an empty field. This preserves the element
                         * and preserves the placeholder being shown.
                         */
                        if (event.key === "Backspace") {
                            const firstChild = view.dom.firstChild;

                            if (firstChild) {
                                const classList = [...firstChild.classList];
                                const isEmptyField =
                                    classList.includes("is-empty-field");
                                const isEmptyNode =
                                    classList.includes("is-empty-node");

                                if (isEmptyField && isEmptyNode) {
                                    event.preventDefault();
                                }

                                // Prevent user from "clearing ALL" and whiping out the element
                                const contentLength =
                                    view.state.doc.content.size;
                                const { from, to } = view.state.selection;

                                const totalSelected = to - from;
                                if (totalSelected === contentLength) {
                                    event.preventDefault();
                                    firstChild.innerHTML = "";
                                }
                            }
                        }
                    },
                },
            },
            content: initialValue,
            onBlur: ({ editor }) => {
                const newVal = decodeHtml(editor.getHTML());

                updateField(newVal, propId, propType, sectionId, itemId);
            },
            onUpdate: () => {
                if (!hasUnsavedChangesRef.current) {
                    dispatch(setHasUnsavedChanges(true));
                    hasUnsavedChangesRef.current = true;
                }
            },
        });

        const newEditorState = {
            ...editors,
            [editorId]: newEditor,
        };
        setEditors(newEditorState);

        return newEditor;
    };

    const getEditor = (key: string) => {
        const foundEditor = editors[key];
        return foundEditor;
    };

    const setActiveEditor = (id?: string) => {
        if (!id) {
            setLocalActiveEditor(null);
            return;
        }
        const editor = getEditor(id);
        setLocalActiveEditor(editor);
    };

    const destroyEditors = () => {
        if (Object.keys(editors).length > 0) setEditors({});
    };

    const setActiveEditorContent = (
        content: string,
        selection?: {
            from: number;
            to: number;
        },
    ) => {
        if (!activeEditor) return;
        if (selection) {
            activeEditor.commands.insertContentAt(selection, content);
        } else {
            activeEditor.commands.setContent(content);
        }
    };

    return (
        <EditorContext.Provider
            value={{
                addEditor,
                activeEditor,
                setActiveEditor,
                getEditor,
                destroyEditors,
                setActiveEditorContent,
            }}
        >
            {children}
        </EditorContext.Provider>
    );
};

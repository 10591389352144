
export const CREDIT_ACTION_TYPES = ["SPEND", "EARN", "REFUND", "PURCHASE", "ADMIN", "SIGNUP"] as const;
export const CREDIT_ACTION_CATEGORIES = ["SOCIAL", "ENGAGEMENT", "REFERRAL", "OTHER", "FEATURE", "PRODUCT"] as const;
export const TIME_FRAMES = ["day", "week", "month", "year"] as const;
export const CREDIT_ACTION_AMOUNT_TYPES = ["FIXED", "VARIABLE"] as const;

export type CreditActionType = typeof CREDIT_ACTION_TYPES[number];
export type CreditActionAmountType = typeof CREDIT_ACTION_AMOUNT_TYPES[number];
export type CreditActionCategory = typeof CREDIT_ACTION_CATEGORIES[number];
export type TimeFrame = typeof TIME_FRAMES[number];

export interface IUsageLimit {
    maxCount: number;
    timeFrame: TimeFrame;
    resetCount?: number;
}

export interface ICreditAction {
    id: string;
    code: string;
    name: string;
    description: string;
    type: CreditActionType;
    credits: number | null;
    category: CreditActionCategory;
    isActive: boolean;
    usageLimit?: IUsageLimit;
    cooldownPeriod?: number;
    metadata?: Record<string, unknown>;
    amountType: CreditActionAmountType;
    createdAt: string;
}

export enum CreditActionCode {
    // ADMIN
    ADMIN_ADJUSTMENT = "ADMIN_ADJUSTMENT",
    // EARN
    APP_FEEDBACK_SURVEY = "APP_FEEDBACK_SURVEY",
    // PURCHASE
    SMALL_CREDIT_PACK = "SMALL_CREDIT_PACK",
    MEDIUM_CREDIT_PACK = "MEDIUM_CREDIT_PACK",
    LARGE_CREDIT_PACK = "LARGE_CREDIT_PACK",
    XL_CREDIT_PACK = "XL_CREDIT_PACK",
    // REFUND
    USER_REFUND = "USER_REFUND",
    // SIGNUP
    SIGN_UP = "SIGN_UP",
    SIGN_UP_BONUS = "SIGN_UP_BONUS",
    // SPEND
    DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT",
    INCREASE_DOCUMENT_LIMIT = "INCREASE_DOCUMENT_LIMIT",
    AI_GENERATE_SUMMARY = "AI_GENERATE_SUMMARY",
    AI_GENERATE_BULLET_POINTS = "AI_GENERATE_BULLET_POINTS",
    AI_WRITING_PROMPT = "AI_WRITING_PROMPT",
    AI_PROMPT_REWRITE = "AI_PROMPT_REWRITE",
    AI_PROMPT_SHORTEN = "AI_PROMPT_SHORTEN",
    AI_PROMPT_FORMAL = "AI_PROMPT_FORMAL",
    AI_PROMPT_GRAMMAR = "AI_PROMPT_GRAMMAR",
}

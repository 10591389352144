import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight, SquareCheckBig } from "lucide-react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "@/components/Button/Button";
import { AssistantItemBlock } from "@/components/Sidebar/Assistant/AssistantItemBlock";
import { AssistantItemsContainer } from "@/components/Sidebar/Assistant/AssistantItemsContainer";
import { JobRoleSelector } from "@/components/Sidebar/Assistant/JobRoleSelector";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import {
    getActiveJobRole,
    getAssistantItemsByQuery,
} from "@/store/assistant/selectors";
import { getActiveResumeDocumentType } from "@/store/resume/selectors";
import { AssistantDocumentItem, AssistantItemTypes } from "@/types/assistant";

export const ResumeAssistant = () => {
    const [activeTab, setActiveTab] = useState<"all" | "item">("all");
    const [activeItem, setActiveItem] = useState<AssistantDocumentItem | null>(
        null,
    );
    const activeJobRole = useAppSelector(getActiveJobRole);
    const [localRole, setLocalRole] = useState<string | null>(activeJobRole);
    const activeDocumentType = useAppSelector(getActiveResumeDocumentType);
    const assistantItems = useAppSelector(state =>
        getAssistantItemsByQuery(state, {
            type: AssistantItemTypes.DOCUMENT,
            id: activeDocumentType,
        }),
    );
    const allJobRoleItems: AssistantDocumentItem[] = useMemo(() => {
        return assistantItems.filter(
            item => item.jobRoleType === "All",
        ) as AssistantDocumentItem[];
    }, [assistantItems]);

    const filteredItems = useMemo(() => {
        if (!assistantItems?.length) return [];
        return assistantItems.filter(item => item.jobRoleType === localRole);
    }, [assistantItems, localRole]);

    useEffect(() => {
        setLocalRole(activeJobRole);
    }, [activeJobRole]);

    return (
        <div className="assistant-sections resume-assistant flex flex-col gap-3">
            <div className="relative overflow-hidden">
                <div className="flex">
                    <motion.div
                        className="w-full flex-shrink-0 px-4"
                        animate={{ x: activeTab === "all" ? "0%" : "-100%" }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 30,
                        }}
                    >
                        <AppText
                            className="mb-3"
                            variant="subheadings"
                        >
                            Resume Help
                        </AppText>
                        <JobRoleSelector noMargin />
                        <div className="mt-3 flex flex-col gap-3">
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key="items"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="flex flex-col gap-3"
                                >
                                    {filteredItems.map(item => (
                                        <Button
                                            key={item.properties.title}
                                            variant={"solid"}
                                            color={"softIndigo"}
                                            rightIcon={
                                                <ChevronRight
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            }
                                            onClick={() => {
                                                setActiveItem(
                                                    item as AssistantDocumentItem,
                                                );
                                                setActiveTab("item");
                                            }}
                                            contentClassName="justify-between w-full"
                                        >
                                            {item.properties.title}
                                        </Button>
                                    ))}
                                    {allJobRoleItems.map(item => (
                                        <Button
                                            key={item.properties.title}
                                            variant={"solid"}
                                            color={"softIndigo"}
                                            rightIcon={
                                                <ChevronRight
                                                    size={16}
                                                    strokeWidth={3}
                                                />
                                            }
                                            onClick={() => {
                                                setActiveItem(
                                                    item as AssistantDocumentItem,
                                                );
                                                setActiveTab("item");
                                            }}
                                            contentClassName="justify-between w-full"
                                        >
                                            {item.properties.title}
                                        </Button>
                                    ))}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </motion.div>
                    <motion.div
                        className="w-full flex-shrink-0 px-4"
                        animate={{
                            x: activeTab === "item" ? "-100%" : "0%",
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 30,
                        }}
                    >
                        <div className="flex flex-col gap-4 pb-10">
                            <AppText variant="subheadings">
                                {activeItem?.properties.title || "Resume Help"}
                            </AppText>
                            <Button
                                color={"softIndigo"}
                                onClick={() => setActiveTab("all")}
                                className="w-full px-20"
                                leftIcon={
                                    <ChevronLeft
                                        size={16}
                                        strokeWidth={3}
                                    />
                                }
                            >
                                Back to Resume Help
                            </Button>
                            {activeItem?.properties.content && (
                                <AssistantItemsContainer
                                    icon={<SquareCheckBig size={16} />}
                                    title={activeItem?.properties.title}
                                >
                                    <AssistantItemBlock
                                        canCopy={false}
                                        isExpandable={false}
                                        content={activeItem?.properties.content}
                                        isLastItem={true}
                                    />
                                </AssistantItemsContainer>
                            )}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

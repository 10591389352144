import { Resume } from "@/types";
import { ComponentPropsObject, SectionItemDetails } from "@/types/resume";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ResumeState {
    resumes: Resume[];
    activeResume?: Resume;
    images: Record<string, string>;
    examples: Resume[];
    hasUnsavedChanges: boolean;
}

const initialState: ResumeState = {
    resumes: [],
    activeResume: undefined,
    images: {},
    examples: [],
    hasUnsavedChanges: false,
};

export const resumeSlice = createSlice({
    name: "resume",
    initialState,
    reducers: {
        setActiveResume: (state, { payload }) => {
            if (!payload) {
                // If there is an activeResume in state, update it in the resumes state.
                if (state.activeResume) {
                    const resumes = [...state.resumes];
                    const activeResumeIndex = resumes.findIndex(resume => resume.id === state.activeResume?.id);
                    resumes[activeResumeIndex] = state.activeResume;
                    return {
                        ...state,
                        resumes,
                        activeResume: undefined,
                    };
                }

                return {
                    ...state,
                    activeResume: undefined,
                };
            }
            return {
                ...state,
                activeResume: {
                    ...state.activeResume,
                    ...payload,
                },
            };
        },
        updateResumeById: (state, { payload }: PayloadAction<{ id: string; resume: Resume; }>) => {
            return {
                ...state,
                resumes: state.resumes.map(resume =>
                    resume.id === payload.id ? payload.resume : resume,
                ),
            };
        },
        setResumes: (state, { payload }: PayloadAction<Resume[]>) => {
            return {
                ...state,
                resumes: payload,
            };
        },
        addResume: (state, { payload }: PayloadAction<Resume>) => {
            return {
                ...state,
                resumes: [...state.resumes, payload],
            };
        },
        removeResume: (state, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                resumes: state.resumes.filter(resume => resume.id !== payload),
            };
        },
        updateSectionHeaderField: (state, { payload }: PayloadAction<{ sectionId: string; value: ComponentPropsObject; unhideImage?: boolean; }>) => {
            const { sectionId, value, unhideImage } = payload;
            const { activeResume } = state;
            const currentHeader = activeResume?.sections[sectionId]?.header;

            if (!activeResume || !currentHeader) {
                return state;
            }

            const updatedSections = {
                ...activeResume.sections,
                [sectionId]: {
                    ...activeResume.sections[sectionId],
                    header: {
                        ...currentHeader,
                        ...value,
                    },
                    hiddenFields: unhideImage
                        ? activeResume.sections[sectionId].hiddenFields.filter(field => field !== "image")
                        : activeResume.sections[sectionId].hiddenFields
                },
            };

            return {
                ...state,
                activeResume: {
                    ...activeResume,
                    sections: updatedSections,
                },
            };
        },
        updateSectionBodyField: (state, { payload }: PayloadAction<{ sectionId: string, itemId?: string, value: SectionItemDetails | SectionItemDetails[]; itemIndex?: number; }>) => {
            const { sectionId, itemId, value, itemIndex } = payload;
            const { activeResume } = state;
            const currentBody = [...activeResume?.sections[sectionId]?.body ?? []];

            if (!activeResume || !currentBody) {
                return state;
            }

            if (itemId === undefined) {
                // No ID means the body is being updated entirely
                return {
                    ...state,
                    activeResume: {
                        ...activeResume,
                        sections: {
                            ...activeResume.sections,
                            [sectionId]: {
                                ...activeResume.sections[sectionId],
                                body: [...value as SectionItemDetails[]],
                            },
                        },
                    },
                };
            }

            const currValue = currentBody.find(item => item.__id === itemId);

            if (!currValue) {
                // Item being added to body
                const newBody = [...currentBody];
                newBody.splice(itemIndex ?? 0, 0, value as SectionItemDetails);

                return {
                    ...state,
                    activeResume: {
                        ...activeResume,
                        sections: {
                            ...activeResume.sections,
                            [sectionId]: {
                                ...activeResume.sections[sectionId],
                                body: newBody,
                            },
                        },
                    },
                };
            }

            const updatedBody = currentBody.map(item =>
                item.__id === itemId ? { ...item, ...value as SectionItemDetails } : item,
            );

            const updatedSections = {
                ...activeResume.sections,
                [sectionId]: {
                    ...activeResume.sections[sectionId],
                    body: updatedBody,
                },
            };

            return {
                ...state,
                activeResume: {
                    ...activeResume,
                    sections: updatedSections,
                },
            };
        },
        addResumeImage: (state, { payload }: PayloadAction<{ id: string; url: string; }>) => {
            return {
                ...state,
                images: {
                    ...state.images,
                    [payload.id]: payload.url,
                },
            };
        },
        removeResumeImage: (state, { payload }: PayloadAction<string>) => {
            const images = { ...state.images };
            delete images[payload];
            return {
                ...state,
                images,
            };

        },
        setExamples: (state, { payload }: PayloadAction<Resume[]>) => {
            return {
                ...state,
                examples: payload,
            };
        },
        updateExampleResume: (state, { payload }: PayloadAction<{ id: string; resume: Resume; }>) => {
            return {
                ...state,
                examples: state.examples.map(resume =>
                    resume.id === payload.id ? payload.resume : resume,
                ),
            };
        },
        setHasUnsavedChanges: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                hasUnsavedChanges: payload,
            };
        },
    },
});

export const { setActiveResume, setResumes, addResume, removeResume, updateSectionBodyField, updateSectionHeaderField, updateResumeById, addResumeImage, removeResumeImage, setExamples, updateExampleResume, setHasUnsavedChanges } =
    resumeSlice.actions;
export const { reducer: resumeReducer } = resumeSlice;
import { InfoTooltip } from "@/components/InfoTooltip/InfoTooltip";

export const TokenEstimationInfo = () => (
    <div className="col-span-1 rounded-lg bg-primary-50 p-4">
        <div className="text-xs text-neutral-700">
            <div className="mb-2 flex items-center">
                <h4 className="mr-2 font-semibold">Token Estimation Guide</h4>
                <InfoTooltip content="These are rough estimates based on the current pricing of the model used. Actual usage can be found in OpenAI and after the generation is complete." />
            </div>
            <ul className="">
                <li className="flex items-center">
                    <span className="mr-2">•</span>1 token ≈ 4 characters of
                    English text
                </li>
                <li className="flex items-center">
                    <span className="mr-2">•</span>
                    100 tokens ≈ 75 words
                </li>
            </ul>
        </div>
    </div>
);

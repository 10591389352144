import { BaseAiFeature } from "./BaseAiFeature";
import aiApi from "@/api/ai";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getAiToolboxInitialPrompt,
    getAiToolboxSelection,
    getAiToolboxPromptActionCode,
} from "@/store/app/selectors";
import { CreditActionCode } from "@/types/creditAction";

const getTitle = (creditActionCode: CreditActionCode) => {
    switch (creditActionCode) {
        case CreditActionCode.AI_PROMPT_REWRITE:
            return "Rewrite";
        case CreditActionCode.AI_PROMPT_GRAMMAR:
            return "Fix grammar";
        case CreditActionCode.AI_PROMPT_SHORTEN:
            return "Shorten";
        case CreditActionCode.AI_PROMPT_FORMAL:
            return "More formal";
        default:
            return "Quick Prompt";
    }
};

export const QuickPromptFeature = ({
    sectionType,
}: {
    sectionType?: string;
}) => {
    const dispatch = useAppDispatch();
    const creditActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const selection = useAppSelector(getAiToolboxSelection);
    const initialPrompt = useAppSelector(getAiToolboxInitialPrompt);

    const handleGenerate = async (input: string) => {
        const response = await aiApi.generateQuickPrompt(
            {
                temperature: 0.7,
                includeUsage: false,
                content: input,
                // mockResponse: true,
                creditActionCode,
                sectionType,
            },
            dispatch,
        );
        return response.output;
    };

    if (!creditActionCode) return null;

    return (
        <BaseAiFeature
            title={getTitle(creditActionCode)}
            creditActionCode={creditActionCode}
            onGenerate={handleGenerate}
            initialPrompt={initialPrompt}
            selection={selection}
            sectionType={sectionType}
        />
    );
};

export enum NotificationMessageType {
    ADMIN_ASSISTANT_ITEM_SAVE = "ADMIN_ASSISTANT_ITEM_SAVE",
    ADMIN_ASSISTANT_ITEM_DELETE = "ADMIN_ASSISTANT_ITEM_DELETE",
    ADMIN_SECTION_SAVE = "ADMIN_SECTION_SAVE",
    ADMIN_SECTION_DELETE = "ADMIN_SECTION_DELETE",
    ADMIN_USER_SAVE = "ADMIN_USER_SAVE",
    ADMIN_USER_DELETE = "ADMIN_USER_DELETE",
    ADMIN_TEMPLATE_SAVE = "ADMIN_TEMPLATE_SAVE",
    ADMIN_TEMPLATE_DELETE = "ADMIN_TEMPLATE_DELETE",
    ADMIN_DOCUMENT_TYPE_SAVE = "ADMIN_DOCUMENT_TYPE_SAVE",
    ADMIN_DOCUMENT_TYPE_DELETE = "ADMIN_DOCUMENT_TYPE_DELETE",
    ADMIN_CREDIT_ACTION_SAVE = "ADMIN_CREDIT_ACTION_SAVE",
    ADMIN_CREDIT_ACTION_DELETE = "ADMIN_CREDIT_ACTION_DELETE",
    ADMIN_CREDIT_TRANSACTION_SAVE = "ADMIN_CREDIT_TRANSACTION_SAVE",
    ADMIN_CREDIT_TRANSACTION_DELETE = "ADMIN_CREDIT_TRANSACTION_DELETE",
    PASSWORD_CHANGED = "PASSWORD_CHANGED",
    EMAIL_CHANGED = "EMAIL_CHANGED",
    DOWNLOAD_FAILED = "DOWNLOAD_FAILED",
    CREDIT_ACTION_FAILED = "CREDIT_ACTION_FAILED",
    MARKETING_OPT_IN_CHANGED = "MARKETING_OPT_IN_CHANGED",
    REARRANGE_SECTION_TOO_BIG = "REARRANGE_SECTION_TOO_BIG",
    SECTION_HEIGHT_OVERLOWING = "SECTION_HEIGHT_OVERLOWING",
    PREVIEW_FAILED = "PREVIEW_FAILED",
    PREFERENCES_SAVED = "PREFERENCES_SAVED",
    RESUME_ADD_ITEM = "RESUME_ADD_ITEM",
    RESUME_REMOVE_ITEM = "RESUME_REMOVE_ITEM",
    RESUME_ADD_SECTION = "RESUME_ADD_SECTION",
    RESUME_REMOVE_SECTION = "RESUME_REMOVE_SECTION",
    RESUME_UPDATE_FIELD = "RESUME_UPDATE_FIELD",
}

export enum NotificationType {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING",
}

export interface NotificationDetails {
    title: string;
    desc?: string | string[];
    messageType: NotificationMessageType;
    type: NotificationType;
}

export enum SideBarState {
    CLOSED = "CLOSED",
    ASSISTANT = "ASSISTANT",
    EDIT = "EDIT",
    STYLES = "STYLES",
}

export enum SaveTemplateState {
    OFFLINE = "OFFLINE",
    SAVING = "SAVING",
    SAVED = "SAVED",
    ERROR = "ERROR",
}

export enum TemplateLoadingState {
    INITIAL = "INITIAL",
    LOADING = "LOADING",
    READY = "READY",
}

export type ModalWidth =
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl";

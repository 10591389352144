import { Editor, EditorContent } from "@tiptap/react";
import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { EditorBubbleMenu } from "@/components/EditorComponents/EditorBubbleMenu";
import { FieldIcon } from "@/components/FieldIcon/FieldIcon";
import { getTextStyle } from "@/components/PdfDocument/utils/getStyles";
import { GLOBAL_STYLE_KEY } from "@/constants/resume";
import { EditorContext } from "@/context/EditorContext";
import { useAppSelector } from "@/hooks/types";
import { decodeHtml } from "@/pages/ResumeBuilder/utils";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { EditorContextType } from "@/types/editor";
import { ResumeStyles } from "@/types/resume";

interface BodyTextProps {
    index?: number;
    id: string;
    value?: string;
    sectionId: string;
    itemId?: string;
    propType: "header" | "body";
    onClick?: (ids: { sectionId: string; itemId?: string }) => void;
    icon?: string;
    placeholder?: string;
    stylesConfig: ResumeStyles;
    isStaticHeader?: boolean;
}

export const BodyText = ({
    id,
    value,
    sectionId,
    itemId,
    propType,
    onClick,
    icon,
    placeholder,
    stylesConfig,
    isStaticHeader,
}: BodyTextProps) => {
    const { setActiveEditor, addEditor, getEditor } = useContext(
        EditorContext,
    ) as EditorContextType;
    const templateLoadingState = useAppSelector(getTemplateLoadingState);
    const editorId = useMemo(
        () => `${sectionId}-${id}-${itemId}`,
        [sectionId, id, itemId],
    );
    const [localEditor, setLocalEditor] = useState<Editor | null>(
        getEditor(editorId) || null,
    );

    const initializeEditor = useCallback(() => {
        const currEditor = getEditor(editorId);
        if (!currEditor) {
            const newEditor = addEditor(
                editorId,
                "",
                itemId,
                propType,
                sectionId,
                id,
                placeholder,
                undefined,
                icon,
                isStaticHeader,
            );
            newEditor.commands.setContent(decodeHtml(value ?? ""));

            setLocalEditor(newEditor);
        } else {
            setLocalEditor(currEditor);
        }
    }, [
        addEditor,
        editorId,
        getEditor,
        id,
        icon,
        isStaticHeader,
        itemId,
        placeholder,
        propType,
        sectionId,
        value,
    ]);

    useEffect(() => {
        initializeEditor();
    }, [initializeEditor]);

    const textStyle = useMemo(
        () =>
            getTextStyle(
                stylesConfig?.[GLOBAL_STYLE_KEY]?.fontSize ?? "",
                "p",
                false,
            ),
        [stylesConfig],
    );

    if (icon) {
        textStyle.display = "flex";
        textStyle.alignItems = "flex-start";
    }

    const onFocus = useCallback(() => {
        setActiveEditor(editorId);
        onClick?.({ sectionId, itemId });
    }, [setActiveEditor, editorId, onClick, sectionId, itemId]);

    return (
        <div
            style={{
                wordBreak: "break-word",
                ...textStyle,
            }}
        >
            {icon && (
                <FieldIcon
                    name={icon}
                    stylesConfig={stylesConfig}
                />
            )}
            <EditorContent
                editor={localEditor}
                onFocus={onFocus}
            />
            <EditorBubbleMenu editor={localEditor} />
        </div>
    );
};

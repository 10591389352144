import clsx from "clsx";
import { X } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { Checkbox } from "@/components/form/Checkbox";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getFilteredPreferences } from "@/pages/Preferences/util";
import { getActiveJobRole } from "@/store/assistant/selectors";
import { setActiveJobRole } from "@/store/assistant/slice";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import { getUserPreferences } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";

const JobRoleModal = () => {
    const isOpen = useAppSelector(
        getIsModalOpen(ModalTypes.ASSISTANT_JOB_ROLE),
    );
    const dispatch = useAppDispatch();
    const activeJobRole = useAppSelector(getActiveJobRole);
    const [selectedRole, setSelectedRole] = useState<string>(activeJobRole);
    const userPreferences = useAppSelector(getUserPreferences);
    const isPreferencesModalOpen = useAppSelector(
        getIsModalOpen(ModalTypes.ASSISTANT_PREFERENCES),
    );
    const [isLoadingPreferences, setIsLoadingPreferences] = useState(false);
    const preferencesList = useMemo(
        () => getFilteredPreferences("jobRoles", userPreferences?.jobRoles),
        [userPreferences],
    );

    const onClose = useCallback(() => {
        if (!isPreferencesModalOpen) {
            dispatch(closeModal(ModalTypes.ASSISTANT_JOB_ROLE));
        }
    }, [dispatch, isPreferencesModalOpen]);

    useEffect(() => {
        if (!isPreferencesModalOpen) {
            setIsLoadingPreferences(false);
        }
    }, [isPreferencesModalOpen]);

    useEffect(() => {}, [preferencesList, selectedRole]);

    const onUpdateRole = useCallback(() => {
        if (!preferencesList.includes(selectedRole)) {
            // If preferences list doesn't include selected role, clear the active role
            dispatch(setActiveJobRole(""));
            onClose();
            return;
        }
        dispatch(setActiveJobRole(selectedRole));
        onClose();
    }, [preferencesList, selectedRole, dispatch, onClose]);

    const onEditPreferences = useCallback(() => {
        setIsLoadingPreferences(true);
        dispatch(
            openModal({
                modalType: ModalTypes.ASSISTANT_PREFERENCES,
                props: {
                    preference: "jobRoles",
                    title: "Update role preferences",
                    description:
                        "What type of role(s) are you currently working in or most interested in pursuing?",
                },
            }),
        );
    }, [dispatch]);

    return (
        <Modal
            modalType={ModalTypes.ASSISTANT_JOB_ROLE}
            open={isOpen}
            title="Resume Role"
            width="2xl"
            onClose={onClose}
            overrideClose={true}
            footer={
                <ModalFooter
                    primaryActionText="Update Role"
                    primaryActionOnClick={onUpdateRole}
                    secondaryActionText="Cancel"
                    secondaryActionOnClick={onClose}
                />
            }
        >
            <div>
                <AppText
                    variant="regular"
                    className="mb-4 text-neutral-900"
                >
                    Select a job role from your preferences to get specific
                    advice, tips and examples that match the role you&apos;re
                    aiming for.
                </AppText>
                {preferencesList && preferencesList?.length > 0 ? (
                    <>
                        <div className="flex h-9 items-center justify-between">
                            <AppText variant="labelsbuttons">
                                Your Roles:
                            </AppText>
                            {selectedRole && (
                                <Button
                                    variant={"text"}
                                    color={"grey"}
                                    rightIcon={<X size={16} />}
                                    className="!px-2 py-1"
                                    onClick={() => {
                                        setSelectedRole("");
                                    }}
                                >
                                    Clear Role
                                </Button>
                            )}
                        </div>
                        <div className="relative grid max-h-[250px] min-h-[100px] overflow-y-auto py-2">
                            <div
                                className={clsx(
                                    "grid grid-cols-2 gap-x-4 gap-y-3 px-1 transition-opacity delay-300 duration-300 ease-in-out",
                                    isLoadingPreferences
                                        ? "opacity-0"
                                        : "opacity-100",
                                )}
                            >
                                {preferencesList.map(role => (
                                    <div key={role}>
                                        <Checkbox
                                            id={role}
                                            label={role}
                                            checked={selectedRole === role}
                                            onChange={() =>
                                                setSelectedRole(role)
                                            }
                                            rounded
                                        />
                                    </div>
                                ))}
                            </div>
                            <div
                                className={clsx(
                                    "pointer-events-none absolute left-0 top-1/3 flex w-full items-center justify-center gap-4 rounded-lg p-4 ring-neutral-200 transition-opacity delay-300 duration-300 ease-in-out",
                                    isLoadingPreferences
                                        ? "opacity-100"
                                        : "opacity-0",
                                )}
                            >
                                <LoadingSpinner />
                            </div>
                        </div>
                        <div className="mt-8 flex items-center justify-center gap-4 rounded-lg bg-neutral-100 px-4 py-3 text-neutral-700 ">
                            <div className="flex flex-col gap-2">
                                <AppText variant="contextheading">
                                    Looking for more options?
                                </AppText>
                                <AppText variant="regular">
                                    Adjust preferences anytime to refine the
                                    role options and ensure your resume stays
                                    aligned with your career goals.
                                </AppText>
                            </div>
                            <Button
                                variant={"solid"}
                                color={"softIndigo"}
                                className="!w-72"
                                onClick={onEditPreferences}
                            >
                                Edit Preferences
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="mt-8 flex items-center justify-center gap-10 rounded-lg bg-neutral-100 px-4 py-3 text-neutral-700">
                        <div className="flex flex-col gap-2">
                            <AppText variant="contextheading">
                                No role preferences set
                            </AppText>
                            <AppText variant="regular">
                                Update your preferences to choose a role and
                                unlock tailored examples and advice.
                            </AppText>
                        </div>
                        <Button
                            variant={"solid"}
                            color={"softIndigo"}
                            className="w-64"
                            onClick={onEditPreferences}
                        >
                            Set Preferences
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default JobRoleModal;

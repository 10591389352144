import { useState, useEffect, useMemo } from "react";
import { AiInputOutput } from "../AiInputOutput";
import { AiToolboxFooter } from "../AiToolboxFooter";
import { AiToolboxHeader } from "../AiToolboxHeader";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useContentEditor } from "@/hooks/useContentEditor";
import { useTransaction } from "@/hooks/useTransaction";
import { getAiToolboxPromptActionCode } from "@/store/app/selectors";
import { resetAiToolbox } from "@/store/app/slice";
import { CreditActionCode } from "@/types/creditAction";
import { convertBulletPointsToHtml } from "@/utils/string";

interface BaseAiFeatureProps {
    onGenerate: (input: string) => Promise<string>;
    title: string;
    creditActionCode: CreditActionCode;
    maxLength?: number;
    initialPrompt?: string;
    sectionType?: string;
    selection?: {
        from: number;
        to: number;
    };
}

export const BaseAiFeature = ({
    onGenerate,
    title,
    creditActionCode,
    maxLength = 500,
    initialPrompt = "",
    selection,
    sectionType,
}: BaseAiFeatureProps) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [userInput, setUserInput] = useState(initialPrompt);
    const [generatedOutput, setGeneratedOutput] = useState("");
    const promptActionCode = useAppSelector(getAiToolboxPromptActionCode);
    const { validateAndAddTransaction, getCreditActionCostByCode } =
        useTransaction();
    const { setActiveEditorContent } = useContentEditor();
    const dispatch = useAppDispatch();

    const generateActionCost = getCreditActionCostByCode(creditActionCode);

    useEffect(() => {
        if (initialPrompt) {
            validateAndGenerate();
        }
    }, [initialPrompt]); // eslint-disable-line react-hooks/exhaustive-deps

    const validateAndGenerate = async () => {
        setIsGenerating(true);
        try {
            await validateAndAddTransaction({
                actionCode: creditActionCode,
                amount: generateActionCost,
            });

            const output = await onGenerate(userInput);
            setGeneratedOutput(output);
        } catch (error) {
            setGeneratedOutput("");
        } finally {
            setIsGenerating(false);
        }
    };

    const handleClear = () => {
        setUserInput("");
        setGeneratedOutput("");
    };

    const handleClose = () => {
        dispatch(resetAiToolbox());
        setGeneratedOutput("");
    };

    const handleBack = () => {
        if (initialPrompt) {
            dispatch(resetAiToolbox());
        }
        setGeneratedOutput("");
    };

    const handleInsert = () => {
        if (selection) {
            setActiveEditorContent(generatedOutput, selection);
        } else {
            if (
                promptActionCode === CreditActionCode.AI_GENERATE_BULLET_POINTS
            ) {
                const formattedOutput =
                    convertBulletPointsToHtml(generatedOutput);
                setActiveEditorContent(formattedOutput);
            } else {
                setActiveEditorContent(generatedOutput);
            }
        }
    };

    const subtitle = useMemo(() => {
        if (initialPrompt) {
            return `${title} - "${initialPrompt}"`;
        }
        return generatedOutput ? userInput : "";
    }, [initialPrompt, generatedOutput, title, userInput]);

    return (
        <div className="w-full rounded-lg bg-white shadow-lg ring-2 ring-neutral-300">
            <AiToolboxHeader subtitle={subtitle} />
            <div className="p-3">
                <AiInputOutput
                    inputValue={userInput}
                    outputValue={generatedOutput}
                    onInputChange={setUserInput}
                    onOutputChange={setGeneratedOutput}
                    maxLength={maxLength}
                    animateOutput
                    loading={isGenerating}
                    creditActionCode={creditActionCode}
                    sectionType={sectionType}
                />
            </div>
            <AiToolboxFooter
                estimatedCredits={generateActionCost}
                onClear={handleClear}
                onGenerate={validateAndGenerate}
                onInsert={handleInsert}
                isGenerating={isGenerating}
                hasInput={!!userInput}
                generatedOutput={generatedOutput}
                onClose={handleClose}
                onBack={handleBack}
            />
        </div>
    );
};

import { useState } from "react";
import { AIGenerationStats } from "./AIGenerationStats";
import aiApi, { AiResponse } from "@/api/ai";
import { useAppSelector } from "@/hooks/types";
import { AIPreferencesConfig } from "@/pages/Admin/AI/AIPreferencesConfig";
import { AiBulletPointsFeature } from "@/pages/Admin/AI/AiBulletPointsFeature";
import { AiQuickPromptsFeature } from "@/pages/Admin/AI/AiQuickPromptsFeature";
import { AiSummaryFeature } from "@/pages/Admin/AI/AiSummaryFeature";
import { AdminFormContainer } from "@/pages/Admin/AdminFormContainer";
import {
    generateBulletPointsPrompt,
    generateSummaryPrompt,
    generateWritePrompt,
} from "@/pages/Admin/utils/generateAiPrompt";
import { getUserId } from "@/store/user/selectors";
import { AdminNavigationItem } from "@/types/admin";
import { CreditActionCode } from "@/types/creditAction";

const aiDashboardNavItems: AdminNavigationItem[] = [
    { id: "ai-summary", label: "AI Summary" },
    { id: "ai-bullet-points", label: "AI Bullet Points" },
    { id: "ai-quick-prompts", label: "AI Quick Prompts" },
];

export type AIModel = "gpt-4o-mini" | "gpt-3.5-turbo";

interface AIModelConfig {
    model: AIModel;
    temperature: number;
}

export const AiDashboard = () => {
    const [activeTab, setActiveTab] = useState<string>("ai-summary");
    const [modelConfig, setModelConfig] = useState<AIModelConfig>({
        model: "gpt-4o-mini",
        temperature: 0.7,
    });
    const userId = useAppSelector(getUserId);
    const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
    const [selectedExperience, setSelectedExperience] = useState<string>("Any");
    const [additionalContext, setAdditionalContext] = useState<string>("");
    const [generatedOutput, setGeneratedOutput] = useState<string>("");
    const [sectionName, setSectionName] = useState<string>("");
    const [writePrompt, setWritePrompt] = useState<CreditActionCode>(
        CreditActionCode.AI_PROMPT_REWRITE,
    );
    const [isGenerating, setIsGenerating] = useState(false);
    const [currentStats, setCurrentStats] = useState({
        promptTokens: 0,
        completionTokens: 0,
        totalTokens: 0,
        estimatedCost: 0,
    });
    const [usageStats, setUsageStats] = useState({
        promptTokens: 0,
        completionTokens: 0,
        totalTokens: 0,
        estimatedCost: 0,
    });
    const [isEditingPrompt, setIsEditingPrompt] = useState(false);
    const [customPrompt, setCustomPrompt] = useState<string>("");

    const toggleJobRole = (role: string) => {
        setSelectedJobRoles(prev =>
            prev.includes(role)
                ? prev.filter(r => r !== role)
                : [...prev, role],
        );
    };

    const toggleIndustry = (industry: string) => {
        setSelectedIndustries(prev =>
            prev.includes(industry)
                ? prev.filter(i => i !== industry)
                : [...prev, industry],
        );
    };

    const handleGenerate = async () => {
        // Check estimated tokens before generating
        const promptText = isEditingPrompt ? customPrompt : generatePrompt();
        const estimatedTokens = Math.ceil(promptText.length / 4);

        if (estimatedTokens > 500) {
            const shouldProceed = window.confirm(
                `Warning: Estimated prompt token count (${estimatedTokens}) exceeds recommended limit of 500 tokens. This may result in higher costs and potential truncation. Do you want to proceed?`,
            );

            if (!shouldProceed) {
                return;
            }
        }

        setIsGenerating(true);
        try {
            let response: AiResponse = {
                output: "",
                usage: {
                    prompt_tokens: 0,
                    completion_tokens: 0,
                    total_tokens: 0,
                    estimated_cost: 0,
                },
            };

            if (activeTab === "ai-summary") {
                response = await aiApi.generateResumeSummary({
                    temperature: modelConfig.temperature,
                    includeUsage: true,
                    overridePrompt: promptText ?? "",
                    adminId: userId,
                });
            }

            if (activeTab === "ai-bullet-points") {
                response = await aiApi.generateBulletPoints({
                    temperature: modelConfig.temperature,
                    includeUsage: true,
                    overridePrompt: promptText ?? "",
                    sectionName: sectionName,
                    adminId: userId,
                });
            }

            if (activeTab === "ai-quick-prompts") {
                response = await aiApi.generateQuickPrompt({
                    temperature: modelConfig.temperature,
                    includeUsage: true,
                    content: additionalContext,
                    creditActionCode: writePrompt,
                    adminId: userId,
                });
            }

            setGeneratedOutput(response.output);
            if (!response.usage) {
                return;
            }

            // Set current generation stats
            setCurrentStats({
                promptTokens: response.usage.prompt_tokens,
                completionTokens: response.usage.completion_tokens,
                totalTokens: response.usage.total_tokens,
                estimatedCost: response.usage.estimated_cost,
            });

            // Update running totals
            if (response.usage) {
                setUsageStats(prev => ({
                    promptTokens:
                        prev.promptTokens + response.usage.prompt_tokens,
                    completionTokens:
                        prev.completionTokens +
                        response.usage.completion_tokens,
                    totalTokens: prev.totalTokens + response.usage.total_tokens,
                    estimatedCost:
                        prev.estimatedCost + response.usage.estimated_cost,
                }));
            }
        } catch (error) {
            console.error("Error generating content:", error);
            setGeneratedOutput("Error: " + error);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleClear = () => {
        setGeneratedOutput("");
        setCurrentStats({
            promptTokens: 0,
            completionTokens: 0,
            totalTokens: 0,
            estimatedCost: 0,
        });
    };

    const handleResetTotals = () => {
        setUsageStats({
            promptTokens: 0,
            completionTokens: 0,
            totalTokens: 0,
            estimatedCost: 0,
        });
    };

    const handleResetJobRoles = () => {
        setSelectedJobRoles([]);
    };

    const handleResetExperience = () => {
        setSelectedExperience("");
    };

    const handleResetIndustries = () => {
        setSelectedIndustries([]);
    };

    const generatePrompt = () => {
        if (activeTab === "ai-summary") {
            return generateSummaryPrompt(
                customPrompt,
                selectedJobRoles,
                selectedExperience,
                selectedIndustries,
                additionalContext,
            );
        }
        if (activeTab === "ai-bullet-points") {
            return generateBulletPointsPrompt(
                customPrompt,
                additionalContext,
                sectionName,
            );
        }
        if (activeTab === "ai-quick-prompts") {
            return generateWritePrompt(writePrompt, additionalContext);
        }
        return "Invalid feature";
    };

    const getTokenCost = (model: AIModel) => {
        switch (model) {
            case "gpt-4o-mini":
                return {
                    prompt: 0.15 / 1000, // $0.15 per 1k tokens
                    completion: 0.15 / 1000, // $0.15 per 1k tokens
                };
            case "gpt-3.5-turbo":
                return {
                    prompt: 0.0005 / 1000, // $0.0005 per 1k tokens
                    completion: 0.0015 / 1000, // $0.0015 per 1k tokens
                };
            default:
                return {
                    prompt: 0,
                    completion: 0,
                };
        }
    };

    const handleSavePrompt = () => {
        setIsEditingPrompt(false);
    };

    return (
        <AdminFormContainer
            pageTitle="AI Dashboard"
            navigationItems={aiDashboardNavItems}
            onNavigationItemClick={setActiveTab}
            activeItemId={activeTab}
        >
            <div className="grid grid-cols-12 gap-8 px-8">
                <AIPreferencesConfig
                    toggleJobRole={toggleJobRole}
                    toggleIndustry={toggleIndustry}
                    setSelectedExperience={setSelectedExperience}
                    resetJobRoles={handleResetJobRoles}
                    resetIndustries={handleResetIndustries}
                    resetExperience={handleResetExperience}
                    setContext={setAdditionalContext}
                    selectedJobRoles={selectedJobRoles}
                    selectedIndustries={selectedIndustries}
                    selectedExperience={selectedExperience}
                    additionalContext={additionalContext}
                    modelConfig={modelConfig}
                    setModelConfig={setModelConfig}
                    activeTab={activeTab}
                    sectionName={sectionName}
                    setSectionName={setSectionName}
                    setWritePrompt={setWritePrompt}
                    writePrompt={writePrompt}
                />

                <div className="col-span-8 rounded-lg border p-4">
                    {activeTab === "ai-summary" && (
                        <AiSummaryFeature
                            model={modelConfig.model}
                            temperature={modelConfig.temperature}
                            isEditingPrompt={isEditingPrompt}
                            setIsEditingPrompt={setIsEditingPrompt}
                            customPrompt={customPrompt}
                            setCustomPrompt={setCustomPrompt}
                            handleSavePrompt={handleSavePrompt}
                            generatePrompt={generatePrompt}
                            getTokenCost={getTokenCost}
                        />
                    )}
                    {activeTab === "ai-bullet-points" && (
                        <AiBulletPointsFeature
                            model={modelConfig.model}
                            temperature={modelConfig.temperature}
                            isEditingPrompt={isEditingPrompt}
                            setIsEditingPrompt={setIsEditingPrompt}
                            customPrompt={customPrompt}
                            setCustomPrompt={setCustomPrompt}
                            handleSavePrompt={handleSavePrompt}
                            generatePrompt={generatePrompt}
                            getTokenCost={getTokenCost}
                            sectionName={sectionName}
                        />
                    )}
                    {activeTab === "ai-quick-prompts" && (
                        <AiQuickPromptsFeature
                            model={modelConfig.model}
                            temperature={modelConfig.temperature}
                            generatePrompt={generatePrompt}
                            getTokenCost={getTokenCost}
                            isEditingPrompt={isEditingPrompt}
                            setIsEditingPrompt={setIsEditingPrompt}
                            customPrompt={customPrompt}
                            setCustomPrompt={setCustomPrompt}
                            handleSavePrompt={handleSavePrompt}
                            writePrompt={writePrompt}
                        />
                    )}

                    <div className="mb-4 flex items-center justify-between">
                        <h2 className="text-xl font-semibold">AI Output</h2>
                        <div className="space-x-2">
                            <button
                                onClick={handleClear}
                                disabled={isGenerating || !generatedOutput}
                                className={`rounded-md px-4 py-2 text-sm font-medium text-white ${
                                    isGenerating || !generatedOutput
                                        ? "cursor-not-allowed bg-neutral-400"
                                        : "bg-neutral-700 hover:bg-neutral-900"
                                }`}
                            >
                                Clear
                            </button>
                            <button
                                onClick={handleGenerate}
                                disabled={isGenerating}
                                className={`rounded-md px-4 py-2 text-sm font-medium text-white ${
                                    isGenerating
                                        ? "cursor-not-allowed bg-primary-400"
                                        : "bg-primary-700 hover:bg-primary-900"
                                }`}
                            >
                                {isGenerating ? "Generating..." : "Generate"}
                            </button>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="min-h-[100px] rounded-lg border border-gray-200 bg-white p-4 text-xs">
                            {isGenerating ? (
                                <div className="flex h-full items-center justify-center">
                                    <div className="text-xs text-gray-500">
                                        Generating...
                                    </div>
                                </div>
                            ) : generatedOutput ? (
                                <div className="prose max-w-none">
                                    <div className="whitespace-pre-wrap text-xs">
                                        {generatedOutput}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex h-full items-center justify-center">
                                    <div className="text-xs text-gray-500">
                                        AI response will appear here
                                    </div>
                                </div>
                            )}
                        </div>
                        <AIGenerationStats
                            currentStats={currentStats}
                            totalStats={usageStats}
                            onResetTotals={handleResetTotals}
                            model={modelConfig.model}
                            temperature={modelConfig.temperature}
                        />
                    </div>
                </div>
            </div>
        </AdminFormContainer>
    );
};

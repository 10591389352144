import { StripeItem, UserPlan } from "@/types/billing";
import { apiRequest } from "./request";
import { PaymentStatusResponse } from "@/types/payment";

const initiateStripeCheckout = (data: { priceId: string, redirectUrl: string; stripeCustomerId: string; }) => apiRequest<{ id: string; }>("/billing/initiate-session", "POST", data);

const getPortalSession = (data: { returnUrl: string; stripeCustomerId: string; }) => apiRequest<{ url: string; }>("/billing/portal-session", "POST", data);

const getUserPlan = (usedId: string) => apiRequest<UserPlan>(`/billing/get-plan/${usedId}`, "GET");

const getPaymentStatus = (sessionId: string) => apiRequest<PaymentStatusResponse>(`/billing/payment-status/${sessionId}`, "GET");

const getStripeProducts = () => apiRequest<StripeItem[]>("/billing/products", "GET");

export default {
    initiateStripeCheckout,
    getPortalSession,
    getUserPlan,
    getPaymentStatus,
    getStripeProducts,
};

import { useEffect, useState, useCallback } from "react";
import { AiSummaryFeature } from "./features/AiSummaryFeature";
import { QuickPromptFeature } from "./features/QuickPromptFeature";
import { AiBulletPointsFeature } from "@/components/AiToolbox/features/AiBulletPointsFeature";
import { useAppSelector } from "@/hooks/types";
import { useContentEditor } from "@/hooks/useContentEditor";
import { getAiToolboxState } from "@/store/app/selectors";
import { getActiveSectionDetails } from "@/store/resume/selectors";
import { getSectionConfigById } from "@/store/sections/selectors";
import { CreditActionCode } from "@/types/creditAction";

export const AiToolbox = () => {
    const { isVisible, promptActionCode } = useAppSelector(getAiToolboxState);
    const { activeEditor } = useContentEditor();
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const activeSection = useAppSelector(getActiveSectionDetails);
    const activeSectionConfig = useAppSelector(state =>
        getSectionConfigById(state, activeSection?.sectionConfigId ?? ""),
    );

    const updateCursorPosition = useCallback((editor: any) => {
        if (editor?.state) {
            const { from } = editor.state.selection;
            const pos = editor.view.coordsAtPos(from);
            setCursorPosition(pos.top + window.scrollY); // Add scroll offset
        }
    }, []);

    useEffect(() => {
        if (!activeEditor) return;

        // Update position when toolbox becomes visible
        if (isVisible) {
            updateCursorPosition(activeEditor);
        }

        // Subscribe to selection changes when toolbox is not visible
        const handleSelectionUpdate = ({ editor }: { editor: any }) => {
            if (!isVisible) {
                updateCursorPosition(editor);
            }
        };

        activeEditor.on("selectionUpdate", handleSelectionUpdate);

        return () => {
            activeEditor.off("selectionUpdate", handleSelectionUpdate);
        };
    }, [activeEditor, updateCursorPosition, isVisible]);

    const renderFeature = () => {
        switch (promptActionCode) {
            case CreditActionCode.AI_GENERATE_SUMMARY:
                return (
                    <AiSummaryFeature
                        sectionType={activeSectionConfig?.sectionType}
                    />
                );
            case CreditActionCode.AI_PROMPT_FORMAL:
            case CreditActionCode.AI_PROMPT_SHORTEN:
            case CreditActionCode.AI_PROMPT_REWRITE:
            case CreditActionCode.AI_PROMPT_GRAMMAR:
                return (
                    <QuickPromptFeature
                        sectionType={activeSectionConfig?.sectionType}
                    />
                );
            case CreditActionCode.AI_GENERATE_BULLET_POINTS:
                return (
                    <AiBulletPointsFeature
                        sectionType={activeSectionConfig?.sectionType}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="h-0 w-0" /> {/* Position anchor */}
            <div
                id="magic-toolbox"
                style={{
                    top: cursorPosition ? `${cursorPosition - 30}px` : "10%",
                    position: "fixed",
                    transform: `translate(-50%, 0)`,
                }}
                className={`left-1/2 z-[1000] flex w-[700px] items-start justify-center gap-2 px-2 pb-4 font-body
                    transition-opacity duration-200 ease-in-out
                    ${
                        isVisible
                            ? "opacity-100"
                            : "pointer-events-none opacity-0"
                    }`}
            >
                {renderFeature()}
            </div>
        </>
    );
};

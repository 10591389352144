import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    defer,
} from "react-router-dom";
import { SectionForm } from "../pages/Admin/Sections/SectionForm";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import {
    getAdminAssistantItem,
    getAdminCreditAction,
    getAdminDocumentType,
    getAdminSection,
    getAdminTemplate,
    getAdminUser,
} from "./loaders";
import ModalManager from "@/components/Modals/ModalManager";
import { AuthLayout, AppLayout } from "@/layouts";
import { AdminDashboardLayout } from "@/layouts/AdminDashboardLayout";
import { AuthWrapper } from "@/layouts/AuthWrapper";
import { AiDashboard } from "@/pages/Admin/AI/AiDashboard";
import { AssistantItemForm } from "@/pages/Admin/Assistant/AssistantItemForm";
import { AssistantItems } from "@/pages/Admin/Assistant/AssistantItems";
import { CreditActionForm } from "@/pages/Admin/CreditActions/CreditActionForm";
import { CreditActionsPage } from "@/pages/Admin/CreditActions/CreditActions";
import { DocumentTypeForm } from "@/pages/Admin/DocumentTypes/DocumentTypeForm";
import { DocumentTypes } from "@/pages/Admin/DocumentTypes/DocumentTypes";
import { Sections } from "@/pages/Admin/Sections/Sections";
import { StatsPage } from "@/pages/Admin/Stats/StatsPage";
import { StylePage } from "@/pages/Admin/StylePage/StylePage";
import { TemplateForm } from "@/pages/Admin/Templates/TemplateForm";
import { Templates } from "@/pages/Admin/Templates/Templates";
import { UserForm } from "@/pages/Admin/Users/UserForm";
import { Users } from "@/pages/Admin/Users/Users";
import { CreateResumePage } from "@/pages/CreateResume/CreateResumePage";
import { Dashboard } from "@/pages/Dashboard/Dashboard";
import { ErrorPage } from "@/pages/Error/ErrorPage";
import { ExamplesPage } from "@/pages/Examples/ExamplesPage";
import { ForgotPasswordPage } from "@/pages/ForgotPassword/ForgotPasswordPage";
import { LoginPage } from "@/pages/Login/LoginPage";
import { LogoutPage } from "@/pages/Logout/LogoutPage";
import { PreferencesPage } from "@/pages/Preferences/PreferencesPage";
import { PreviewPage } from "@/pages/Preview/PreviewPage";
import { ProfilePage } from "@/pages/Profile/ProfilePage";
import { ResetPasswordPage } from "@/pages/ResetPassword/ResetPasswordPage";
import { ResumeBuilder } from "@/pages/ResumeBuilder/ResumeBuilder";
import { ResumeSaveWrapper } from "@/pages/ResumeBuilder/ResumeSaveWrapper";
import { SignUpPage } from "@/pages/Signup/SignUpPage";
import { VerifyEmailPage } from "@/pages/VerifyEmail/VerifyEmailPage";
import { ErrorTypes } from "@/types";
import { AppRoutes } from "@/types/routes";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />}>
            <Route element={<AuthWrapper />}>
                <Route
                    path={AppRoutes.Login}
                    element={<LoginPage />}
                />
                <Route
                    path={AppRoutes.Signup}
                    element={<SignUpPage />}
                />
                <Route
                    path={AppRoutes.Verify}
                    element={<VerifyEmailPage />}
                />
                <Route
                    path={AppRoutes.ForgotPassword}
                    element={<ForgotPasswordPage />}
                />
                <Route
                    path={AppRoutes.ResetPassword}
                    element={<ResetPasswordPage />}
                />
            </Route>
            <Route
                path={AppRoutes.Logout}
                element={<LogoutPage />}
            />
            <Route
                element={
                    <ProtectedRoute>
                        <ResumeSaveWrapper>
                            <AppLayout />
                            <ModalManager />
                        </ResumeSaveWrapper>
                    </ProtectedRoute>
                }
            >
                <Route
                    path={AppRoutes.Dashboard}
                    element={<Dashboard />}
                ></Route>
                <Route
                    path={AppRoutes.Preferences}
                    element={<PreferencesPage />}
                ></Route>
                <Route
                    path={AppRoutes.Examples}
                    element={<ExamplesPage />}
                ></Route>
                <Route
                    path={AppRoutes.NewResume}
                    element={<CreateResumePage />}
                ></Route>
                <Route
                    path={AppRoutes.Preview}
                    element={<PreviewPage />}
                ></Route>
                <Route
                    path={AppRoutes.Resume}
                    element={<ResumeBuilder />}
                ></Route>
                <Route
                    path={AppRoutes.Profile}
                    element={<ProfilePage />}
                ></Route>
            </Route>
            <Route
                path={AppRoutes.Admin}
                element={
                    <AdminProtectedRoute>
                        <AdminDashboardLayout />
                    </AdminProtectedRoute>
                }
            >
                <Route
                    path={AppRoutes.Stats}
                    element={<StatsPage />}
                ></Route>
                <Route
                    path={AppRoutes.Ai}
                    element={<AiDashboard />}
                ></Route>
                <Route
                    path={AppRoutes.Users}
                    element={<Users />}
                ></Route>
                <Route
                    path={AppRoutes.User}
                    element={<UserForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminUser: getAdminUser(params.userId),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Sections}
                    element={<Sections />}
                ></Route>
                <Route
                    path={AppRoutes.Section}
                    element={<SectionForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminSection: getAdminSection(params.sectionId),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Styles}
                    element={<StylePage />}
                />
                <Route
                    path={AppRoutes.Templates}
                    element={<Templates />}
                ></Route>
                <Route
                    path={AppRoutes.Template}
                    element={<TemplateForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminTemplate: getAdminTemplate(
                                params.templateId,
                            ),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.CreditActions}
                    element={<CreditActionsPage />}
                ></Route>
                <Route
                    path={AppRoutes.CreditAction}
                    element={<CreditActionForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminCreditAction: getAdminCreditAction(
                                params.creditActionId,
                            ),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.Assistant}
                    element={<AssistantItems />}
                ></Route>
                <Route
                    path={AppRoutes.AssistantItem}
                    element={<AssistantItemForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminAssistantItem: getAdminAssistantItem(
                                params.assistantItemId,
                            ),
                        })
                    }
                ></Route>
                <Route
                    path={AppRoutes.DocumentTypes}
                    element={<DocumentTypes />}
                ></Route>
                <Route
                    path={AppRoutes.DocumentType}
                    element={<DocumentTypeForm />}
                    loader={({ params }) =>
                        defer({
                            getAdminDocumentType: getAdminDocumentType(
                                params.documentTypeId,
                            ),
                        })
                    }
                ></Route>
            </Route>
            <Route
                path="*"
                element={<ErrorPage error={ErrorTypes.notFound} />}
            ></Route>
        </Route>,
    ),
);

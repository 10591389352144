import clsx from "clsx";

interface ChipProps {
    children: React.ReactNode;
    onClick?: () => void;
    type: "primary" | "secondary" | "gradient";
    className?: string;
    borderless?: boolean;
}

const Chip = ({
    children,
    onClick,
    type,
    className,
    borderless,
}: ChipProps) => {
    switch (type) {
        case "primary":
            return (
                <div
                    className={clsx(
                        "align-items flex items-center gap-2 rounded-lg bg-primary-700 px-3 py-1 text-white",
                        className,
                        onClick
                            ? "cursor-pointer transition-colors hover:bg-primary-800"
                            : "",
                        borderless && "gradient-border-button-borderless",
                    )}
                    onClick={onClick}
                >
                    {children}
                </div>
            );
        case "secondary":
            return (
                <div
                    className={clsx(
                        "align-items flex items-center gap-2 rounded-lg bg-primary-100 px-3 py-1 text-primary-700",
                        className,
                        onClick
                            ? "cursor-pointer transition-colors hover:bg-primary-800"
                            : "",
                        borderless && "gradient-border-button-borderless",
                    )}
                    onClick={onClick}
                >
                    {children}
                </div>
            );
        case "gradient":
            return (
                <div
                    className={clsx(
                        "gradient-border-button",
                        className,
                        onClick && "cursor-pointer",
                        borderless && "gradient-border-button-borderless",
                    )}
                    onClick={onClick}
                >
                    {children}
                </div>
            );
    }
};

export default Chip;

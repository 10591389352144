import clsx from "clsx";
import { PlusSquare, Move } from "lucide-react";
import { useCallback } from "react";
import { Button } from "@/components/Button/Button";
import { EditorToolbar } from "@/components/Editor/EditorToolbar";
import { PreviewResumeButton } from "@/components/Modals/PreviewResumeModal/PreviewResumeButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowSideBar, getTemplateLoadingState } from "@/store/app/selectors";
import { openModal } from "@/store/modal/slice";
import { TemplateLoadingState } from "@/types/app";
import { ModalTypes } from "@/types/modal";

const SecondaryNavigation = () => {
    const sideBarOpen = useAppSelector(getShowSideBar);
    const dispatch = useAppDispatch();

    const handleOpenModal = useCallback(
        (modalType: ModalTypes) => {
            dispatch(openModal({ modalType }));
        },
        [dispatch],
    );

    const templateLoadingState = useAppSelector(getTemplateLoadingState);

    return (
        <div
            className={clsx(
                "fixed top-navigationHeight z-10 flex h-[calc(1*theme(spacing.navigationHeight))] w-full shrink-0 transform items-center border-b-[1px] border-t-[1px] border-neutral-300 bg-white px-3 duration-300 ease-in-out 2xl:h-navigationHeight",
                sideBarOpen ? "translate-x-[360px] max-xl:translate-x-0" : "",
            )}
        >
            <div className="relative flex w-full flex-row items-center justify-between">
                <div className="flex items-center gap-3">
                    <Button
                        onClick={() =>
                            handleOpenModal(ModalTypes.ADD_NEW_SECTION)
                        }
                        leftIcon={<PlusSquare className="h-5 w-5" />}
                        disabled={
                            templateLoadingState !== TemplateLoadingState.READY
                        }
                        className={BuilderTourStep.ADD_SECTIONS}
                    >
                        Add Section
                    </Button>
                    <Button
                        variant={"outline"}
                        color={"primary"}
                        onClick={() => handleOpenModal(ModalTypes.REARRANGE)}
                        leftIcon={<Move className="h-5 w-5" />}
                        disabled={
                            templateLoadingState !== TemplateLoadingState.READY
                        }
                        className={BuilderTourStep.REARRANGE_SECTIONS}
                    >
                        Rearrange
                    </Button>
                </div>
                <div className="flex-1 pl-1 xl:pl-3">
                    <EditorToolbar />
                </div>
            </div>
            <div
                className={clsx(
                    "absolute right-[76px] transform duration-300 ease-in-out",
                    sideBarOpen
                        ? "-translate-x-[0px] xl:-translate-x-[360px]"
                        : "translate-x-0",
                )}
            >
                <PreviewResumeButton />
            </div>
        </div>
    );
};

export default SecondaryNavigation;

import { useMemo } from "react";
import { CreditActionSummary } from "@/components/Credits/CreditActionSummary";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { ModalTypes } from "@/types/modal";

const InsufficientFundsModal = () => {
    const isOpen = useAppSelector(
        getIsModalOpen(ModalTypes.INSUFFICIENT_FUNDS),
    );
    const { getCreditActionByCode } = useTransaction();
    const modalProps = useAppSelector(
        getModalProps(ModalTypes.INSUFFICIENT_FUNDS),
    );
    const { actionCode } = modalProps ?? {};

    const action = useMemo(
        () => getCreditActionByCode(actionCode),
        [actionCode, getCreditActionByCode],
    );

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.INSUFFICIENT_FUNDS}
            title="Buy Credits"
            width="lg"
        >
            <div className="items-left flex flex-col gap-4">
                <AppText variant="regular">
                    You do not have enough credits to complete this action.
                </AppText>
                <CreditActionSummary
                    creditAction={action}
                    insufficientCreditsMessage="Purchase additional credits to proceed"
                    balanceAfterMessage="Balance after:"
                />
            </div>
        </Modal>
    );
};

export default InsufficientFundsModal;

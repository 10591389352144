import clsx from "clsx";
import { Check, Copy } from "lucide-react";
import { useState, useCallback, useEffect } from "react";
import { Button } from "@/components/Button/Button";

interface CopyButtonProps {
    content: string;
    size?: "small" | "default";
    variant?: "icon" | "full";
}

export const CopyButton = ({
    content,
    size = "default",
    variant = "icon",
}: CopyButtonProps) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopy = useCallback(() => {
        setIsCopied(true);
        // Strip HTML tags. This simplifies the pasting process into editors
        navigator.clipboard.writeText(content.replace(/<[^>]*>?/g, ""));
    }, [content]);

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => setIsCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return variant === "icon" ? (
        <button
            className={clsx(
                "flex items-center justify-center rounded-lg bg-primary-50 text-primary-700 hover:bg-primary-100",
                "transition-all duration-200 ease-in-out",
                isCopied && "scale-110",
                size === "small" ? "h-7 w-7" : "h-9 w-9",
            )}
            onClick={e => {
                e.stopPropagation();
                onCopy();
            }}
        >
            {isCopied ? (
                <Check
                    size={16}
                    strokeWidth={3}
                />
            ) : (
                <Copy
                    size={16}
                    strokeWidth={3}
                />
            )}
        </button>
    ) : (
        <Button
            onClick={onCopy}
            variant={"outline"}
            color={"primary"}
            size={size}
            leftIcon={
                isCopied ? (
                    <Check
                        strokeWidth={3}
                        size={16}
                    />
                ) : (
                    <Copy
                        strokeWidth={3}
                        size={16}
                    />
                )
            }
        >
            Copy
        </Button>
    );
};

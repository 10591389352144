import { ArrowRight, ChevronLeft } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminControlPanel } from "@/components/Admin/AdminControlPanel";
import { Button } from "@/components/Button/Button";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { DesktopOnly } from "@/components/Responsive/DesktopOnly";
import { LayoutToggles } from "@/components/Sidebar/Assistant/LayoutToggles";
import { SideBarField } from "@/components/Sidebar/SideBarField";
import { ColourPicker } from "@/components/Sidebar/Styles/ColourPicker";
import { FontSelector } from "@/components/Sidebar/Styles/FontSelector";
import { FontSizeSelector } from "@/components/Sidebar/Styles/FontSizeSelector";
import { SwitchField } from "@/components/SwitchToggle/SwitchField";
import { AppText } from "@/components/Text/AppText";
import {
    GLOBAL_STYLE_KEY,
    resumeAccentColours,
    resumeFontSizes,
    resumeFonts,
    resumeHeadingFontSizes,
} from "@/constants/resume";
import { createNewResume } from "@/helper/createNewResume";
import { translate } from "@/helper/translate";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import { CreateResumePreview } from "@/pages/CreateResume/CreateResumePreview";
import { openModal } from "@/store/modal/slice";
import {
    getUserDetails,
    getUserFullName,
    getUserReachedResumeLimit,
} from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { ColumnLayout, StyleConfig } from "@/types/resume";

export const CreateResumePage = () => {
    const userDetails = useAppSelector(getUserDetails);
    const userFullName = useAppSelector(getUserFullName);
    const reachedResumeLimit = useAppSelector(getUserReachedResumeLimit);
    const [backgroundColor, setBackgroundColor] = useState<string>(
        resumeAccentColours[0].value,
    );
    const [columnLayout, setColumnLayout] = useState<ColumnLayout>(
        ColumnLayout.SINGLE,
    );
    const [showImage, setShowImage] = useState<boolean>(false);
    const [showIcons, setShowIcons] = useState<boolean>(false);
    const [showUnderline, setShowUnderline] = useState<boolean>(false);
    const [activeFont, setActiveFont] = useState<string>(resumeFonts[0].value);
    const [fontSize, setFontSize] = useState<string>(resumeFontSizes[1].value);
    const [headingFontSize, setHeadingFontSize] = useState<string>(
        resumeHeadingFontSizes[1].value,
    );
    const [createAsTemplate, setCreateAsTemplate] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const handleNext = useCallback(() => {
        if (reachedResumeLimit) {
            dispatch(openModal({ modalType: ModalTypes.DOCUMENT_LIMIT }));
            return;
        }

        const newGlobalStyles: StyleConfig = {
            showIcons,
            backgroundColor,
            fontFamily: activeFont,
            fontSize,
            headingFontSize,
            underlineSectionHeadings: showUnderline,
        };

        createNewResume(navigate, {
            styles: {
                [GLOBAL_STYLE_KEY]: newGlobalStyles,
            },
            columnLayout,
            showImage,
            userDetails,
            createAsTemplate,
        });
    }, [
        activeFont,
        backgroundColor,
        columnLayout,
        dispatch,
        fontSize,
        headingFontSize,
        navigate,
        reachedResumeLimit,
        showIcons,
        showImage,
        showUnderline,
        userDetails,
        createAsTemplate,
    ]);

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Create Resume"
                description="Set up your resume layout and design"
            />
            <div className="mb-6 text-center">
                <AppText
                    variant="subheadings"
                    className="mb-2"
                >
                    Let’s set up your resume
                </AppText>
                <AppText
                    variant="labelsbuttons"
                    className="block text-neutral-700"
                >
                    Adjust the settings to see a live preview of your resume.
                </AppText>
                <AppText
                    variant="labelsbuttons"
                    className="text-neutral-700"
                >
                    You can change these settings later in the styles menu.
                </AppText>
            </div>
            <div className="flex items-start gap-6">
                <div className="grid grid-cols-1 gap-4  p-4">
                    <SideBarField title="Page Layout">
                        <LayoutToggles
                            onLayoutChange={layout => setColumnLayout(layout)}
                        />
                    </SideBarField>
                    <SideBarField title="Font">
                        <FontSelector
                            activeFont={activeFont}
                            onFontChange={font => setActiveFont(font)}
                        />
                    </SideBarField>
                    <SideBarField title="Heading Font Size">
                        <FontSizeSelector
                            size={headingFontSize}
                            onSizeChange={size => setHeadingFontSize(size)}
                        />
                    </SideBarField>
                    <SideBarField title="Text Font Size">
                        <FontSizeSelector
                            size={fontSize}
                            onSizeChange={size => setFontSize(size)}
                        />
                    </SideBarField>
                    <SideBarField
                        title={`Accent ${translate("colour", "title")}`}
                    >
                        <ColourPicker
                            onChange={setBackgroundColor}
                            activeColour={backgroundColor}
                        />
                    </SideBarField>
                    <SideBarField title="Show Icons">
                        <SwitchField
                            enabled={showIcons}
                            onToggle={() => setShowIcons(!showIcons)}
                        />
                    </SideBarField>
                    <SideBarField title="Show Header Image">
                        <SwitchField
                            enabled={showImage}
                            onToggle={() => setShowImage(!showImage)}
                        />
                    </SideBarField>
                    <SideBarField title="Underline Headings">
                        <SwitchField
                            enabled={showUnderline}
                            onToggle={() => setShowUnderline(!showUnderline)}
                        />
                    </SideBarField>
                </div>
                <CreateResumePreview
                    backgroundColor={backgroundColor}
                    columnLayout={columnLayout}
                    fontFamily={activeFont}
                    fontSize={fontSize}
                    showImage={showImage}
                    showIcons={showIcons}
                    showUnderline={showUnderline}
                    headingFontSize={headingFontSize}
                    userFullName={userFullName}
                    userEmail={userDetails?.email ?? "example@example.com"}
                />
            </div>
            <div className="mt-12 flex items-center justify-between bg-white">
                <Button
                    leftIcon={<ChevronLeft size={20} />}
                    onClick={goBack}
                    color={"grey"}
                    className="mr-16 w-24"
                >
                    Back
                </Button>
                <Button
                    rightIcon={<ArrowRight size={20} />}
                    onClick={handleNext}
                >
                    Get started
                </Button>
            </div>
            <AdminControlPanel>
                <AppText variant="labelsbuttons">Create as template?</AppText>
                <SwitchField
                    enabled={createAsTemplate}
                    onToggle={() => setCreateAsTemplate(!createAsTemplate)}
                />
            </AdminControlPanel>
            <DesktopOnly />
        </NoNavPageContainer>
    );
};
